import React from 'react';
import styled from 'styled-components';
import { Button, Loading, Skeleton } from 'tdesign-react';
import { useState, useEffect } from 'react';
import { fetchJobsdetail, checkCollectStatus, collectJob, uncollectJob } from '../../api/ApiRequest';
import { useSelector } from 'react-redux';
import { FaRegLightbulb, FaRegShareSquare } from 'react-icons/fa';

import searchingSVG from '../../img/search.svg';
import { RiWalletLine } from 'react-icons/ri';
import { MdWorkOutline } from 'react-icons/md';
import { BiBuildings } from 'react-icons/bi';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog } from 'tdesign-react';
import { selectToken, selectUserId } from '../../Redux/slices/authSlice';
import { selectcreatedtime } from '../../Redux/slices/jobSlice';
import { EditIcon } from 'tdesign-icons-react';

function UserJobDetail({ visible, handleClose, Id }) {
  const navigate = useNavigate();
  // 从后端获取数据
  const jobId = Id;
  const createdtime = useSelector(selectcreatedtime);
  const myId = useSelector(selectUserId);

  const token = useSelector(selectToken);
  const [getData, setGetdata] = useState({});
  const [showLoading, setShowLoading] = useState(true);

  const [showCollectBtn, setShowCollectBtn] = useState(true);
  const [jobDescription, setJobDescription] = useState([]);
  const [jobRequirment, setJobRequirment] = useState([]);

  const [showNotFound, setShowNotFound] = useState(false);

  const fetchData = async () => {
    setShowLoading(true);
    setShowNotFound(false);
    if (jobId === '') {
      setShowNotFound(true);
    } else {
      const response = await fetchJobsdetail(jobId);

      setGetdata(response.data.data);

      // 职位描述格式化
      setJobDescription(response.data.data.jobdescription);

      // 工作要求格式化
      setJobRequirment(JSON.parse(response.data.data.jobrequirment));
    }
    setShowLoading(false);
  };

  const collectStatus = async () => {
    if (token && jobId !== '') {
      const response = await checkCollectStatus(jobId, token);
      response.data.status === true ? setShowCollectBtn(false) : setShowCollectBtn(true);
    }
  };

  // 跳转编辑页面并传值
  const handleLink = (jobId) => {
    navigate(`/jobUpload/update/${jobId}`, { state: { getData } });
  };

  React.useLayoutEffect(() => {
    collectStatus();
  }, [jobId]);

  useEffect(() => {
    fetchData();
  }, [jobId]);

  if (showNotFound) {
    return (
      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-full">
        <img src={searchingSVG} alt="searching" className="tw-w-1/3 tw-h-1/3" />
        <div className="tw-text-base tw-font-bold ">没有找到相关信息</div>
      </div>
    );
  }

  return (
    <div>
      <Dialog
        visible={visible}
        cancelBtn={false}
        confirmBtn={false}
        closeBtn={false}
        onClose={handleClose}
        zIndex="1500"
        className="community"
        attach=".App"
      >
        <JobDet>
          <Loading loading={showLoading} size="large" showOverlay style={{ color: '#F8924B' }}>
            <>
              <Skeleton animation={'gradient'} loading={showLoading} theme={'article'} style={{ marginTop: 20 }}>
                <div className="job_header ">
                  {/* 工作大标题 */}
                  <div className="jobtitle tw-text-gray-900">{getData.title}</div>
                  {/* 工作副标题 */}
                  <div className="jobsubtitle">
                    <span>{getData.companytitle} </span>
                    <span>{getData.locationname} </span>
                    <span>{createdtime} </span>
                    <span>{getData.applynumber}位申请者</span>
                  </div>
                  {/* 工作类型 */}
                  <div className=" tw-flex tw-items-center tw-text-sm">
                    <MdWorkOutline size={18} style={{ marginRight: '8px' }} />
                    工作类型：{getData.jobcategoryname}
                  </div>
                  {/* 工资水平 */}
                  <div className=" tw-flex tw-items-center tw-text-sm">
                    <RiWalletLine size={18} style={{ marginRight: '8px' }} />
                    工资水平：
                    {getData.salaryfrom}
                    {getData.salaryunit !== '面议' && '-'}
                    {getData.salaryto}
                    {getData.salaryunit !== '面议' && '/'}
                    {getData.salaryunit}
                  </div>
                  {/* 工作所属行业 */}
                  <div className=" tw-flex tw-items-center tw-text-sm">
                    <BiBuildings size={18} style={{ marginRight: '8px' }} />
                    所属行业：{getData.industrytype}
                  </div>
                  {/* 工作经验要求 */}
                  <div className="job_exp tw-flex tw-items-center tw-text-sm">
                    <FaRegLightbulb size={18} style={{ marginRight: '8px' }} />
                    经验要求：{getData.experience}
                  </div>

                  {/* 申请按钮和收藏按钮 */}
                  {myId === getData.userid ? (
                    <Button theme="warning" shape="round" onClick={() => handleLink(jobId)}>
                      编辑
                      <EditIcon />
                    </Button>
                  ) : (
                    <Button theme="warning" shape="round">
                      快速申请
                      <FaRegShareSquare />
                    </Button>
                  )}
                  {showCollectBtn ? (
                    <Button
                      variant="outline"
                      theme="warning"
                      className="save_job"
                      shape="round"
                      onClick={() => {
                        if (token) {
                          collectJob(jobId, token);
                          setShowCollectBtn(false);
                        } else {
                          navigate('/login');
                        }
                      }}
                    >
                      收藏职位
                    </Button>
                  ) : (
                    <Button
                      variant="outline"
                      theme="warning"
                      className="save_job"
                      shape="round"
                      onClick={() => {
                        if (token) {
                          uncollectJob(jobId, token);
                          setShowCollectBtn(true);
                        }
                      }}
                    >
                      取消收藏
                    </Button>
                  )}
                </div>
                {/* 工作详细内容 */}
                <div className="job_content">
                  {/* 职位描述 */}
                  <div className="job_des">
                    <span className="tw-text-xl tw-text-gray-900">职位描述</span>
                    <ol>{jobDescription}</ol>
                  </div>
                  {/* 职位要求 */}
                  <div className="job_des">
                    <span className="tw-text-xl tw-text-gray-900">工作要求</span>
                    <ol>
                      {jobRequirment?.map((item) => {
                        return (
                          <li className="tw-list-decimal " key={item.id}>
                            <span>{item.info}</span>
                          </li>
                        );
                      })}
                    </ol>
                  </div>
                  {/* 职位优势 */}
                  <div className="job_adv tw-whitespace-pre-wrap">
                    <span className="tw-text-xl tw-text-gray-900">职业优势</span>
                    <p>{getData.companyadvantage}</p>
                  </div>
                  {/* 公司介绍 */}
                  <div className="job_adv tw-whitespace-pre-wrap">
                    <span className="tw-text-xl tw-text-gray-900">公司介绍</span>
                    <p>{getData.companydescription}</p>
                  </div>
                </div>
              </Skeleton>
            </>
          </Loading>
        </JobDet>
      </Dialog>
    </div>
  );
}

const JobDet = styled.div`
  padding: 20px 50px 0 50px;
  width: 100%;
  /* 标题样式 */
  .job_header {
    div {
      margin: 0.625rem;
    }
    .jobtitle {
      font-size: 24px;
    }
    .jobsubtitle {
      font-size: 15px;
      span {
        padding: 0 4px;
        margin-right: 12px;
      }
    }
    .save_job {
      :hover {
        background-color: rgba(227, 115, 24, 0.15);
        box-shadow: inset 0 0 0 1px #fa9550;
      }
    }
    button {
      height: 2.8125rem;
      width: 5.625rem;
      margin: 0.3125rem 0.625rem 0.3125rem 0.4375rem;
      .t-ripple {
        display: none;
      }
      svg {
        margin: 0.25rem 0 0 0.25rem;
      }
    }
  }
  /* 内容样式 */
  .job_content {
    margin: 0.625rem;
    padding-bottom: 50px;
  }
  .job_des {
    margin-bottom: 20px;
    ol {
      margin-left: 17px;
      font-size: 15px;
    }
  }
  .job_adv {
    margin-bottom: 20px;
    p {
      font-size: 15px;
    }
  }
`;
export default UserJobDetail;
