import React from "react";
import styled from "styled-components";

function Footer() {
  return (
    <Bigbox>
      <div className="info">
        <div className="list_container">
          <ul>
            <li>关于贴贴</li>
            <li>关于我们</li>
            <li>联系我们</li>
            <li>商务合作</li>
          </ul>
          <ul>
            <li>帮助中心</li>
            <li>意见反馈</li>
            <li>法律声明</li>
            <li>隐私条款</li>
          </ul>
        </div>
        {/* 社交媒体 */}
        <Media>
          <div>微信</div>
          <div>Facebook</div>
          <div>Instgram</div>
        </Media>
      </div>
    </Bigbox>
  );
}

const Bigbox = styled.div`
  height: 100%;
  margin-top: 3rem;
  padding: 60px 0;
  display: flex;
  justify-content: space-around;
  background-color: #fafafb;
  .info {
    display: flex;
    width: 1200px;
    justify-content: space-between;
    padding: 0 40px;
  }
  .list_container {
    display: flex;
    justify-content: center;
    ul li {
      font-size: 14px;
      margin-left: 40px;
      padding: 7px 0;
      color: #999;
      font-weight: normal;
      :nth-child(1) {
        padding-bottom: 20px;
        font-size: 16px;
        color: #111827;
      }
    }
  }
`;
const Media = styled.div``;
export default Footer;
