import React from "react";
import styled from "styled-components";
import Headers from "../../components/Home/Headers";
import MainContainer from "./components//MainContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentTag,
  setSubTag,
  selectCurrentTag,
} from "../../Redux/slices/homeSlice";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Mobilehome from "../../Mobile/pages/Mobilehome";

function Home() {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentTag = useSelector(selectCurrentTag);

  React.useLayoutEffect(() => {
    if (location.pathname === "/") {
      if (currentTag !== 1) {
        dispatch(setSubTag(1));
      }
      dispatch(setCurrentTag(1));
    }
  }, []);

  // 测试移动端
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 992px)",
  });
  const isMobile = useMediaQuery({ query: "(max-width: 992px)" });

  return (
    <HomeColor>
      {isDesktopOrLaptop && (
        <Grid id="mainC">
          <Headers />
          <MainContainer />
        </Grid>
      )}
      {isMobile && <Mobilehome />}
    </HomeColor>
  );
}

const HomeColor = styled.div`
  background-color: #fcfcfc;
`;

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
`;
export default Home;
