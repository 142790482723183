import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  Avatar,
  Button,
  DatePicker,
  Dialog,
  Form,
  Input,
  Upload,
} from "tdesign-react";
import FormItem from "tdesign-react/es/form/FormItem";
import {
  selectAvatar,
  selectUserName,
  selectToken,
  setAvatar,
  setUserName,
  setDob,
  setLocation,
  setSignature,
  selectSignature,
  selectUserId,
} from "../../Redux/slices/authSlice";
import { convertImage } from "../../utils/uploadImage";
import { UpdateUserInfo } from "../../api/ApiRequest";
import { useParams } from "react-router-dom";

function UserInfo({ dob, location }) {
  const dispatch = useDispatch();
  // 修改资料弹出框
  const avatar = useSelector(selectAvatar);
  const username = useSelector(selectUserName);
  const token = useSelector(selectToken);
  const signature = useSelector(selectSignature);
  const defaulticon = username?.slice(0, 1);
  const myId = useSelector(selectUserId);
  let params = useParams();

  const [visible, setVisible] = useState(false);
  const [newAvatar, setNewAvatar] = useState([]);

  const handleClick = () => {
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
  };
  //   提交用户修改资料数据
  const [form] = Form.useForm();
  const Updatedinfo = async () => {
    let updatedAvatar = newAvatar.length === 0 ? avatar : newAvatar[0].url;
    let newdob = form.getFieldsValue(true).birth;
    let newusername = form.getFieldsValue(true).username;
    let newsignature = form.getFieldsValue(true).signature;
    let newlocation = form.getFieldsValue(true).city;

    const response = await UpdateUserInfo(
      newusername,
      updatedAvatar,
      newsignature,
      newdob,
      newlocation,
      token
    );
    dispatch(setUserName(newusername));
    dispatch(setAvatar(updatedAvatar));
    dispatch(setDob(newdob));
    dispatch(setLocation(newlocation));
    dispatch(setSignature(newsignature));
    setVisible(false);
  };
  return (
    <Setting>
      <Header>
        <span>个人资料</span>
        {params.userid === myId && <span onClick={handleClick}>修改资料</span>}
        <Dialog
          visible={visible}
          onClose={handleClose}
          confirmBtn={null}
          cancelBtn={null}
          zIndex="1500"
        >
          <Form form={form} onSubmit={Updatedinfo}>
            <FormItem label="我的头像" name="avatar">
              <Upload
                onChange={setNewAvatar}
                files={newAvatar}
                requestMethod={(data) => convertImage(data, token)}
                autoUpload={true}
                useMockProgress={true}
                name="abc"
                theme="image"
                trigger={"add"}
                tips={newAvatar.length === 0 && "请选择单张图片上传"}
                accept="image/*"
              />

              <div className="uploadicon" style={{ marginLeft: "50px" }}>
                <Avatar
                  image={newAvatar.length === 0 ? avatar : newAvatar[0].url}
                  size="100px"
                  //   style={{ marginLeft: "50px" }}
                >
                  {avatar === "" && defaulticon}
                </Avatar>
                <div
                  className="icon tw-text-center tw-text-xs"
                  style={{ marginTop: "16px" }}
                >
                  预览头像
                </div>
              </div>
            </FormItem>
            <FormItem label="用户名" name="username" initialData={username}>
              <Input />
            </FormItem>
            <FormItem
              label="我的个性签名"
              name="signature"
              initialData={signature}
            >
              <Input />
            </FormItem>
            <FormItem label="出生日期" name="birth" initialData={dob}>
              <DatePicker format="DD-MM-YYYY" />
            </FormItem>
            <FormItem label="位置" name="city" initialData={location}>
              <Input />
            </FormItem>
            <FormItem className="tw-justify-center tw-flex">
              <Button type="submit" theme="warning">
                保存
              </Button>
            </FormItem>
          </Form>
        </Dialog>
      </Header>
      <Info>
        <div>生日：{dob}</div>
        <div>位置：{location}</div>
      </Info>
    </Setting>
  );
}

const Setting = styled.div`
  font-size: 14px;
  background-color: #fff;
  box-shadow: 0 0 0 1px #eee;
  padding: 5px 10px 5px 10px;
  width: 18.75rem;
  height: 8rem;
  .uploadicon {
    margin-left: 5px;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e9ef;
  margin-bottom: 0.625rem;
  span {
    color: #222222;
    font-size: 15px;
    :nth-child(2) {
      font-size: 13px;
      color: #222222;
      cursor: pointer;
    }
  }
`;
const Info = styled.div`
  padding: 0 10px 5px 3px;
  div {
    margin-bottom: 5px;
  }
`;
export default UserInfo;
