import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

function NewsScroll({ data }) {
  const [top, setTop] = useState(data.slice(0, 4));
  const [rest, setRest] = useState(data.slice(4, data.length));
  console.log(data);

  useEffect(() => {
    setTop(data.slice(0, 4));
    setRest(data.slice(4, data.length));
  }, [data]);

  function formatTitle(title) {
    if (title.length > 25) {
      return title.slice(0, 22) + '...';
    } else {
      return title;
    }
  }

  return (
    <div className="tw-px-10 tw-py-2">
      <div className="tw-grid 2xl:tw-grid-cols-4 xl:tw-grid-cols-2 tw-gap-5 tw-mb-3">
        {top.map((item) => {
          return (
            <Link target="_blank" to={'/news/detail/' + item.newsid} key={item.newsid}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, type: 'spring', stiffness: 100 }}
                className="tw-bg-white tw-border tw-rounded-lg tw-shadow tw-h-full tw-flex tw-flex-col"
              >
                <img className="tw-rounded-t-lg tw-w-full tw-h-64" src={item.thumbnail} alt={item.title} />
                <div className="tw-p-3 tw-flex-grow">
                  <h5 className="tw-mb-2 tw-text-xl tw-font-semibold tw-text-gray-900">{formatTitle(item.title)}</h5>
                </div>
              </motion.div>
            </Link>
          );
        })}
      </div>

      <div className="tw-grid 2xl:tw-grid-cols-6 xl:tw-grid-cols-4 tw-gap-5 tw-mb-3">
        {rest.map((item) => {
          return (
            <Link target="_blank" to={'/news/detail/' + item.newsid} key={item.newsid}>
              <motion.div
                whileHover={{ scale: 1.05 }}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, type: 'spring', stiffness: 100 }}
                className="tw-bg-white tw-border tw-rounded-lg tw-shadow tw-h-full tw-flex tw-flex-col"
              >
                <img className="tw-rounded-t-lg tw-w-full tw-h-52" src={item.thumbnail} alt={item.title} />
                <div className="tw-p-3">
                  <h5 className="tw-mb-2 tw-text-xl tw-font-semibold tw-text-gray-900 ">{formatTitle(item.title)}</h5>
                </div>
              </motion.div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default NewsScroll;
