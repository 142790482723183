import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

function Usercount({ followers, following, forumsnumber }) {
  let params = useLocation();
  const a = params.pathname.split("/");
  const urlmatch = a[a.length - 1];
  // 设定选中状态钩子
  const Navigate = useNavigate();
  const handleChange = async (e) => {
    // 跳转页面
    Navigate(e);
  };
  return (
    <Userinfo>
      <div className="user_count">
        <div
          className={urlmatch === "following" ? "active" : ""}
          onClick={() => handleChange("following")}
        >
          <div>{following}</div>
          <div>关注</div>
        </div>
        <div
          className={urlmatch === "followers" ? "active" : ""}
          onClick={() => handleChange("followers")}
        >
          <div>{followers}</div>
          <div>粉丝</div>
        </div>
        <div
          className={urlmatch === "forums" ? "active" : ""}
          onClick={() => handleChange("formspost")}
        >
          <div>{forumsnumber}</div>
          <div>帖子</div>
        </div>
      </div>
    </Userinfo>
  );
}

const Userinfo = styled.div`
  width: 18.75rem;

  .user_count {
    width: 100%;
    height: 4.0625rem;
    display: flex;
    padding-top: 0.3125rem;
    font-size: 0.9375rem;
    align-items: center;

    div {
      flex: 1;
      text-align: center;
      cursor: pointer;
      :hover {
        color: #d9a40e;
      }
    }
    .active {
      color: #d9a40e;
    }
  }
`;
export default Usercount;
