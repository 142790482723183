import React from 'react';
import { motion } from 'framer-motion';
import { Avatar } from 'tdesign-react';
import styles from './ProfilePopup.module.css';

function ProfilePopup({ avatar, username, children }) {
  const [isHover, setIsHover] = React.useState(false);
  const defaultIcon = username?.slice(0, 1);
  const animatedAvatar = motion(
    <Avatar image={avatar ? avatar : ''} size="40px" style={{ marginTop: '5px', zIndex: 3000 }}>
      {avatar === '' && defaultIcon}
    </Avatar>
  );

  return (
    <div
      className="tw-relative"
      onMouseEnter={() => setIsHover((current) => !current)}
      onMouseLeave={() => setIsHover((current) => !current)}
    >
      <div className={isHover ? styles.hoverActive : styles.hoverNotActive}>
        <Avatar image={avatar ? avatar : ''} size="40px" style={{ marginTop: '5px', cursor: 'pointer' }}>
          {avatar === '' && defaultIcon}
        </Avatar>
      </div>

      {isHover && (
        <div
          id="popupContainer"
          className="tw-absolute tw-rounded-lg  tw-bg-white"
          style={{
            top: 60,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '18.75rem',
            height: '360px',
            zIndex: 20,
            boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 6px ',
          }}
        >
          <div className="tw-flex tw-flex-col tw-items-center " style={{ paddingTop: '40px' }}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfilePopup;
