import React from "react";
import styled from "styled-components";

function Mobilefooter() {
  return (
    <Footer>
      <FooterUl>
        <li>首页</li>
        <li>生活</li>
        <li>贴贴</li>
        <li>消息</li>
        <li>我的</li>
      </FooterUl>
    </Footer>
  );
}
const Footer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 3.125rem;
  width: 100%;
`;
const FooterUl = styled.ul`
  display: flex;
  /* justify-content: space-between; */
  li {
    flex: 1;
    font-size: 1rem;
    text-align: center;
  }
`;
export default Mobilefooter;
