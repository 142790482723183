import React from "react";
import { useState, useEffect } from "react";
import {
  PostForumLike,
  PostForumUnlike,
  fetchFollowForumList,
} from "../../api/ApiRequest";
import { HeartIcon, HeartFilledIcon } from "tdesign-icons-react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { selectToken } from "../../Redux/slices/authSlice";
import { Avatar } from "tdesign-react";
import getCursor from "../../utils/getCursor";
import CommunityForumDetail from "./CommunityForumDetail";
import { Link, useLocation, useNavigate } from "react-router-dom";

function CommunityFollow() {
  let token = useSelector(selectToken);

  let params = useLocation();
  const navigate = useNavigate();
  const [getdata, setgetdata] = useState([]);
  const [cursor, setCursor] = useState(getCursor());
  // 向后端取数据
  const fetchData = async () => {
    const response = await fetchFollowForumList(cursor, token);
    setgetdata([...getdata, ...response.data.data]);
    setCursor(response.data.cursor);
  };

  // 显示弹窗
  const [visible, setVisible] = useState(false);

  const handleClick = async (forumId, userId) => {
    navigate(forumId, { state: { forumId, userId } }, { status: "isForum" });
    setVisible(true);
  };

  const handleClose = () => {
    if (params.pathname !== "/community/forum" && !visible) {
      setVisible(false);
      navigate(-1);
    } else {
      setVisible(false);
      navigate(-1);
    }
  };

  // 点赞功能
  const handleLike = async (id, liked) => {
    if (token) {
      if (liked === "-1") {
        PostForumLike(id, token).then(() => {
          const newstate = [...getdata];
          found: for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].forumid === id) {
              newstate[index].liked = "1";
              newstate[index].likes += 1;
              break found;
            }
          }
          setgetdata(newstate);
        });
      } else if (liked !== "-1") {
        PostForumUnlike(id, token).then(() => {
          const newstate = [...getdata];
          found: for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].forumid === id) {
              newstate[index].liked = "-1";
              newstate[index].likes -= 1;
              break found;
            }
          }
          setgetdata(newstate);
        });
      }
    } else navigate("/login");
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ul style={{ marginTop: "20px" }}>
      {getdata.map((item) => {
        return (
          <li key={item.forumid}>
            <Listbox>
              <img
                src={item.thumbnail}
                alt=""
                key={item.forumid}
                onClick={() => handleClick(item.forumid, item.userid)}
              />
              {/* 用户信息 */}
              <Footer>
                <span
                  className="title"
                  onClick={() => handleClick(item.forumid, item.userid)}
                >
                  {item.title}
                </span>
                <Userbox>
                  {/* 用户头像和用户名 */}
                  <div className="avatar tw-cursor-pointer">
                    <Link
                      target="_blank"
                      to={"/profile/userInfo/" + item.userid}
                    >
                      <Avatar
                        image={item.avatar}
                        size="22px"
                        style={{ marginRight: "8px" }}
                      >
                        {item.avatar === "" && item.username.slice(0, 1)}
                      </Avatar>
                      <span>{item.username}</span>
                    </Link>
                  </div>
                  <div
                    className="like tw-cursor-pointer"
                    onClick={() => handleLike(item.forumid, item.liked)}
                  >
                    {item.liked === "-1" ? (
                      <HeartIcon
                        size="1.3rem"
                        style={{
                          marginRight: "5px",
                        }}
                      />
                    ) : (
                      <HeartFilledIcon
                        size="1.3rem"
                        style={{
                          marginRight: "5px",
                          color: token && item.liked !== "-1" ? "#ff2442" : "",
                        }}
                      />
                    )}
                    <span>{item.likes}</span>
                  </div>
                </Userbox>
              </Footer>
            </Listbox>
          </li>
        );
      })}
      {visible && (
        <CommunityForumDetail visible={visible} onClose={handleClose} />
      )}
    </ul>
  );
}

const Listbox = styled.div`
  width: 470px;
  margin: 0 auto;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 16px;
  margin-top: 16px;
  .avatar {
    display: flex;
    height: 1.25rem;
    align-items: center;
    span {
      font-size: 14px;
    }
  }
  img {
    object-fit: cover;
    width: 100%;
    cursor: pointer;
  }
`;
const Footer = styled.div`
  padding: 12px 12px 12px 0;
  .title {
    font-size: 16px;
    cursor: pointer;
  }
`;
const Userbox = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  a {
    color: #666;
    :hover {
      color: #333;
    }
  }
  .like {
    display: flex;
    align-items: center;
  }
`;
export default CommunityFollow;
