import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { DeleteIcon, AddIcon } from "tdesign-icons-react";
import { Button, Dialog, Input, Upload } from "tdesign-react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { useSelector } from "react-redux";
import {
  selectAvatar,
  selectToken,
  selectUserName,
} from "../../Redux/slices/authSlice";
import { convertImages } from "../../utils/uploadImage";
import { PostForum, PostUserEditForums } from "../../api/ApiRequest";
import { useMediaQuery } from "react-responsive";
function CommunityAdd({ visible, onClose, userdetail, userimages }) {
  const token = useSelector(selectToken);
  const username = useSelector(selectUserName);
  const avatar = useSelector(selectAvatar);
  // 向后端申请内容详细数据，跳出弹窗，路由跳转
  const [getimages, setGetimages] = useState([]);
  const [imagesurl, setImagesUrl] = useState([]);
  const [imagesError, setImagesError] = useState(false);

  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);

  const [content, setContent] = useState("");
  const [contentError, setContentError] = useState(false);

  const handlesubmit = (e) => {
    setImagesUrl(imagesurl.concat(e.response.map((item) => item.url)));
  };

  // 提交发布帖子
  const handleClick = async () => {
    const imgs = imagesurl.toString();
    // 如果是更新帖子
    if (userdetail) {
      await PostUserEditForums(
        userdetail.forumid,
        title,
        content,
        imgs,
        userdetail.ispublic,
        userdetail.category,
        token
      ).then(() => {
        // 提交成功后初始化数据
        onClose();
        setContent("");
        setGetimages([]);
        setImagesUrl([]);
        setTitle("");
      });
    }
    // 如果是发布新的帖子，不是更新帖子
    else {
      // 判断内容是否为空
      let isValid = true;
      if (title === "") {
        setTitleError(true);
        isValid = false;
      }

      if (content.trim() === "") {
        setContentError(true);
        isValid = false;
      }

      if (imgs === "") {
        setImagesError(true);
        isValid = false;
      }

      // 如果内容不为空，才发布论坛
      if (isValid) {
        await PostForum(title, content, imgs, true, 1, token).then(() => {
          // 提交成功后初始化数据
          onClose();
          setContent("");
          setGetimages([]);
          setImagesUrl([]);
          setTitle("");
        });
      }
    }
  };

  const isSmallscreen = useMediaQuery({
    query: "(max-height: 900px), (max-width: 1366px)",
  });

  const [composing, setComposing] = useState(false);

  const handleTextAreaChange = (event) => {
    if (!composing) {
      setContent(event.target.value);
      event.target.value = userdetail?.content;
    }
  };

  const handleCompositionStart = () => {
    setComposing(true);
  };

  const handleCompositionEnd = (event) => {
    setComposing(false);
    setContent(event.target.value);
  };

  // 添加图片判断
  function UploadImg() {
    if (imagesurl?.length === 0) {
      return (
        <Upload
          onChange={setGetimages}
          files={getimages}
          requestMethod={(data) => convertImages(data, token)}
          autoUpload={true}
          useMockProgress={true}
          onSuccess={handlesubmit}
          className="forumpostpic"
          theme="custom"
          accept="image/*"
          multiple
        >
          <Button theme="warning" type="submit">
            添加图片
          </Button>
        </Upload>
      );
    }
  }

  useEffect(() => {
    if (userdetail) {
      setTitle(userdetail.title);
      setContent(userdetail.content);
      setGetimages(userimages);
      setImagesUrl(userimages);
    }
  }, []);

  return (
    <div>
      <Dialog
        visible={visible}
        onClose={onClose}
        confirmBtn={null}
        cancelBtn={null}
        closeBtn={null}
        footer={false}
        zIndex="1500"
        attach=".App"
        className="community"
      >
        <div
          className="imgswiper tw-flex tw-flex-wrap tw-content-center tw-justify-center"
          style={{
            width: isSmallscreen ? "450px" : "600px",
            height: isSmallscreen ? "600px" : "800px",
          }}
        >
          {/* 轮播图 */}
          {/* <UploadImg /> */}
          {imagesurl.length === 0 && (
            <Upload
              onChange={setGetimages}
              files={getimages}
              requestMethod={(data) => convertImages(data, token)}
              autoUpload={true}
              useMockProgress={true}
              onSuccess={handlesubmit}
              className="forumpostpic"
              theme="custom"
              accept="image/*"
              multiple
            >
              <Button theme="warning" type="submit">
                添加图片
              </Button>
            </Upload>
          )}

          <PhotoProvider>
            <Splide
              options={{
                perPage: 1,
                pagination:
                  getimages.length || userimages?.length > 1 ? true : false,
                type: "slide",
                rewind: true,
                arrows: imagesurl.length > 1 ? true : false,
                autoplay: "pause",
                height: isSmallscreen ? 600 : 800,
              }}
            >
              {/* 如果是编辑的话 */}
              {imagesurl?.map((item, index) => {
                return (
                  <SplideSlide key={index}>
                    <PhotoView src={item} key={item}>
                      <img src={item} key={index} />
                    </PhotoView>

                    {/* 删除图片按钮 */}
                    <span className="tw-absolute tw-left-2 tw-bottom-2">
                      <Button
                        variant="outline"
                        type="submit"
                        shape="circle"
                        style={{
                          background: "rgba(26, 26, 26, .8)",
                          color: "rgb(255,255,255)",
                        }}
                        onClick={() => {
                          // Remove the image from the array
                          // getimages.splice(index, 1);
                          // setGetimages([...getimages]);
                          imagesurl.splice(index, 1);
                          setImagesUrl([...imagesurl]);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </span>

                    {/* 继续添加图片 */}
                    <span className="tw-absolute tw-right-2 tw-bottom-2 ">
                      <Upload
                        onChange={setGetimages}
                        files={getimages}
                        requestMethod={(data) =>
                          convertImages(data, token, 1440, 1920)
                        }
                        autoUpload={true}
                        useMockProgress={true}
                        onSuccess={handlesubmit}
                        className="forumpostpic"
                        theme="custom"
                        accept="image/*"
                        multiple
                      >
                        <Button
                          variant="outline"
                          type="submit"
                          shape="circle"
                          style={{
                            background: "rgba(26, 26, 26, .8)",
                            color: "rgb(255,255,255)",
                          }}
                        >
                          <AddIcon style={{ color: "rgb(255,255,255)" }} />
                        </Button>
                      </Upload>
                    </span>
                  </SplideSlide>
                );
              })}
              {/* 如果是上传的话 */}
            </Splide>
          </PhotoProvider>
        </div>

        {/* 详细内容 */}
        <Detail
          className="listdetail "
          style={{
            width: isSmallscreen ? "450px" : "600px",
            height: isSmallscreen ? "600px" : "800px",
          }}
        >
          {/* 头部区域 */}
          <Headerdetail>
            <div className="thumbnail">
              <a href="#" style={{ width: "40px", height: "40px" }}>
                <img src={avatar} alt="" />
              </a>
              <span style={{ marginLeft: "12px" }}>{username}</span>
            </div>
            <div>
              <Button shape="round" theme="warning" onClick={handleClick}>
                发布
              </Button>
            </div>
          </Headerdetail>

          {/*···················· 详细内容 */}
          <Contentdetail>
            <Input
              placeholder="请填写标题"
              onChange={(value) => {
                setTitle(value);
                setTitleError(false);
                value = userdetail?.value;
              }}
              value={userdetail ? userdetail.title : title}
              style={{ outline: "none" }}
              status={titleError && "error"}
              tips={titleError && "标题不能为空"}
            />
            <textarea
              placeholder="请输入内容"
              onChange={handleTextAreaChange}
              onCompositionStart={handleCompositionStart}
              onCompositionEnd={handleCompositionEnd}
              style={{
                outline: "none",
                border: contentError ? "1px solid red" : "none",
              }}
              value={userdetail ? userdetail.content : content}
            />
          </Contentdetail>
        </Detail>
      </Dialog>
    </div>
  );
}

// 右边详细内容大盒子
const Detail = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid rgba(239, 239, 239);
`;

// 论坛详细信息的头部
const Headerdetail = styled.div`
  display: flex;
  padding: 20px 30px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .thumbnail {
    height: 43px;
    display: flex;
    align-items: center;
    /* line-height: 43px; */
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

// 论坛详细信息的内容
const Contentdetail = styled.div`
  padding: 10px 30px 20px 30px;
  height: 40.625rem;
  div {
    outline: none;
    border: none;
    box-shadow: none;
    border-bottom: 1px solid #e5e7eb;
    :hover {
      border-color: #e5e7eb;
    }
  }
  input {
    /* border-bottom: 1px solid #e5e7eb; */
  }
  textarea {
    margin-top: 20px;
    resize: none;
    outline: none;
    box-shadow: none;
    border-color: none;
    border: none;
    width: 100%;
    height: 10rem;
    padding: 5px 8px;
  }
`;

export default CommunityAdd;
