import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { TfiComments } from "react-icons/tfi";
import { ImNewspaper } from "react-icons/im";
import Button from "tdesign-react/es/button/Button";
import {
  selectAvatar,
  selectFollowers,
  selectFollowing,
  selectIsLogin,
  selectToken,
  selectUserName,
  setAvatar,
  setUserId,
  setUserName,
  setIsLogin,
  setToken,
  selectUserId,
} from "../../Redux/slices/authSlice";
import { useDispatch } from "react-redux";

import { Avatar, Badge } from "tdesign-react";
import Popup from "tdesign-react/es/popup/Popup";
import ProfilePopup from "./ProfilePopup";
import { motion } from "framer-motion";
import Logo from "../../img/logo2.png";
import { setCurrentTag, setSubTag } from "../../Redux/slices/homeSlice";
import HeaderSearch from "./HeaderSearch";
import australiaFlag from "../../img/australia-flag-icon.svg";
import { NotificationFilledIcon } from "tdesign-icons-react";

function Headers() {
  const dispatch = useDispatch();
  // 判断是否登录
  let isLogin = useSelector(selectIsLogin);
  let token = useSelector(selectToken);
  let userid = useSelector(selectUserId);
  let avatar = useSelector(selectAvatar);
  let username = useSelector(selectUserName);
  let defaulticon = username?.slice(0, 1);
  let followers = useSelector(selectFollowers);
  let following = useSelector(selectFollowing);

  const [iconHover, setIconHover] = React.useState(false);
  // 退出登录
  const handleLogout = () => {
    dispatch(setIsLogin(false));
    dispatch(setToken(""));
    dispatch(setAvatar(""));
    dispatch(setUserName(""));
    dispatch(setUserId(""));
  };

  const handleHome = (index) => {
    dispatch(setCurrentTag(index));
    dispatch(setSubTag(index));
  };
  return (
    <Bar>
      <Left>
        <li>
          <Link to={"/"} onClick={() => handleHome(1)}>
            <img
              className="tw-inline-block"
              height={80}
              width={80}
              src={Logo}
            />
          </Link>
        </li>
        <li className="tw-flex tw-gap-1 tw-justify-center tw-items-center tw-font-semibold">
          <img src={australiaFlag} alt="australiaFlag" width={25} height={20} />
          悉尼
        </li>
        <li>今日天气</li>
        <li>今日汇率</li>
      </Left>

      <HeaderSearch />
      <Right>
        {/* 登录注册弹窗 */}
        {isLogin === false && (
          <Popup
            trigger="hover"
            placement="bottom"
            showArrow
            zIndex={1000}
            overlayInnerStyle={{
              width: "359px",
              height: "235px",
              textAlign: "center",
            }}
            content={
              <UnLoginContent>
                <div>
                  <div className="login_info">
                    <motion.p
                      key={iconHover}
                      animate={{ y: 0, opacity: 1 }}
                      initial={{ y: 5, opacity: 0 }}
                      transition={{ duration: 1 }}
                    >
                      生活大小事，都在贴一贴
                    </motion.p>
                    <p>登录后你可以：</p>
                    <div className="login_adv">
                      <div className="login_sub">
                        <span>
                          <TfiComments />
                        </span>
                        <span className="login_text">发表评论</span>
                      </div>
                      <div className="login_sub">
                        <span>
                          <ImNewspaper />
                        </span>
                        <span className="login_text">免费发布板块信息</span>
                      </div>
                    </div>
                  </div>
                  <div className="login_button">
                    <Link to={"/login"}>
                      <Button
                        shape="rectange"
                        size="medium"
                        type="button"
                        variant="base"
                        theme="warning"
                      >
                        登录
                      </Button>
                    </Link>
                  </div>
                  <div>
                    首次使用？<Link to={"/register"}>点我注册</Link>
                  </div>
                </div>
              </UnLoginContent>
            }
          >
            {/* 登录头像 */}
            <li
              onMouseEnter={() => setIconHover((current) => !current)}
              className="tw-cursor-pointer"
            >
              <Avatar image={isLogin && avatar} size="40px">
                {isLogin && avatar === "" ? defaulticon : "登录"}
              </Avatar>
            </li>
          </Popup>
        )}

        {isLogin && (
          <ProfilePopup avatar={avatar} username={username}>
            <LoginContent>
              {/* 关注，粉丝数据 */}
              <span className="user_name">{username}</span>
              <div className="user_count">
                <Link to={`/profile/userInfo/${userid}/following`}>
                  <div>{following}</div>
                  <div>关注</div>
                </Link>
                <Link to={`/profile/userInfo/${userid}/followers`}>
                  <div>{followers}</div>
                  <div>粉丝</div>
                </Link>
                <Link to={`/profile/userInfo/${userid}/formspost`}>
                  <div>5</div>
                  <div>帖子</div>
                </Link>
              </div>
              <div className="user_page">
                <Link to={`/profile/userInfo/${userid}`}>个人中心</Link>
                <Link to={`/profile/userInfo/${userid}/newsave`}>
                  <span>我的收藏</span>
                </Link>

                <Link to={`/profile/userInfo/${userid}/userlike`}>
                  我赞过的
                </Link>
                {/* 退出登录按钮 */}
                <div className="tw-cursor-pointer" onClick={handleLogout}>
                  <span style={{ fontSize: "14px" }}>退出登录</span>
                </div>
              </div>
            </LoginContent>
          </ProfilePopup>
        )}

        {/* ================================================================================================================== */}
        {/* 消息弹窗 */}
        {/* 未登录前消息弹窗 */}
        {isLogin === false && (
          <Popup
            trigger="hover"
            placement="bottom"
            showArrow
            overlayInnerStyle={{
              width: "22.4375rem",
              height: "10rem",
              padding: "10px 0 4px 0",
            }}
            content={
              <UnLogin_message>
                <span>登录后可查看消息</span>
                <div className="login_button">
                  <Link to={"/login"}>
                    <Button
                      shape="rectange"
                      size="medium"
                      type="button"
                      variant="base"
                      theme="warning"
                    >
                      登录
                    </Button>
                  </Link>
                </div>
              </UnLogin_message>
            }
          >
            <li className="tw-cursor-pointer">
              <Badge dot count={1}>
                <NotificationFilledIcon size={25} color="#003cab" />
              </Badge>
            </li>
          </Popup>
        )}

        {/* 登录后消息弹窗 */}
        {isLogin && (
          <Popup
            trigger="hover"
            placement="bottom"
            showArrow
            overlayInnerStyle={{
              width: "7.5rem",
              height: "10rem",
              padding: "10px 0 4px 0",
            }}
            content={
              <Login_message>
                <a href="#">收到的赞</a>
                <a href="#">回复我的</a>
                <a href="#">@我的</a>
                <a href="#">我的消息</a>
              </Login_message>
            }
          >
            <li className="tw-cursor-pointer">
              <Badge size="small" count={10}>
                <NotificationFilledIcon size={25} />
              </Badge>
            </li>
          </Popup>
        )}

        <li>商务合作</li>
        <li>意见反馈</li>
        <li>手机App</li>
      </Right>
    </Bar>
  );
}

const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 20px;
`;

const Left = styled.ul`
  display: flex;
  width: fit-content;
  list-style: none;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  li {
    flex: auto;
    font-size: 16px;
    color: #5c5c5c;
  }
`;

const Right = styled.ul`
  list-style: none;
  display: flex;
  width: fit-content;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  li {
    flex: auto;
    text-align: center;
    font-size: 16px;
    color: #5c5c5c;
  }
`;

const UnLoginContent = styled.div`
  .login_info p {
    font-size: 18px;
    margin-top: 10px;
    :nth-child(2) {
      text-align: left;
      padding-left: 5px;
      margin-top: 15px;
      font-size: 15px;
    }
  }
  .login_button {
    height: 44px;
    width: 319px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 10px;
    button {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }
  .login_adv {
    display: flex;
    flex: auto;
    margin-top: 16px;
    color: #505050;
  }
  .login_sub {
    display: flex;
    width: 50%;
    height: 25px;
    justify-content: center;
    margin-left: -5px;
    font-size: 17px;

    .login_text {
      margin-left: 10px;
      line-height: 25px;
    }
    svg {
      margin-top: 2px;
      font-size: 20px;
    }
  }
`;

const LoginContent = styled.div`
  width: 100%;
  text-align: center;
  .user_name {
    font-size: 1rem;
    color: #fb7299;
  }
  .user_count {
    display: flex;
    font-size: 0.9375rem;
    margin-top: 7px;
    a {
      flex: 1;
    }
  }
  .user_page {
    display: flex;
    flex-direction: column;
    a,
    div {
      width: 100%;
      height: 45px;
      line-height: 45px;
      font-size: 0.875rem;
      margin-top: 8.5px;
      :hover {
        background-color: rgba(255, 193, 7, 0.25);
      }
    }
  }
`;

const Login_message = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  a {
    width: 100%;
    height: 35px;
    line-height: 35px;
    :hover {
      background-color: rgba(255, 193, 7, 0.25);
    }
  }
`;

const UnLogin_message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
  .login_button {
    height: 40px;
    width: 319px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 10px;
    button {
      width: 100%;
      height: 100%;
      border-radius: 12px;
    }
  }
`;

export default Headers;
