import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  HeartIcon,
  HeartFilledIcon,
  StarIcon,
  StarFilledIcon,
  ChatIcon,
} from "tdesign-icons-react";
import { Avatar, Button, Dialog, Input } from "tdesign-react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchFollowStatus,
  fetchForumCommentLogin,
  fetchForumDetail,
  fetchForumFirstReply,
  fetchForumLike,
  fetchForumReply,
  fetchForumSaveStatus,
  PostFollowUser,
  PostForumComment,
  PostForumLike,
  PostForumLikeComment,
  PostForumReply,
  PostForumSave,
  PostForumUnlike,
  PostForumUnlikeComment,
  PostForumUnsave,
  PostUnFollowUser,
  PostForumLikeReply,
  PostForumUnlikeReply,
  fetchForumReplyLogin,
} from "../../api/ApiRequest";
import { useSelector } from "react-redux";
import {
  selectToken,
  selectUserId,
  selectAvatar,
  selectUserName,
} from "../../Redux/slices/authSlice";
import { useMediaQuery } from "react-responsive";
import { getDateDiff } from "../../utils/getDateDiff";
import { getCommentCursor } from "../../utils/getCursor";
import CommunityAdd from "../community/CommunityAdd";

function UserForumDetail({ visible, handleClose, forumId, userId }) {
  let token = useSelector(selectToken);
  const myId = useSelector(selectUserId);
  const myAvatar = useSelector(selectAvatar);
  const myName = useSelector(selectUserName);
  const isSmallscreen = useMediaQuery({
    query: "(max-height: 900px), (max-width: 1366px)",
  });

  // 向后端申请内容详细数据，跳出弹窗，路由跳转
  const [getdetail, setGetdetail] = useState([]);
  const [getimages, setGetimages] = useState([]);

  const [currentTimeStamp, setCurrentTimeStamp] = useState(
    new Date().getTime() / 1000
  );

  // 点赞数，收藏数，评论数
  const [likecount, setLikecount] = useState(0);
  const [savecount, setSavecount] = useState(0);
  const [replycount, setReplycount] = useState(0);

  const fetchData = async () => {
    const response = await fetchForumDetail(forumId);
    setGetdetail(response.data.data);
    setGetimages(response.data.data.images.split(","));
    setLikecount(response.data.data.likes);
    setSavecount(response.data.data.collectioncount);
    setReplycount(response.data.data.replynumber);
  };

  const [firstReply, setFirstReply] = useState([]);
  const [cursor, setCursor] = useState(getCommentCursor());
  const [hasmore, sethasmore] = useState(true);

  // 未登陆获取帖子一级评论
  const fetchFirstReply = async () => {
    const targetCursor = { timeStamp: cursor["timeStamp"] };

    const response = await fetchForumFirstReply(forumId, targetCursor);
    if (response.data.info === "no more comments") {
      sethasmore(false);
    } else {
      response.data.data.forEach((item) => {
        if (item.replynumber > 3) {
          item.moreReply = true;
        } else {
          item.moreReply = false;
        }
      });
      setFirstReply((current) => [...current, ...response.data.data]);
      const tempCursor = { timeStamp: response.data.cursor["timeStamp"] };
      tempCursor["replyTimeStamp"] = cursor["replyTimeStamp"].concat(
        response.data.cursor["replyTimeStamp"]
      );
      setCursor(tempCursor);
    }
  };

  // 登陆后获取帖子一级评论
  const fetchFirstReplyLogin = async () => {
    const targetCursor = { timeStamp: cursor["timeStamp"] };

    const response = await fetchForumCommentLogin(forumId, targetCursor, token);
    if (response.data.info === "no more comments") {
      sethasmore(false);
    } else {
      // Append a field moreReply to each comment
      response.data.data.forEach((item) => {
        if (item.replynumber > 3) {
          item.moreReply = true;
        } else {
          item.moreReply = false;
        }
      });
      setFirstReply((current) => [...current, ...response.data.data]);
      const tempCursor = { timeStamp: response.data.cursor["timeStamp"] };
      tempCursor["replyTimeStamp"] = cursor["replyTimeStamp"].concat(
        response.data.cursor["replyTimeStamp"]
      );
      setCursor(tempCursor);
    }
  };

  // 获取帖子回复

  const fetchReply = async (forumCommentId, index) => {
    const targetCursor = {
      minTimeStamp: cursor["replyTimeStamp"][index],
      maxTimeStamp: currentTimeStamp,
    };
    let response = null;

    if (token) {
      response = await fetchForumReplyLogin(
        forumCommentId,
        targetCursor,
        token
      );
    } else {
      response = await fetchForumReply(forumCommentId, targetCursor);
    }

    const morereply = [...firstReply];
    if (response.data.info === "no more replies") {
      morereply[index].moreReply = false;
      setFirstReply(morereply);
    } else {
      const reply = morereply.find(
        (item) => item.forumcommentid === forumCommentId
      );
      if (reply) {
        reply.replies = [...reply.replies, ...response.data.data];
      }
      if (response.data.data.length < 5) morereply[index].moreReply = false;
      setFirstReply(morereply);
      const tempCursor = { ...cursor };
      tempCursor["replyTimeStamp"][index] = response.data.cursor["timeStamp"];
      setCursor(tempCursor);
    }
  };

  // 无限滚动函数
  const [isLoading, setIsLoading] = useState(false);

  function debounce(func, delay) {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  }

  async function handleScroll(event) {
    if (isLoading) {
      return;
    }
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (hasmore && scrollTop + clientHeight >= scrollHeight * 0.6) {
      setIsLoading(true);
      if (token) {
        await fetchFirstReplyLogin();
        setIsLoading(false);
      } else {
        await fetchFirstReply();
        setIsLoading(false);
      }
    }
  }

  const handleDebouncedScroll = debounce(handleScroll, 500);

  //   检查用户是否关注过
  const [followstatus, setFollowstatus] = useState(null);

  const checkFollowStatus = async () => {
    const response = await fetchFollowStatus(getdetail.userid, token);
    if (response.data.data.exists) {
      setFollowstatus(true);
    }
    if (!response.data.data.exists) {
      setFollowstatus(false);
    }
  };

  // 关注用户向后端发请求
  const handleFollow = async () => {
    // 如果登陆后
    if (token) {
      // 如果关注过用户就post取消关注
      if (followstatus) {
        await PostUnFollowUser(userId, token).then(setFollowstatus(false));
      } else {
        await PostFollowUser(userId, token).then(setFollowstatus(true));
      }
    }
  };

  // 收藏点赞评论状态
  const [like, setLike] = useState(false);
  const [save, setSave] = useState(false);

  // 点赞帖子
  const handleLike = async () => {
    if (token) {
      // 没点赞的时候
      if (like === false) {
        PostForumLike(forumId, token).then(() => {
          setLike(true);
          setLikecount(likecount + 1);
        });
      }
      // 取消点赞
      else {
        PostForumUnlike(forumId, token).then(() => {
          setLike(false);
          setLikecount(likecount - 1);
        });
      }
    }
  };

  // 查看用户是否点赞过帖子
  const checkLikeStatus = async () => {
    const response = await fetchForumLike(forumId, token);
    if (response.data.data) {
      setLike(true);
    } else {
      setLike(false);
    }
  };

  // 收藏论坛帖子
  const handleSave = async () => {
    if (token) {
      // 没收藏的时候
      if (save === false) {
        PostForumSave(forumId, token).then(setSave(true));
        setSavecount(savecount + 1);
      }
      // 取消点赞
      else {
        PostForumUnsave(forumId, token).then(setSave(false));
        setSavecount(savecount - 1);
      }
    }
  };

  // 查看用户是否收藏过论坛帖子
  const checkSaveStatus = async () => {
    const response = await fetchForumSaveStatus(forumId, token);
    if (response.data.data.collectstatus) {
      setSave(true);
    } else {
      setSave(false);
    }
  };

  // TODO:
  // 点赞功能
  const navigate = useNavigate();

  const handleCommentLike = async (id, liked, isReply) => {
    if (token) {
      if (isReply && liked === "-1") {
        PostForumLikeReply(id, token).then(() => {
          const newstate = [...firstReply];
          found: for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].replies.length !== 0) {
              for (let i = 0; i < newstate[index].replies.length; i += 1) {
                if (newstate[index].replies[i].forumreplyid === id) {
                  newstate[index].replies[i].liked = "1";
                  newstate[index].replies[i].likes += 1;
                  break found;
                }
              }
            }
          }
          setFirstReply(newstate);
        });
      } else if (isReply && liked !== "-1") {
        PostForumUnlikeReply(id, token).then(() => {
          const newstate = [...firstReply];
          found: for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].replies.length !== 0) {
              for (let i = 0; i < newstate[index].replies.length; i += 1) {
                if (newstate[index].replies[i].forumreplyid === id) {
                  newstate[index].replies[i].liked = "-1";
                  newstate[index].replies[i].likes -= 1;
                  break found;
                }
              }
            }
          }
          setFirstReply(newstate);
        });
      } else if (!isReply && liked === "-1") {
        PostForumLikeComment(id, token).then(() => {
          const newstate = [...firstReply];
          for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].forumcommentid === id) {
              newstate[index].liked = "1";
              newstate[index].likes += 1;
              break;
            }
          }
          setFirstReply(newstate);
        });
      } else if (!isReply && liked !== "-1") {
        PostForumUnlikeComment(id, token).then(() => {
          const newstate = [...firstReply];
          for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].forumcommentid === id) {
              newstate[index].liked = "-1";
              newstate[index].likes -= 1;
              break;
            }
          }
          setFirstReply(newstate);
        });
      }
    } else navigate("/login");
  };

  // 发布回复功能
  const [receiverName, setReceiverName] = useState("");
  const [forumCommentId, setForumCommentId] = useState(0);
  const [replyToFirst, setReplyToFirst] = useState(true);
  const [receiver, setReceiver] = useState(0);

  const handleReply = async (replayname, forumCommentId, tofrist, receiver) => {
    setReceiverName(replayname);
    setForumCommentId(forumCommentId);
    setReplyToFirst(tofrist);
    setReceiver(receiver);
  };

  // 发布评论
  const [comment, setComment] = useState("");
  const Postcomment = async () => {
    if (receiverName !== "") {
      const response = await PostForumReply(
        forumCommentId,
        comment,
        replyToFirst,
        receiver,
        token
      );
      setComment("");
      const newReply = {
        forumreplyid: response.data.data.forumreplyid,
        content: comment,
        likes: 0,
        createdtime: response.data.data.createdtime,
        replytofirst: replyToFirst,
        senderid: myId,
        sendername: myName,
        senderavatar: myAvatar,
        receiverid: receiver,
        receivername: response.data.data.receivername,
        receiveravatar: response.data.data.receiveravatar,
        liked: "-1",
      };
      const newstate = [...firstReply];
      for (let index = 0; index < newstate.length; index += 1) {
        if (newstate[index].forumcommentid === forumCommentId) {
          newstate[index].replies = [...newstate[index].replies, newReply];
          break;
        }
      }
      setFirstReply(newstate);
    } else {
      const response = await PostForumComment(forumId, comment, token);
      setComment("");
      const newComment = {
        forumcommentid: response.data.data.forumcommentid,
        username: myName,
        avatar: myAvatar,
        content: response.data.data.content,
        likes: 0,
        createdtime: response.data.data.createdtime,
        replynumber: 0,
        userid: myId,
        liked: "-1",
        replies: [],
      };
      setFirstReply((current) => [...current, newComment]);
      // fetchFirstReplyLogin();
    }
  };

  // 修改用户发布的帖子
  const [editstatus, setEditStatus] = useState(false);

  const handleEdit = async () => {
    if (editstatus) {
      setEditStatus(false);
      handleClose();
    } else setEditStatus(true);
  };

  useEffect(() => {
    if (token) {
      fetchData();
      checkLikeStatus();
      checkSaveStatus();
      fetchFirstReplyLogin();
    } else {
      fetchData();
      fetchFirstReply();
    }
  }, []);

  // 当拿到详细状态，等状态更新完后再发请求
  useEffect(() => {
    if (token && getdetail.length !== 0) {
      checkFollowStatus();
    }
  }, [getdetail]);

  return (
    <div>
      {editstatus ? (
        <CommunityAdd
          visible={editstatus}
          onClose={handleEdit}
          userdetail={getdetail}
          userimages={getimages}
        />
      ) : (
        <Dialog
          visible={visible}
          cancelBtn={false}
          confirmBtn={false}
          closeBtn={false}
          onClose={handleClose}
          zIndex="1500"
          className="community"
          attach=".App"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
              height: isSmallscreen ? "600px" : "800px",
              width: isSmallscreen ? "850px" : "1000px",
            }}
          >
            {/* 图片 */}
            <div
              className="imgswiper tw-flex tw-flex-col tw-justify-center tw-items-center"
              style={{
                maxWidth: "600px",
                height: "100%",
                width: isSmallscreen ? "450px" : "600px",
                height: isSmallscreen ? "600px" : "800px",
              }}
              splidecount={getimages.length}
            >
              {/* 轮播图 */}
              <PhotoProvider>
                <Splide
                  options={{
                    perPage: 1,
                    pagination: getimages.length > 1 ? true : false,
                    type: "slide",
                    rewind: true,
                    arrows: getimages.length > 1 ? true : false,
                    autoplay: "pause",
                    height: isSmallscreen ? 600 : 800,
                  }}
                >
                  {getimages?.map((item, index) => {
                    return (
                      <SplideSlide key={index}>
                        <PhotoView src={item} key={item}>
                          <img src={item} />
                        </PhotoView>
                      </SplideSlide>
                    );
                  })}
                </Splide>
              </PhotoProvider>
            </div>

            {/* 详细内容 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: isSmallscreen ? "600px" : "800px",
                width: "400px",
                borderLeft: "1px solid #e8e8e8",
              }}
            >
              {/* 头部区域 */}
              <Headerdetail>
                <div className="thumbnail">
                  <Link
                    target="_blank"
                    to={"/profile/userInfo/" + getdetail.userid}
                    key={getdetail.userid}
                  >
                    <Avatar size="40px" image={getdetail.avatar}>
                      {getdetail.avatar === "" &&
                        getdetail.username.slice(0, 1)}
                    </Avatar>
                  </Link>
                  <span style={{ marginLeft: "12px", fontSize: "16px" }}>
                    {getdetail.username}
                  </span>
                </div>
                {myId !== getdetail.userid ? (
                  <div>
                    {followstatus == null ? (
                      <Button
                        loading
                        shape="round"
                        theme="warning"
                        onClick={handleFollow}
                      ></Button>
                    ) : (
                      <Button
                        shape="round"
                        theme="warning"
                        onClick={handleFollow}
                      >
                        {followstatus ? "取消关注" : "关注"}
                      </Button>
                    )}
                  </div>
                ) : (
                  <div>
                    <Button shape="round" theme="warning" onClick={handleEdit}>
                      编辑
                    </Button>
                  </div>
                )}
              </Headerdetail>

              <Contentdetail onScroll={handleDebouncedScroll}>
                {/*···················· 详细内容 */}
                <h1>{getdetail.title}</h1>
                <div>{getdetail.content}</div>
                <div style={{ flex: 1, width: "98%", marginTop: "40px" }}>
                  {firstReply?.map((item, index) => {
                    return (
                      <Comment key={item.forumcommentid}>
                        <div className="avatar">
                          <Link
                            target="_blank"
                            to={"/profile/userInfo/" + item.userid}
                            key={item.userid}
                          >
                            <Avatar size="33px" image={item.avatar}>
                              {item.avatar === "" && item.username.slice(0, 1)}
                            </Avatar>
                          </Link>
                        </div>
                        <div className="detail">
                          <div>{item.username}</div>
                          <div className="content">{item.content}</div>
                          <div className="actions">
                            <span className="time">
                              {getDateDiff(item.createdtime)}
                            </span>
                            <div className="icons">
                              {/* 点赞功能 */}
                              <span
                                className="like"
                                onClick={() =>
                                  handleCommentLike(
                                    item.forumcommentid,
                                    item.liked,
                                    false
                                  )
                                }
                              >
                                <HeartFilledIcon
                                  size="1.3rem"
                                  style={{
                                    marginRight: "5px",
                                    color:
                                      token && item.liked !== "-1"
                                        ? "#ff2442"
                                        : "",
                                  }}
                                />
                                <span>{item.likes}</span>
                              </span>
                              {/* 评论按钮 */}
                              <span
                                className="chat"
                                onClick={() =>
                                  handleReply(
                                    item.username,
                                    item.forumcommentid,
                                    true,
                                    item.userid
                                  )
                                }
                              >
                                <ChatIcon
                                  size="1.3rem"
                                  style={{ marginRight: "5px" }}
                                />
                                <span>回复</span>
                              </span>
                            </div>
                          </div>

                          {/* 评论回复 */}
                          <div className="reply-container">
                            {item.replies.map((replyitem) => {
                              return (
                                <Comment
                                  className="replyitem"
                                  key={replyitem.forumreplyid}
                                >
                                  <div className="avatar">
                                    <Link
                                      target="_blank"
                                      to={
                                        myId === replyitem.senderid
                                          ? "/userpage"
                                          : "/profile/userInfo/" +
                                            replyitem.senderid
                                      }
                                    >
                                      <Avatar
                                        size="33px"
                                        image={replyitem.senderavatar}
                                      >
                                        {replyitem.senderavatar === "" &&
                                          replyitem.sendername.slice(0, 1)}
                                      </Avatar>
                                    </Link>
                                  </div>
                                  <div className="detail">
                                    <div>{replyitem.sendername}</div>
                                    <div className="content">
                                      {replyitem.replytofirst
                                        ? replyitem.content
                                        : `回复${replyitem.receivername}:${replyitem.content}`}
                                    </div>
                                    <div className="actions">
                                      <span className="time">
                                        {getDateDiff(replyitem.createdtime)}
                                      </span>
                                      <div className="icons">
                                        {/* 点赞功能 */}
                                        <span
                                          className="like"
                                          onClick={() =>
                                            handleCommentLike(
                                              replyitem.forumreplyid,
                                              replyitem.liked,
                                              true
                                            )
                                          }
                                        >
                                          <HeartFilledIcon
                                            size="1.3rem"
                                            style={{
                                              marginRight: "5px",
                                              color:
                                                token &&
                                                replyitem.liked !== "-1"
                                                  ? "#ff2442"
                                                  : "",
                                            }}
                                          />
                                          <span>{replyitem.likes}</span>
                                        </span>
                                        {/* 评论按钮 */}
                                        <span
                                          className="chat"
                                          onClick={() =>
                                            handleReply(
                                              replyitem.sendername,
                                              item.forumcommentid,
                                              false,
                                              replyitem.senderid
                                            )
                                          }
                                        >
                                          <ChatIcon
                                            size="1.3rem"
                                            style={{ marginRight: "5px" }}
                                          />
                                          <span>回复</span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </Comment>
                              );
                            })}
                            {item.moreReply && (
                              <span
                                className="morereply"
                                onClick={() => {
                                  fetchReply(item.forumcommentid, index);
                                }}
                              >
                                展开更多回复
                              </span>
                            )}
                          </div>
                        </div>
                      </Comment>
                    );
                  })}

                  {/* 评论到底了 */}
                  {!hasmore && <div className="tw-text-center">-The End-</div>}
                  {/* {isLoading && <Loading />} */}
                </div>
              </Contentdetail>
              {/*···················· 评论区*/}
              <Commentdetail>
                <div className="left">
                  {/* 点赞 */}
                  <div
                    className="icon"
                    onClick={() => {
                      handleLike();
                    }}
                  >
                    {like ? (
                      <HeartFilledIcon
                        size="28px"
                        style={{ color: "ff2442" }}
                      />
                    ) : (
                      <HeartIcon size="28px" />
                    )}
                    <span className="count">{likecount}</span>
                  </div>
                  {/* 收藏 */}
                  <div
                    className="icon"
                    onClick={() => {
                      handleSave();
                    }}
                  >
                    {save ? (
                      <StarFilledIcon
                        size="28px"
                        style={{ color: "rgb(252, 220, 0)" }}
                      />
                    ) : (
                      <StarIcon size="28px" />
                    )}

                    <span className="count">{savecount}</span>
                  </div>
                  {/* 评论 */}
                  <span
                    className="icon"
                    onClick={() => {
                      setReceiverName("");
                    }}
                  >
                    <ChatIcon size="28px" />
                    <span className="count">{replycount}</span>
                  </span>
                </div>
                <div className="comment_input">
                  <Input
                    className="commentinput"
                    placeholder={
                      receiverName === "" ? "请输入评论" : `@${receiverName}`
                    }
                    size="large"
                    value={comment}
                    onChange={setComment}
                  />
                  <Button
                    shape="round"
                    size="large"
                    theme="warning"
                    onClick={Postcomment}
                  >
                    发送
                  </Button>
                </div>
              </Commentdetail>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}

// 论坛详细信息的头部
const Headerdetail = styled.div`
  display: flex;
  padding: 10px 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  .thumbnail {
    height: 43px;
    display: flex;
    align-items: center;
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

// 论坛详细信息的内容
const Contentdetail = styled.div`
  padding: 10px 30px 20px 30px;
  flex: 1;
  display: flex;
  flex-direction: column;
  // background-color: pink;
  width: 100%;
  overflow: auto;

  h1 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #333;
  }
`;

// 评论样式
const Comment = styled.div`
  display: flex;
  margin-top: 1rem;
  .avatar {
    margin-right: 15px;
  }
  .detail {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .content {
    margin-top: 5px;
  }
  .actions {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    .icons {
      display: flex;
    }
    .like {
      display: flex;
      margin-right: 10px;
      cursor: pointer;
    }
    .chat {
      cursor: pointer;
    }
  }
  .replyitem {
    .detail {
      border-bottom: none;
    }
  }
  .morereply {
    margin-left: 48px;
    cursor: pointer;
  }
`;

// 论坛详细信息的评论
const Commentdetail = styled.div`
  padding: 15px 15px;
  // height: 9.375rem;
  width: 100%;
  border-top: 1px solid rgba(239, 239, 239);
  .left {
    display: flex;
  }
  .icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    .count {
      margin: 0 10px 0 5px;
    }
  }
  .comment_input {
    margin-top: 1.25rem;
    display: flex;
    align-items: center;
    .commentinput {
      margin-right: 0.5rem;
    }
    .t-input {
      border-radius: 22px;
    }
  }
`;

export default UserForumDetail;
