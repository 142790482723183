import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

function UserSavelist({ collectnumber }) {
  let params = useLocation();
  const a = params.pathname.split("/");
  const urlmatch = a[a.length - 1];

  const postTitles = ["jobspost", "housespost", "formspost"];
  const likeTitles = ["newslike", "formslike"];
  const saveTitles = ["newsave", "jobsave", "housesave", "postsave"];

  // 设定选中状态钩子
  const Navigate = useNavigate();
  const handleChange = async (e) => {
    // 跳转页面
    Navigate(e);
  };

  return (
    <Setting>
      {saveTitles.includes(urlmatch) && (
        <>
          <li
            className={urlmatch === "newsave" ? "active" : ""}
            onClick={() => {
              handleChange("newsave");
            }}
          >
            新闻收藏夹
            <span className="tw-absolute tw-right-4">
              {collectnumber?.newscollectionnum}
            </span>
          </li>
          <li
            className={urlmatch === "jobsave" ? "active" : ""}
            onClick={() => {
              handleChange("jobsave");
            }}
          >
            工作收藏夹
            <span className="tw-absolute tw-right-4">
              {collectnumber.jobscollectionnum}
            </span>
          </li>
          <li
            className={urlmatch === "housesave" ? "active" : ""}
            onClick={() => {
              handleChange("housesave");
            }}
          >
            房屋收藏夹
            <span className="tw-absolute tw-right-4">
              {collectnumber.housescollectionnum}
            </span>
          </li>
          <li
            className={urlmatch === "postsave" ? "active" : ""}
            onClick={() => {
              handleChange("postsave");
            }}
          >
            帖子收藏夹
            <span className="tw-absolute tw-right-4">
              {collectnumber.forumscollectionnum}
            </span>
          </li>
        </>
      )}
      {postTitles.includes(urlmatch) && (
        <>
          <li
            className={urlmatch === "jobspost" ? "active" : ""}
            onClick={() => {
              handleChange("jobspost");
            }}
          >
            工作投稿
            <span className="tw-absolute tw-right-4">
              {collectnumber?.jobspostnum}
            </span>
          </li>
          <li
            className={urlmatch === "housespost" ? "active" : ""}
            onClick={() => {
              handleChange("housespost");
            }}
          >
            租房投稿
            <span className="tw-absolute tw-right-4">
              {collectnumber?.housespostnum}
            </span>
          </li>
          <li
            className={urlmatch === "formspost" ? "active" : ""}
            onClick={() => {
              handleChange("formspost");
            }}
          >
            论坛投稿
            <span className="tw-absolute tw-right-4">
              {collectnumber?.forumspostnum}
            </span>
          </li>
        </>
      )}
      {likeTitles.includes(urlmatch) && (
        <>
          <li
            className={urlmatch === "newslike" ? "active" : ""}
            onClick={() => {
              handleChange("newslike");
            }}
          >
            新闻点赞
            <span className="tw-absolute tw-right-4">
              {collectnumber?.newslikednum}
            </span>
          </li>
          <li
            className={urlmatch === "formslike" ? "active" : ""}
            onClick={() => {
              handleChange("formslike");
            }}
          >
            论坛点赞
            <span className="tw-absolute tw-right-4">
              {collectnumber?.forumlikednum}
            </span>
          </li>
        </>
      )}
    </Setting>
  );
}
const Setting = styled.ul`
  font-size: 14px;
  background-color: #fff;
  box-shadow: 0 0 0 1px #eee;
  padding: 10px;
  width: 18.75rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  li {
    position: relative;
    height: 45px;
    line-height: 45px;
    width: 100%;
    cursor: pointer;
    text-align: center;
    :hover {
      background-color: #f4f5f7;
      color: #d9a40e;
    }
  }
  .active {
    color: #d9a40e;
    background-color: rgba(225, 193, 7, 0.25);
    :hover {
      background-color: rgba(225, 193, 7, 0.25);
    }
  }
`;
export default UserSavelist;
