import React from 'react';
import FormItem from 'tdesign-react/es/form/FormItem';
import Input from 'tdesign-react/es/input/Input';
import { Select, Radio, Space, RangeInput } from 'tdesign-react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocations, setLocations } from '../../Redux/slices/filterSlice';
import { fetchSydlocation } from '../../api/ApiRequest';
import Option from 'tdesign-react/es/select/base/Option';

function validateRange(value) {
  try {
    const num1 = parseInt(value[0]);
    const num2 = parseInt(value[1]);
    if (value && value.length === 2 && num1 !== '' && num2 !== '' && num1 < num2) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

function Step1({ step1Data }) {
  console.log(step1Data);
  console.log(step1Data.salaryUnit);
  // 工作地区列表
  const locations = useSelector(selectLocations);
  const dispatch = useDispatch();

  const fetchLocations = async () => {
    if (locations?.length !== 0) {
      return;
    }
    const response = await fetchSydlocation();
    dispatch(setLocations(response.data.data));
  };

  React.useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <>
      {/* 标题 */}
      <FormItem
        initialData={step1Data.title}
        className="tw-w-5/6 tw-font-bold"
        style={{ width: '50vw' }}
        label="招聘标题"
        name="JobTitle"
        shouldUpdate={false}
        successBorder={false}
        rules={[{ required: true, message: '请填写标题', type: 'error' }]}
      >
        <Input
          align="left"
          allowInputOverMax={false}
          autoWidth={false}
          autofocus={true}
          maxlength={20}
          placeholder="请输入招聘帖子标题"
          showLimitNumber={true}
          onChange={(value) => step1Data.setTitle(value)}
          size="medium"
          type="text"
        />
      </FormItem>

      <div className="tw-flex tw-justify-center">
        <FormItem className="tw-font-bold tw-flex-1" label="经验要求" name="experience" requiredMark={false}>
          <div>
            <Space direction="vertical">
              <Radio.Group variant="primary-filled" defaultValue={step1Data.experience}>
                <Radio.Button value="不限" onChange={() => step1Data.setExperience('不限')}>
                  不限
                </Radio.Button>
                <Radio.Button value="需要" onChange={() => step1Data.setExperience('需要')}>
                  需要
                </Radio.Button>
                <Radio.Button value="不需要" onChange={() => step1Data.setExperience('不需要')}>
                  不需要
                </Radio.Button>
              </Radio.Group>
            </Space>
          </div>
        </FormItem>

        <FormItem
          className="tw-font-bold tw-flex-1"
          label="工作性质"
          name="category"
          requiredMark={false}
          initialData={step1Data.category}
        >
          <div>
            <Space direction="vertical">
              <Radio.Group variant="primary-filled" defaultValue={step1Data.category}>
                <Radio.Button value="1" onChange={() => step1Data.setCategory('1')}>
                  全职
                </Radio.Button>
                <Radio.Button value="2" onChange={() => step1Data.setCategory('2')}>
                  兼职
                </Radio.Button>
                <Radio.Button value="3" onChange={() => step1Data.setCategory('3')}>
                  临时工
                </Radio.Button>
                <Radio.Button value="4" onChange={() => step1Data.setCategory('4')}>
                  合同工
                </Radio.Button>
                <Radio.Button value="5" onChange={() => step1Data.setCategory('5')}>
                  实习生
                </Radio.Button>
              </Radio.Group>
            </Space>
          </div>
        </FormItem>
      </div>

      <div className="tw-flex tw-justify-center tw-gap-2">
        <FormItem
          className="tw-font-bold tw-flex-1"
          label="工作地区"
          name="location"
          requiredMark={true}
          rules={[{ required: true, message: '请填写地区', type: 'error' }]}
          initialData={step1Data.location}
          value={step1Data.location}
        >
          <Select style={{ width: '90%' }} filterable onChange={(value) => step1Data.setLocation(value)}>
            {locations?.map((item) => (
              <Option label={item.locationname} key={item.locationid} value={item.locationid} />
            ))}
          </Select>
        </FormItem>

        <FormItem
          className="tw-font-bold tw-flex-1"
          label="所属行业"
          name="industry"
          requiredMark={true}
          rules={[{ required: true, message: '请填写行业', type: 'error' }]}
          initialData={step1Data.industry}
        >
          <Select style={{ width: '90%' }} onChange={(e) => step1Data.setIndustry(e)}>
            <Option key="1" label="互联网/通信" value="1" />
            <Option key="2" label="传媒/艺术/设计" value="2" />
            <Option key="3" label="财务/人力/行政" value="3" />
            <Option key="4" label="销售/市场/业务" value="4" />
            <Option key="5" label="建筑/土木工程/施工员" value="5" />
            <Option key="6" label="技工/工人/学徒" value="6" />
            <Option key="7" label="厨师/服务员/帮工" value="7" />
            <Option key="8" label="医护/按摩/美容/发型" value="8" />
            <Option key="9" label="清洁/搬运/库管/司机" value="9" />
            <Option key="10" label="网管/IT安装/程序员" value="10" />
            <Option key="11" label="保姆/月嫂/钟点工" value="11" />
            <Option key="12" label="店员/收银/客服" value="12" />
            <Option key="13" label="教师/教练/家教" value="13" />
            <Option key="14" label="旅行/导游" value="14" />
            <Option key="15" label="编辑/翻译/律师" value="15" />
          </Select>
        </FormItem>
      </div>

      <FormItem
        className="tw-font-bold tw-flex-1"
        label="薪资待遇"
        name="salaryUnit"
        requiredMark={false}
        initialData={step1Data.salaryUnit}
      >
        <div>
          <Space direction="vertical">
            <Radio.Group variant="primary-filled" defaultValue={step1Data.salaryUnit}>
              <Radio.Button
                value="面议"
                onClick={() => {
                  step1Data.setSalaryUnit('面议');
                }}
              >
                面议
              </Radio.Button>
              <Radio.Button
                value="时薪"
                onClick={() => {
                  step1Data.setSalaryUnit('时薪');
                }}
              >
                时薪
              </Radio.Button>
              <Radio.Button
                value="年薪"
                onClick={() => {
                  step1Data.setSalaryUnit('年薪');
                }}
              >
                年薪
              </Radio.Button>
            </Radio.Group>
          </Space>
        </div>
      </FormItem>

      {step1Data.salaryUnit != '面议' && (
        <FormItem
          className="tw-font-bold tw-flex-1"
          initialData={
            step1Data.salaryFrom === '' || step1Data.salaryTo === '' ? null : [step1Data.salaryFrom, step1Data.salaryTo]
          }
          label="薪资范围"
          name="salary"
          requiredMark={true}
          rules={[{ validator: validateRange, message: '请正确填写薪资范围' }]}
        >
          <RangeInput
            onChange={(value) => {
              step1Data.setSalaryFrom(value[0]);
              step1Data.setSalaryTo(value[1]);
            }}
            placeholder="请输入薪资范围"
          />
        </FormItem>
      )}
    </>
  );
}

export default Step1;
