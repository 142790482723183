import React from 'react';
import { motion } from 'framer-motion';
import { RxDotFilled } from 'react-icons/rx';
import styles from './Loading.module.css';

function LoadingBtn({ title, loading, onClick }) {
  const Dots = () => {
    return (
      <div className="tw-flex tw-justify-between tw-items-center">
        <motion.span
          animate={{
            y: [0, -10, 0],
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
          }}
          className="tw-font-black tw-text-base"
        >
          <RxDotFilled />
        </motion.span>
        <motion.span
          animate={{
            y: [0, -10, 0],
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: 0.2,
          }}
          className="tw-font-black tw-text-base"
        >
          <RxDotFilled />
        </motion.span>
        <motion.span
          animate={{
            y: [0, -10, 0],
          }}
          transition={{
            duration: 1,
            repeat: Infinity,
            delay: 0.4,
          }}
          className="tw-font-black tw-text-base"
        >
          <RxDotFilled />
        </motion.span>
      </div>
    );
  };

  const Info = () => {
    return (
      <button className={styles.loadingBtn}>
        <div>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path
                fill="currentColor"
                d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
              ></path>
            </svg>
          </div>
        </div>
        <span>{title}</span>
      </button>
    );
  };

  return (
    <motion.div
      style={{ alignSelf: 'flex-end' }}
      className={`tw-cursor-pointer ${
        loading ? 'tw-bg-purple-800 hover:tw-bg-purple-700' : 'tw-bg-purple-600 hover:tw-bg-purple-500'
      } tw-font-bold tw-flex tw-justify-center tw-items-center tw-mt-4 tw-rounded-md  tw-text-white tw-p-3 tw-w-full ${
        loading ? '' : styles.loadingBtn
      }`}
      onClick={onClick}
    >
      {loading ? <Dots /> : <Info />}
    </motion.div>
  );
}

export default LoadingBtn;
