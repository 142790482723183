import React from 'react';
import styled from 'styled-components';

function CategoryTab({ isSelected, icon, title }) {
  const selectedStyle = {
    backgroundColor: '#e9832f',
    color: 'white',
  };

  const unselectedStyle = {
    backgroundColor: '',
    color: 'black',
  };

  return (
    <Container style={isSelected ? selectedStyle : unselectedStyle}>
      {icon}
      <button>{title}</button>
    </Container>
  );
}

export default CategoryTab;

const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  border-radius: 1.5rem;
  width: 100%;
  height: 100%;
  margin: 0.75rem;
  padding: 1.25rem;
  font-size: 1rem;
  &:hover {
    background-color: #fae3d1;
  }
`;
