import { io } from 'socket.io-client';

let socket = null;

export function createSocket(token) {
  socket = io('https://tietieserver.onrender.com', {
    autoConnect: false,
    auth: {
      token: token,
    },
  });
  return socket;
}

export function getSocket() {
  return socket;
}
