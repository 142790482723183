export const checkUsername = (username) => {
  if (username === '') {
    return { userNameStatus: false, userNameInfo: '用户名不能为空' };
  }

  if (username.length > 16) {
    return { userNameStatus: false, userNameInfo: '用户名长度不能大于16位' };
  }
  return { userNameStatus: true, userNameInfo: '用户名格式正确' };
};

export const checkPassword = (password) => {
  if (password === '') {
    return { passwordStatus: false, passwordInfo: '密码不能为空' };
  }

  if (password.length < 8) {
    return { passwordStatus: false, passwordInfo: '密码长度不能小于8位' };
  }

  if (password.length > 16) {
    return { passwordStatus: false, passwordInfo: '密码长度不能大于16位' };
  }

  if (password.replace(/[a-z0-9]/g, '').length === 0) {
    return { passwordStatus: false, passwordInfo: '密码必须包含至少一个大写字母' };
  }

  if (password.replace(/[A-Z0-9]/g, '').length === 0) {
    return { passwordStatus: false, passwordInfo: '密码必须包含至少一个小写字母' };
  }

  return { passwordStatus: true, passwordInfo: '密码格式正确' };
};

export const checkConfirmPassword = (password, confirmPassword) => {
  if (confirmPassword === '') {
    return { confirmPasswordStatus: false, confirmPasswordInfo: '确认密码不能为空' };
  }

  if (password !== confirmPassword) {
    return { confirmPasswordStatus: false, confirmPasswordInfo: '两次密码不一致' };
  }

  return { confirmPasswordStatus: true, confirmPasswordInfo: '确认密码格式正确' };
};
