import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Headers from "../../components/Home/Headers";
import {
  Outlet,
  useNavigate,
  useParams,
  useLocation,
  Link,
} from "react-router-dom";
import { fetchGroupMember } from "../../api/ApiRequest";
import { Avatar } from "tdesign-react";

const Grouphome = () => {
  const navigate = useNavigate();
  const location = useParams();
  let params = useLocation();
  const a = params.pathname.split("/");
  const urlmatch = a[a.length - 1];
  const [memberdata, setMemberData] = useState([]);

  const handleTabChange = (tab) => {
    navigate(tab);
  };

  useEffect(() => {
    fetchGroupMember(location.groupid).then((res) => {
      setMemberData(res.data.members);
    });
  }, []);

  // 获取群组成员

  return (
    <Page>
      <div className="user_header">
        <Headers />
      </div>

      <ChatRoomContainer>
        <Sidebar>
          <Tab
            className={urlmatch === "posts" ? "active" : ""}
            onClick={() => handleTabChange("posts")}
          >
            群组首页
          </Tab>
          <Tab>群组公告</Tab>
          <Tab
            className={urlmatch === "chat" ? "active" : ""}
            onClick={() => handleTabChange("chat")}
          >
            群组聊天
          </Tab>
        </Sidebar>

        <ChatArea id="forumParent">
          <Outlet />
        </ChatArea>
        <Members>
          <h2>群组成员</h2>
          {/* 线上群组成员内容 */}
          <Online>
            {/* <span className="online_number">线上-1</span> */}
            {memberdata?.map((item) => {
              return (
                <Link
                  target="_blank"
                  to={"/profile/userInfo/" + item.userid}
                  key={item.userid}
                >
                  <div className="members">
                    <Avatar
                      image={item.avatar}
                      size="35px"
                      style={{ marginRight: "8px" }}
                    >
                      {item.avatar === "" && item.username.slice(0, 1)}
                    </Avatar>
                    <div>{item.username}</div>
                  </div>
                </Link>
              );
            })}
          </Online>
          {/* 线下群组成员内容 */}
          {/* <Offline>
            <span className="online_number">离线-1</span>
          </Offline> */}
        </Members>
      </ChatRoomContainer>
    </Page>
  );
};
const Page = styled.div`
  .user_header {
    background-color: #fff;
    height: 4rem;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
    li {
      color: #212121;
      font-size: 0.875rem;
      text-shadow: none;
    }
  }
`;
const ChatRoomContainer = styled.div`
  display: flex;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
`;

const ChatArea = styled.div`
  display: flex;
  flex: 0 0 65%;
  padding: 0 20px;
`;
const Sidebar = styled.div`
  flex: 0 0 15%;
  height: 500px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  color: #666666;
  padding: 20px;

  .active {
    background-color: #e9832f;
    color: white;
    :hover {
      background-color: #e9832f;
      color: white;
    }
  }
`;
const Tab = styled.div`
  font-size: 1rem;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border-radius: 1.5rem;
  font-weight: 500;
  color: black;

  &:hover {
    background-color: #fae3d1;
  }
`;
const Members = styled.div`
  flex: 0 0 20%;
  background-color: #fff;
  height: 500px;
  color: #666666;
  padding: 0 20px 20px 20px;
  h2 {
    font-size: 16px;
    height: 56px;
    line-height: 56px;
    color: #111827;
    border-bottom: 1px solid #e4e6eb;
  }
`;
const Online = styled.div`
  margin-top: 5px;
  font-size: 13px;
  .members {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
`;

const Offline = styled.div`
  margin-top: 10px;
  font-size: 13px;
`;

export default Grouphome;
