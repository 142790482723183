import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  houseId: 1,
};

export const renthouseSlice = createSlice({
  name: 'house',
  initialState,
  reducers: {
    sethouseId: (state, action) => {
      state.houseId = action.payload;
    },

  },
});

export const { sethouseId } = renthouseSlice.actions;

export const selecthouseId = (state) => state.house.houseId;

export default renthouseSlice.reducer;