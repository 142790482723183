import React from 'react';
import './App.css';
import Home from './pages/home/Home';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import 'tdesign-react/es/style/index.css';
import 'react-photo-view/dist/react-photo-view.css';
import { selectToken } from './Redux/slices/authSlice';

import { verify } from './api/ApiRequest';
import { setAvatar, setUserId, setUserName, setIsLogin, setToken } from './Redux/slices/authSlice';
// import { createSocket } from './utils/socket';

// Importing the pages
import LoginPage from './pages/Auth/LoginPage';
import RegisterPage from './pages/Auth/RegisterPage';
import SeekJob from './pages/home/components/mainContainer/category/SeekJob';
import Community from './pages/home/components/mainContainer/category/community/Community';
import JobUploadPage from './pages/Job/UploadJobPage';
import Userpage from './pages/Userpage/Userpage';
import UserPost from './components/Userpage/UserPost';
import UserLike from './components/Userpage/UserLike';
import Renthome from './pages/home/components/mainContainer/category/Renthome';
import Newsdetail from './components/Topnews/Newsdetail';
import HomeUploadPage from './pages/Renthome/HomeUploadPage';
import Housesave from './components/Userpage/usersave/Housesave';
import Jobsave from './components/Userpage/usersave/Jobsave';
import Newssave from './components/Userpage/usersave/Newssave';
import Postsave from './components/Userpage/usersave/Postsave';
import CommunityGroup from './components/community/CommunityGroup';
import CommunityFollow from './components/community/CommunityFollow';
import CommunityContent from './components/community/CommunityContent';
import CommunityForumDetail from './components/community/CommunityForumDetail';
import Followers from './components/Userpage/Followers';
import Following from './components/Userpage/Following';
import Grouphome from './pages/Community/Grouphome';
import GroupForum from './components/community/Group/GroupForum';
import ChatRoom from './components/community/Group/ChatRoom';
import styled from 'styled-components';

function App() {
  const dispatch = useDispatch();
  // const [socketConnection, setSocketConnection] = React.useState(null);

  // 退出登录
  const handleLogout = () => {
    dispatch(setIsLogin(false));
    dispatch(setToken(''));
    dispatch(setAvatar(''));
    dispatch(setUserName(''));
    dispatch(setUserId(''));
  };

  const verifyToken = async (token) => {
    try {
      await verify(token);
      // const con = createSocket(token).connect();
      // setSocketConnection(con);
    } catch (error) {
      handleLogout();
    }
  };

  const token = useSelector(selectToken);
  const postTitles = ['jobspost', 'housespost', 'formspost'];
  const likeTitles = ['newslike', 'formslike'];

  React.useEffect(() => {
    if (token) {
      try {
        verifyToken(token);
      } catch (error) {
        console.log('logout');
        handleLogout();
      }
    }
  }, [token]);

  // React.useEffect(() => {
  //   if (socketConnection) {
  //     socketConnection.on('connect', () => {
  //       console.log('socket connected');
  //     });
  //     socketConnection.on('disconnect', () => {
  //       console.log('socket disconnected');
  //     });
  //   }

  //   return () => {
  //     if (socketConnection) {
  //       socketConnection.off('connect');
  //       socketConnection.off('disconnect');
  //       socketConnection.disconnect();
  //     }
  //   };
  // }, [socketConnection]);

  return (
    <Mainapp className="App">
      <Routes>
        {/* Home page */}
        <Route path="/" element={<Home />} />
        {/* Login page */}
        <Route path="/login" element={token ? <Navigate to="/" /> : <LoginPage />} />

        {/* Register page */}
        <Route path="/register" element={token ? <Navigate to="/" /> : <RegisterPage />} />

        {/* 用户个人页面userpage */}
        <Route path="/profile/userInfo/:userid" element={<Userpage />}>
          {postTitles.map((item) => (
            <Route key="userPost" path={item} element={<UserPost />} />
          ))}
          {likeTitles.map((item) => (
            <Route key="userLike" path={item} element={<UserLike />} />
          ))}
          <Route path="followers" element={<Followers />} />
          <Route path="following" element={<Following />} />
          <Route path="housesave" element={<Housesave />} />
          <Route path="jobsave" element={<Jobsave />} />
          <Route path="newsave" element={<Newssave />} />
          <Route path="postsave" element={<Postsave />} />
        </Route>

        {/* 嵌套路由 */}
        <Route path="/*" element={<Home />}>
          <Route path="seekjob" element={<SeekJob />} />
          <Route path="community" element={<Community />}>
            <Route path="forum" element={<CommunityContent />}>
              <Route path="keyword=:terms" element={<CommunityContent />} />
              <Route path=":forumid" element={<CommunityForumDetail />} />
            </Route>

            <Route path="group" element={<CommunityGroup />} />
            <Route path="follow" element={<CommunityFollow />}>
              <Route path=":forumid" element={<CommunityForumDetail />} />
            </Route>
          </Route>
          <Route path="renthome" element={<Renthome />} />
        </Route>

        {/* 新闻详情页面 */}
        <Route path="/news/detail/:newsId" element={<Newsdetail />} />
        {/* Upload job page */}
        <Route path="/jobUpload" element={token ? <JobUploadPage /> : <LoginPage />} />
        <Route path="/jobUpload/update/:jobId" element={token ? <JobUploadPage /> : <LoginPage />} />
        {/* Upload rent page */}
        <Route path="/homeUpload" element={token ? <HomeUploadPage /> : <LoginPage />} />

        {/* 群组首页页面 */}
        <Route path="/group/groupId=:groupid" element={<Grouphome />}>
          <Route path="posts" element={<GroupForum />} />
          {/* <Route path="chat" element={<ChatRoom socketConnection={socketConnection} />} /> */}
          <Route path="chat" element={<ChatRoom />} />
        </Route>
      </Routes>
    </Mainapp>
  );
}

const Mainapp = styled.div`
/* tdesign帖子对话框样式 */
.community {
  /* 对话框主体尺寸 */
  width: 1000px;
  height: 800px;
  padding: 0;
  border: 0;
  border-radius: 24px;
  .t-dialog {
    @media (max-height: 900px), (max-width: 1366px) {
      width: 850px;
      height: 600px;
    }
  }
  /* 对话框内容样式 */
  .t-dialog__body {
    display: flex;
    padding: 0;
  }
  .t-dialog__position {
    padding-top: 9vh;
  }
  /* 轮播图样式 */
  .imgswiper {
    /* 轮播图箭头样式 */
    .splide__arrow {
      opacity: 0;
      transition: all 0.3s;
      background-clip: padding-box;
    }
    :hover {
      .splide__arrow {
        opacity: 1;
        transition: all 0.4s;
        background-color: #fff;
      }
    }
    /* 轮播图指示器样式 */
    .splide__pagination {
      display: flex;
    }
    .splide__pagination__page {
      background-color: #d0caca;
    }
    .splide__pagination__page.is-active {
      background: #d9a40e;
    }
    img {
      border-radius: 24px 0 0 24px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: zoom-in;
    }
  }
}
.group {
  /* 创建群组对话框主体尺寸 */
  .t-dialog {
    width: 75vw;
    height: 80vh;
    padding: 0;
    border: 0;
    border-radius: 24px;
  }
  /* 对话框内容样式 */
  .t-dialog__body {
    display: flex;
    padding: 0;
  }
  .t-dialog__position {
    padding-top: 9vh;
  }
}


`

export default App;
