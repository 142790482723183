import React from 'react';
import FormItem from 'tdesign-react/es/form/FormItem';
import Textarea from 'tdesign-react/es/textarea/Textarea';
import { Input, InputAdornment, Space } from 'tdesign-react';
import { BiPlus } from 'react-icons/bi';
import Button from 'tdesign-react/es/button/Button';
import { useDispatch } from 'react-redux';

import { List } from 'tdesign-react';
const { ListItem } = List;

function Step2({ step2Data }) {
  const [currentRequirement, setCurrentRequirement] = React.useState('');
  // const jobRequirement = useSelector(selectJobRequirement);
  // const defaultRequirements = isArray(jobRequirement) ? jobRequirement : JSON.parse(jobRequirement);

  function validateRequirement() {
    try {
      if (step2Data.jobRequirement.length !== 0) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  return (
    <>
      <FormItem
        className="tw-w-5/6 tw-font-bold"
        style={{ width: '50vw' }}
        label="职位描述"
        name="jobDescription"
        shouldUpdate={false}
        successBorder={false}
        initialData={step2Data.jobDescription}
        rules={[{ required: true, message: '请填写职位描述', type: 'error' }]}
      >
        <Textarea
          onChange={(e) => step2Data.setJobDescription(e)}
          autosize={{ minRows: 7, maxRows: 7 }}
          placeholder="请详细描述该岗位的职责"
          maxlength={3000}
        />
      </FormItem>

      {/* 检查工作要求至少有一个 */}
      <FormItem
        className="tw-w-5/6 tw-font-bold tw-flex tw-flex-col"
        style={{ width: '50vw' }}
        label="职位要求"
        name="jobRequirment"
        shouldUpdate={false}
        successBorder={false}
        rules={[{ validator: validateRequirement, message: '请填写职位要求' }]}
      >
        <div className="tw-border-2 tw-rounded-lg" style={{ height: '152px', width: '100%', overflow: 'auto' }}>
          <List size="small" split={true} stripe={true}>
            {step2Data.jobRequirement?.length === 0 && (
              <ListItem>
                <span style={{ color: '#A8A8A8' }}>请在下方输入职位要求, 一行一个</span>
              </ListItem>
            )}

            {step2Data.jobRequirement?.map((item) => (
              <ListItem
                key={item.id}
                action={
                  <>
                    <li>
                      <Button
                        shape="rectangle"
                        theme="danger"
                        size="small"
                        type="button"
                        variant="base"
                        onClick={() => {
                          const target = step2Data.JobRequirement.filter((i) => i.id !== item.id);
                          step2Data.setJobRequirement(target);
                        }}
                      >
                        移除
                      </Button>
                    </li>
                  </>
                }
              >
                {item.info}
              </ListItem>
            ))}
          </List>
        </div>
      </FormItem>

      <FormItem>
        <Space direction="vertical" style={{ width: '100%' }}>
          <InputAdornment
            append={
              <Button
                shape="rectangle"
                size="medium"
                type="button"
                variant="base"
                onClick={() => {
                  const target = [
                    ...step2Data.jobRequirement,
                    {
                      id: step2Data.jobRequirement.length,
                      info: currentRequirement,
                    },
                  ];
                  step2Data.setJobRequirement(target);
                  setCurrentRequirement('');
                }}
              >
                <BiPlus size={25} />
              </Button>
            }
          >
            <Input value={currentRequirement} onChange={(e) => setCurrentRequirement(e)} />
          </InputAdornment>
        </Space>
      </FormItem>
    </>
  );
}

export default Step2;
