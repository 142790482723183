import React, { useEffect, useState } from "react";
import {
  PostFollowUser,
  PostUnFollowUser,
  fetchFollowing,
  fetchLoginFollowing,
} from "../../api/ApiRequest";
import { useSelector } from "react-redux";
import { selectToken, selectUserId } from "../../Redux/slices/authSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { Avatar, Button } from "tdesign-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
function Following() {
  let { userid } = useParams();
  const myid = useSelector(selectUserId);
  let token = useSelector(selectToken);
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(0);
  const [hasmore, sethasmore] = useState(true);
  const [data, setData] = useState([]);
  const getFollowing = async () => {
    if (token) {
      const response = await fetchLoginFollowing(
        userid === undefined ? myid : userid,
        startIndex,
        token
      );
      if (response.data.info === "no more following") {
        sethasmore(false);
      } else {
        setData([...data, ...response.data.data]);
        setStartIndex((current) => current + response.data.data.length);
      }
    } else {
      const response = await fetchFollowing(userid, startIndex);
      if (response.data.info === "no more following") {
        sethasmore(false);
      } else {
        setData([...data, ...response.data.data]);
        setStartIndex((current) => current + response.data.data.length);
      }
    }
  };

  //   关注用户向后端发请求
  const handleFollow = async (userid, followstatus) => {
    // 如果登陆后
    if (token) {
      // 如果关注过用户就post取消关注
      if (followstatus) {
        await PostUnFollowUser(userid, token).then(() => {
          const newstate = [...data];
          for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].userid === userid) {
              newstate[index].is_following = false;
              break;
            }
          }
          setData(newstate);
        });
      } else {
        await PostFollowUser(userid, token).then(() => {
          const newstate = [...data];
          for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].userid === userid) {
              newstate[index].is_following = true;
              break;
            }
          }
          setData(newstate);
        });
      }
    }
    // 如果没登录，跳到登录
    else {
      navigate("/login");
    }
  };
  useEffect(() => {
    getFollowing();
  }, []);

  return (
    <Followerbox>
      <h1>全部关注</h1>
      <InfiniteScroll
        dataLength={data.length} //This is important field to render the next
        next={getFollowing}
        hasMore={hasmore}
        scrollableTarget="Jobparent"
      >
        {data?.map((item) => {
          return (
            <div key={item.userid} className="follower-item">
              <div className="avatar">
                <Link target="_blank" to={"/profile/userInfo/" + item.userid}>
                  <Avatar size="60px" image={item.avatar}>
                    {item.avatar === "" && item.username.slice(0, 1)}
                  </Avatar>
                </Link>
                <div className="detail">
                  <span>{item.username}</span>
                  <span className="signature">{item.signature}</span>
                </div>
              </div>
              {item.userid !== myid && (
                <Button
                  theme="warning"
                  onClick={() => {
                    handleFollow(item.userid, item.is_following);
                  }}
                >
                  {item.is_following ? "取消关注" : "关注"}
                </Button>
              )}
            </div>
          );
        })}
      </InfiniteScroll>
    </Followerbox>
  );
}

const Followerbox = styled.div`
  h1 {
    font-size: 18px;
    padding-bottom: 1rem;
    vertical-align: middle;
    border-bottom: 1px solid #e5e9ef;
  }
  .follower-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e9ef;
  }
  .avatar {
    display: flex;
  }
  .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 50px;
    font-size: 16px;
    color: #d9a40e;
    .signature {
      font-size: 12px;
      color: #333;
    }
  }
`;

export default Following;
