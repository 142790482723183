import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import { Pagination, Tag } from "tdesign-react";
import {
  PostHousesUnSave,
  fetchUserCollectHouses,
} from "../../../api/ApiRequest";
import { DeleteIcon } from "tdesign-icons-react";

import { selectToken, selectUserId } from "../../../Redux/slices/authSlice";

import moment from "moment";
import UserHouseDetail from "../UserHouseDetail";

function Housesave() {
  const myId = useSelector(selectUserId);
  const token = useSelector(selectToken);
  let params = useParams();

  //   获取房子收藏的总数
  const [collectnumber] = useOutletContext();

  // 获取收藏的工作
  const [getcollectHouse, setCollectHouse] = useState([]);
  const [pageIndex, setpageIndex] = useState(1);
  const fetchHouse = async () => {
    const response = await fetchUserCollectHouses(pageIndex, params.userid);
    setCollectHouse([...response.data.data]);
  };

  // 取消收藏
  const [trigger, setTrigger] = useState("");
  const handleDelete = async (event, houseid) => {
    event.preventDefault();
    await PostHousesUnSave(houseid, token).then(() => {
      setTrigger(houseid);
    });
  };

  // 判断个人页面租房弹框是否显示
  const [rentvisible, setRentVisible] = useState(false);
  const [targetForumId, setTargetForumId] = useState(null);

  const handleRentVisible = (Id) => {
    setTargetForumId(Id);
    setRentVisible(true);
  };

  const handleRentClose = () => {
    if (rentvisible) {
      setRentVisible(false);
    }
  };

  useEffect(() => {
    fetchHouse();
  }, [pageIndex, trigger]); //eslint-disable-line
  return (
    <div>
      <SaveBox>
        {getcollectHouse.map((items, index) => {
          return (
            <li key={index} onClick={() => handleRentVisible(items.houseid)}>
              <div className="header">
                {/* banner */}
                <div className="icon">
                  <img src={items.thumbnail} />
                </div>
              </div>
              <div className="title">{items.title}</div>
              {/* 标签 */}
              <div className="tw-mt-5">
                <Tag style={{ marginRight: "5px" }}>{items.locationname}</Tag>
                <Tag style={{ marginRight: "5px" }}>{items.name}</Tag>
                {params.userid === myId && (
                  <div className="time tw-flex tw-justify-between tw-mt-2">
                    <div>
                      收藏于：
                      {moment(items.collecttime * 1000).format("DD-MM-YYYY")}
                    </div>
                    <span>
                      <DeleteIcon
                        size="18px"
                        onClick={(e) => {
                          handleDelete(e, items.houseid);
                        }}
                      />
                    </span>
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </SaveBox>
      {collectnumber.housescollectionnum > 12 && (
        <Pagination
          current={pageIndex}
          foldedMaxPageBtn={5}
          pageEllipsisMode="mid"
          showFirstAndLastPageBtn={false}
          showJumper
          showPageNumber
          showPageSize={false}
          showPreviousAndNextBtn
          size="medium"
          theme="default"
          total={collectnumber.housescollectionnum}
          totalContent={false}
          onCurrentChange={(index) => {
            setpageIndex(index);
          }}
        />
      )}

      {rentvisible && (
        <UserHouseDetail
          visible={rentvisible}
          handleClose={handleRentClose}
          Id={targetForumId}
        />
      )}
    </div>
  );
}

const SaveBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;

  li {
    height: 250px;
    width: 15rem;
    margin-right: 10px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 20px;
    padding: 6px;
    background: rgb(255, 255, 255);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    :nth-child(4n) {
      margin-right: 0;
      @media (max-width: 1420px) {
        margin-right: 10px;
      }
    }
    :hover {
      /* box-shadow: 0 19px 39px 0 rgb(0 0 0 / 20%); */
      cursor: pointer;
      transform: translateY(-6px);
      box-shadow: 0 30px 50px rgba(0, 0, 0, 0.3);
      transition: all 0.3s;
    }
    li {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .icon {
      width: 100%;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .header {
      display: flex;
      margin-bottom: 10px;
      .companyname {
        width: 100%;
        text-align: center;
      }
    }
    .title {
      height: 60px;
      display: flex;
      align-items: center;
      font-size: 16px;
    }
    .time {
      padding-left: 2px;
      font-size: 13px;
    }
  }
`;
export default Housesave;
