import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  fetchNewsdetail,
  fetchNewsLike,
  fetchNewsSave,
  fetchRankNews,
  PostNewsLike,
  PostNewsSave,
  PostNewsUnLike,
  PostNewsUnSave,
} from "../../api/ApiRequest";
import Headers from "../Home/Headers";
import ReplyComment from "./ReplyComment";
import {
  HeartIcon,
  HeartFilledIcon,
  StarIcon,
  StarFilledIcon,
  ChatIcon,
} from "tdesign-icons-react";
import Footer from "../Home/Footer";
import { useSelector } from "react-redux";
import { selectToken } from "../../Redux/slices/authSlice";
import { Drawer } from "tdesign-react";

function Newsdetail() {
  let params = useParams();
  const nevigate = useNavigate();
  let token = useSelector(selectToken);
  //   定义一个方法向后端请求数据
  const [newsdata, setNewsdata] = useState([]);

  const fetchData = async () => {
    const response = await fetchNewsdetail(params.newsId);
    setNewsdata(response.data.data);
  };

  // 收藏点赞评论状态
  const [like, setLike] = useState(false);
  const [save, setSave] = useState(false);

  // 评论抽屉效果
  const [visible, setVisible] = useState(false);
  const handleDrawer = () => {
    if (visible) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  };
  // 点赞新闻
  const handleLike = async () => {
    if (token) {
      // 没收藏的时候
      if (like === false) {
        PostNewsLike(params.newsId, token).then(setLike(true));
      }
      // 取消收藏
      else {
        PostNewsUnLike(params.newsId, token).then(setLike(false));
      }
    } else {
      nevigate("/login");
    }
  };

  // 收藏新闻
  const handleSave = async () => {
    if (token) {
      // 没收藏的时候
      if (save === false) {
        const response = PostNewsSave(params.newsId, token).then(setSave(true));
      }
      // 取消收藏
      else {
        PostNewsUnSave(params.newsId, token).then(setSave(false));
      }
    } else {
      nevigate("/login");
    }
  };

  // 查看用户是否点赞过新闻
  const checkLikeStatus = async () => {
    const response = await fetchNewsLike(params.newsId, token);
    if (response.data.data.likestatus) {
      setLike(true);
    } else {
      setLike(false);
    }
  };
  // 查看用户是否收藏过新闻
  const checkSaveStatus = async () => {
    const response = await fetchNewsSave(params.newsId, token);
    if (response.data.status) {
      setSave(true);
    }
    if (!response.data.status) {
      setSave(false);
    }
  };

  // 获取热榜新闻
  const [ranknews, setRankNews] = useState([]);
  const fetchRank = async () => {
    const response = await fetchRankNews();
    setRankNews(response.data.data);
  };
  useEffect(() => {
    if (token) {
      checkLikeStatus();
      checkSaveStatus();
      fetchRank();
      fetchData();
    } else {
      fetchRank();
      fetchData();
    }
  }, []);

  return (
    <Bigbox>
      <div className="user_header">
        <Headers />
      </div>
      {/* 主体内容 */}
      <Detail>
        <LeftSideBox>
          <li onClick={handleLike}>
            {like ? (
              <HeartFilledIcon size="30px" style={{ color: "ff2442" }} />
            ) : (
              <HeartIcon size="30px" />
            )}
            点赞
          </li>
          <li onClick={() => handleSave()}>
            {save ? (
              <StarFilledIcon size="30px" style={{ color: "#FCDC00" }} />
            ) : (
              <StarIcon size="30px" />
            )}
            收藏
          </li>
          <li onClick={() => handleDrawer()}>
            <ChatIcon size="30px" />
            评论
          </li>
        </LeftSideBox>
        <div>
          <Newscontent dangerouslySetInnerHTML={{ __html: newsdata.content }} />
          <CommentBox>
            <h3>网友评论</h3>
            <ReplyComment newsId={params.newsId} />
          </CommentBox>
        </div>
        <RightSideBox>
          <h3>热榜新闻</h3>
          <ol>
            {ranknews?.map((item) => {
              return (
                <li key={item.newsid}>
                  <Link target="_blank" to={"/news/detail/" + item.newsid}>
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ol>
        </RightSideBox>
      </Detail>
      <Drawer
        visible={visible}
        onClose={handleDrawer}
        footer={false}
        header={<div className="tw-text-xl">评论 数量</div>}
        size="445px"
      >
        <div>
          <ReplyComment newsId={params.newsId} />
        </div>
      </Drawer>
      {/* 底部footer */}
      <Footer />
    </Bigbox>
  );
}

// 外层大盒子
const Bigbox = styled.div`
  background-color: #fff;
  .user_header {
    background-color: #fff;
    height: 4rem;
    box-shadow: 0 2px 4px #00000014;
    z-index: 500;
  }
  li {
    color: #212121;
    font-size: 0.875rem;
    text-shadow: none;
  }
`;

// 内容大盒子
const Detail = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 40px;
  width: 1200px;
`;

// 左边侧边栏
const LeftSideBox = styled.ul`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 100px;
  margin-left: -100px;
  li {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    cursor: pointer;
  }
`;

// 文章内容盒子
const Newscontent = styled.div`
  width: 730px;
  word-wrap: break-word;
  font-size: 18px;
  h1 {
    font-size: 36px;
    font-weight: 600;
    color: #333333;
  }
  p {
    margin-bottom: 30px;
  }
  img {
    margin: 0 auto;
  }
`;

// 右边侧边栏
const RightSideBox = styled.div`
  width: 300px;
  h3 {
    font-size: 20px;
    color: #111827;
  }
  ol {
    padding-left: 20px;
  }
  li {
    font-size: 16px;
    list-style: decimal;
    padding: 12px 0;
  }
`;
const CommentBox = styled.div`
  width: 676px;
  margin: 0 auto;
  margin-top: 40px;
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
`;
export default Newsdetail;
