import React from 'react';
import Form from 'tdesign-react/es/form/Form';
import Button from 'tdesign-react/es/button/Button';
import Step1 from './Step1';
import { PostNewHomes } from '../../api/ApiRequest';
import { useSelector, useDispatch } from 'react-redux';
import { selectToken } from '../../Redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';

import RenthomeStep from '../../components/Renthome/RenthomeStep';
import Step2 from './Step2';
import {
  reset,
  selectCheckinTime,
  selectCurrentStep,
  selecthouseDescription,
  selectHousetype,
  selectHouseunit,
  selectImgs,
  selectImgsUrl,
  selectLocation,
  selectMinimumperiod,
  selectOrigin,
  selectPriceweek,
  selectRentmethod,
  selectTitle,
  setCurrentStep,
} from '../../Redux/slices/rentUploadSlice';
function HomeUploadPage() {
  const [form] = Form.useForm();
  const currentStep = useSelector(selectCurrentStep);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const navigate = useNavigate();

  const handleChange = async (e) => {
    if (e.validateResult === true) {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  // 判断租金范围，返回一个数值做筛选
  const ispriceRange = (price) => {
    if (price < 100) {
      return 1;
    } else if (150 > price && price >= 100) {
      return 2;
    } else if (200 > price && price >= 150) {
      return 3;
    } else if (300 > price && price >= 200) {
      return 4;
    } else if (500 > price && price >= 300) {
      return 5;
    } else if (1000 > price && price >= 500) {
      return 6;
    } else {
      return 7;
    }
  };

  const title = useSelector(selectTitle);
  const priceUnit = useSelector(selectHouseunit);
  const ispriceUnit = useSelector(selectPriceweek);
  const price = priceUnit === '面议' ? 0 : ispriceUnit;
  const priceRange = priceUnit === '面议' ? 8 : ispriceRange(price);
  const location = useSelector(selectLocation);
  const houseFrom = useSelector(selectOrigin);
  const category = useSelector(selectHousetype);
  const method = useSelector(selectRentmethod);
  const checkInTime = useSelector(selectCheckinTime);
  const minimumPeriod = useSelector(selectMinimumperiod);
  const detail = useSelector(selecthouseDescription);
  const images = useSelector(selectImgsUrl).toString();
  // 提交数据给后端
  const handleSubmit = async () => {
    try {
      await PostNewHomes(
        title,
        price,
        priceRange,
        priceUnit,
        images,
        houseFrom,
        location,
        category,
        method,
        detail,
        checkInTime,
        minimumPeriod,
        token
      );
      navigate('/renthome');
      dispatch(reset());
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className="tw-h-screen tw-w-scree tw-flex tw-flex-col tw-justify-center tw-items-center"
      style={{ backgroundColor: '#E37318', opacity: 0.9 }}
    >
      {/* <UploadStep /> */}
      <div className="tw-bg-white tw-h-5/6 tw-w-5/6 tw-rounded-xl tw-flex tw-flex-col tw-items-center tw-relative">
        <RenthomeStep current={currentStep} />
        <Form
          form={form}
          colon={true}
          labelAlign="top"
          labelWidth="100px"
          layout="vertical"
          preventSubmitDefault
          requiredMark
          resetType="initial"
          showErrorMessage
          statusIcon
          submitWithWarningMessage={false}
          onSubmit={handleChange}
        >
          {currentStep === 0 && <Step1 />}
          {currentStep === 1 && <Step2 />}

          {currentStep !== 0 && (
            <Button
              shape="rectangle"
              theme="danger"
              size="medium"
              type="button"
              variant="base"
              className="tw-absolute tw-bottom-6 tw-right-48"
              onClick={() => dispatch(setCurrentStep(currentStep - 1))}
            >
              上一步
            </Button>
          )}
          {currentStep === 1 ? (
            <Button
              shape="rectangle"
              size="medium"
              type="submit"
              variant="base"
              className="tw-absolute tw-bottom-6 tw-right-20"
              onClick={handleSubmit}
            >
              提交
            </Button>
          ) : (
            <Button
              shape="rectangle"
              size="medium"
              type="submit"
              variant="base"
              className="tw-absolute tw-bottom-6 tw-right-20"
            >
              下一步
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
}

export default HomeUploadPage;
