function getCursor() {
  return { timeStamp: new Date().getTime() / 1000 };
}

function createCursor(timeStamp) {
  return { timeStamp: timeStamp };
}

function getCommentCursor() {
  const currentTimeStamp = new Date().getTime() / 1000;
  return { timeStamp: currentTimeStamp, replyTimeStamp: [] };
}

export default getCursor;
export { getCommentCursor, createCursor };
