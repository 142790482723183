import React, { useEffect, useState } from 'react';
import { Avatar, Button, Comment, Textarea, Space } from 'tdesign-react';
import {
  checkNewsReplystatus,
  fetchNewsExpandReply,
  PostNewsSecondreply,
  PutLikeSecondComment,
  PutUnlikeSecondComment,
} from '../../api/ApiRequest';
import { HeartFilledIcon, ChatIcon, CaretRightSmallIcon } from 'tdesign-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectUserId } from '../../Redux/slices/authSlice';
import { getDateDiff } from '../../utils/getDateDiff';
import { createCursor } from '../../utils/getCursor';

function Secondlycomment({ commentId, token, initialData, totalNum, needToExpend, creator, timeStamp }) {
  const userid = useSelector(selectUserId);
  const [cursor, setCursor] = useState(createCursor(timeStamp));

  const replyInputRef = React.useRef(null);
  const secondaryReplyInputRef = React.useRef(null);

  // 从后端获取特定评论的回复
  const [replies, setReplies] = useState([]);

  const handleExpand = async () => {
    const response = await fetchNewsExpandReply(commentId, cursor);
    setReplies((current) => [...current, ...response.data.data]);
    setCursor(response.data.cursor);
  };

  // 登录后从后端获取特定评论的回复
  const commentStatus = async () => {
    if (commentId) {
      console.log(cursor);
      const response = await checkNewsReplystatus(commentId, token, cursor);
      setReplies((current) => [...current, ...response.data.data]);
      setCursor(response.data.cursor);
    }
  };

  //   点赞二级评论功能
  const [likebutton, setLikebutton] = useState([]);
  const [unlikebutton, setUnLikebutton] = useState([]);
  let navigate = useNavigate();
  const handleLike = (id, liked, creator) => {
    if (token) {
      if (likebutton.includes(id) || liked !== '-1') {
        if (!unlikebutton.includes(id)) {
          PutUnlikeSecondComment(id, token).then(() => {
            setUnLikebutton((current) => [...current, id]);
            setLikebutton((current) => current.filter((item) => item !== id));
          });
        } else {
          PutLikeSecondComment(id, token, creator)
            .then(() => {
              setLikebutton((current) => [...current, id]);
              setUnLikebutton((current) => current.filter((item) => item !== id));
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        PutLikeSecondComment(id, token, creator)
          .then(() => {
            setLikebutton((current) => [...current, id]);
            setUnLikebutton((current) => current.filter((item) => item !== id));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else navigate('/login');
  };

  // 回复框功能
  const [expendReply, setExpandReply] = useState(undefined);
  const [secondcontent, setSecondcontent] = useState('');

  const handlereply = (id) => {
    if (expendReply === id) {
      setExpandReply(undefined);
    } else {
      setExpandReply(id);
    }
  };

  const handleSecondlSubmit = async (commentId, receiver, replytofirst, replyId) => {
    if (secondcontent !== '') {
      setSecondcontent('');
      const response = await PostNewsSecondreply(commentId, secondcontent, receiver, replytofirst, token);
      setExpandReply(undefined);
      if (replytofirst === true) {
        setReplies((current) => [response.data.data, ...current]);
      } else {
        const index = replies.findIndex((item) => item.replyid === replyId);
        const newReplies = [...replies];
        newReplies.splice(index + 1, 0, response.data.data);
        setReplies(newReplies);
      }
    } else {
      console.log('内容不能为空');
    }
  };

  useEffect(() => {
    setReplies(initialData);
  }, []);

  useEffect(() => {
    setSecondcontent('');
  }, [needToExpend]);

  return (
    <div>
      {needToExpend === commentId && (
        <Publish onClick={() => replyInputRef.current.focus()}>
          <div
            className="textinput tw-flex-1 tw-relative tw-mt-4 tw-ml-2"
            style={secondcontent === '' ? { height: '100px' } : { height: '150px' }}
          >
            <textarea
              value={secondcontent}
              ref={replyInputRef}
              placeholder="我来说两句"
              onChange={(e) => setSecondcontent(e.target.value)}
            />
            <Button
              className="tw-absolute tw-right-3 tw-bottom-3"
              theme={secondcontent === '' ? 'default' : 'warning'}
              disabled={secondcontent === '' ? true : false}
              onClick={() => handleSecondlSubmit(commentId, creator, true, -1)}
            >
              回复
            </Button>
          </div>
        </Publish>
      )}

      {replies?.map((item) => {
        return (
          <div key={item.replyid}>
            <Comment
              avatar={
                <Link
                  target="_blank"
                  to={userid === item.senderid ? '/userpage' : '/profile/userInfo/' + item.senderid}
                  key={item.senderid}
                >
                  <Avatar size="48px" image={item.senderavatar} />
                </Link>
              }
              //评论对象
              author={
                item.replytofirst === false ? (
                  <>
                    <span>{item.sendername}</span>
                    <CaretRightSmallIcon size="small" />
                    <span>{item.receivername}</span>
                  </>
                ) : (
                  <span>{item.sendername}</span>
                )
              }
              datetime={getDateDiff(item.createdtime)}
              content={item.content}
              actions={[
                <span></span>,

                // 点赞功能
                <div key="ThumbUp" onClick={() => handleLike(item.replyid, item.liked, item.senderid)}>
                  <HeartFilledIcon
                    size="1.3rem"
                    style={{
                      marginRight: '5px',
                      color:
                        (token && item.liked !== '-1' && !unlikebutton.includes(item.replyid) && `#ff2442`) ||
                        (likebutton.includes(item.replyid) && `#ff2442`) ||
                        (unlikebutton.includes(item.replyid) && ''),
                    }}
                  />
                  <span>
                    {likebutton.includes(item.replyid) && (item.liked !== '-1' ? item.likes : item.likes + 1)}
                    {unlikebutton.includes(item.replyid) && (item.likes >= 1 ? item.likes - 1 : item.likes)}
                    {!unlikebutton.includes(item.replyid) && !likebutton.includes(item.replyid) && item.likes}
                  </span>
                </div>,

                // 回复框
                <div key="Chat" onClick={() => handlereply(item.replyid)}>
                  <ChatIcon size="16px" />
                  <span>回复</span>
                </div>,
              ]}
            />

            {expendReply === item.replyid && (
              <Publish onClick={() => secondaryReplyInputRef.current.focus()}>
                <div
                  className="textinput tw-flex-1 tw-relative tw-mt-4 tw-ml-2"
                  style={secondcontent === '' ? { height: '100px' } : { height: '150px' }}
                >
                  <textarea
                    value={secondcontent}
                    ref={secondaryReplyInputRef}
                    placeholder="我来说两句"
                    onChange={(e) => setSecondcontent(e.target.value)}
                  />
                  <Button
                    className="tw-absolute tw-right-3 tw-bottom-3"
                    theme={secondcontent === '' ? 'default' : 'warning'}
                    disabled={secondcontent === '' ? true : false}
                    onClick={() => handleSecondlSubmit(commentId, creator, false, item.replyid)}
                  >
                    回复
                  </Button>
                </div>
              </Publish>
            )}
          </div>
        );
      })}

      {totalNum > replies.length && (
        <div className="tw-flex tw-justify-center tw-mt-3 tw-cursor-pointer">
          <span onClick={commentStatus}>查看剩余 {totalNum - replies.length} 条回复</span>
        </div>
      )}
    </div>
  );
}

const Publish = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 20px;
  border: 1.5px solid #ddd;
  border-radius: 6px;
  background-color: #fff;

  textarea {
    resize: none;
    width: 100%;
    height: 60%;
    position: relative;
    font-size: 16px;
    outline: none;
  }
  // .t-is-disabled {
  //   cursor: default;
  // }
`;

const ReplyBox = styled.div`
  display: flex;
  height: 4.875rem;
  margin-top: var(--td-comp-margin-l);
  margin-left: calc(var(--td-comp-size-xxxl) + var(--td-comp-margin-xxl));
  border-radius: var(--td-radius-medium);
  button {
    height: 100%;
  }
`;

export default Secondlycomment;
