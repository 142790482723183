import React from "react";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Button, Avatar } from "tdesign-react";
import {
  selectIsLogin,
  selectToken,
} from "../../../../../Redux/slices/authSlice";
import { selectCurrentTag } from "../../../../../Redux/slices/homeSlice";
import { setCurrentTag } from "../../../../../Redux/slices/homeSlice";
import { ImNewspaper } from "react-icons/im";
import { MdWork, MdForum, MdHomeWork } from "react-icons/md";
import { BiParty } from "react-icons/bi";
import { CgLoadbar } from "react-icons/cg";
import { HiDotsHorizontal } from "react-icons/hi";
import CategoryTab from "../../../../../components/Home/CategoryTab";
import { motion, AnimatePresence } from "framer-motion";
import groupImage from "../../../../../img/group.svg";
import friendImage from "../../../../../img/friends.svg";
import { fetchUserGroups } from "../../../../../api/ApiRequest";

function Category() {
  const currentTag = useSelector(selectCurrentTag);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const isLogin = useSelector(selectIsLogin);
  const token = useSelector(selectToken);

  const [groups, setGroups] = React.useState(null);

  const handleChange = (index, e) => {
    dispatch(setCurrentTag(index));
    Navigate(e);
  };

  const iconStyle = {
    fontSize: "1.6rem",
  };

  React.useEffect(() => {
    if (isLogin) {
      fetchUserGroups(token).then((res) => {
        setGroups(res.data.groups);
      });
    }
  }, [isLogin]);

  return (
    <Sub className="tw-w-56 tw-h-full">
      {/* tab导航栏切换 */}
      <Tab onClick={() => handleChange(1, "/")}>
        <CategoryTab
          isSelected={currentTag === 1}
          icon={<ImNewspaper style={iconStyle} />}
          title={"新闻头条"}
        />
      </Tab>
      <Tab onClick={() => handleChange(2, "seekjob")}>
        <CategoryTab
          isSelected={currentTag === 2}
          icon={<MdWork style={iconStyle} />}
          title={"工作招聘"}
        />
      </Tab>
      <Tab onClick={() => handleChange(3, "community/forum")}>
        <CategoryTab
          isSelected={currentTag === 3}
          icon={<MdForum style={iconStyle} />}
          title={"贴贴论坛"}
        />
      </Tab>
      <Tab onClick={() => handleChange(4, "renthome")}>
        <CategoryTab
          isSelected={currentTag === 4}
          icon={<MdHomeWork style={iconStyle} />}
          title={"租房找房"}
        />
      </Tab>
      <Tab onClick={() => handleChange(5, "cityevent")}>
        <CategoryTab
          isSelected={currentTag === 5}
          icon={<BiParty style={iconStyle} />}
          title={"同城活动"}
        />
      </Tab>

      <AnimatePresence>
        {currentTag === 3 && (
          <motion.div
            className=" tw-bg-gray-100 tw-w-full tw-flex-1 tw-self-stretch tw-rounded-t-xl tw-flex tw-flex-col tw-items-center"
            exit={{ y: "100%" }}
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            transition={{ duration: 1.1, type: "spring", bounce: 0.15 }}
          >
            <div className="tw-w-full tw-flex tw-justify-center tw-items-center">
              <CgLoadbar
                style={{
                  fontSize: "1.6rem",
                }}
              />
            </div>

            <div className="tw-text-base tw-font-semibold tw-text-gray-900 tw-w-full tw-flex tw-items-center tw-justify-between tw-px-3 tw-border-b-2 tw-border-gray-300  ">
              <div>关注的群组</div>
              <HiDotsHorizontal
                style={{ fontSize: "1.3rem", cursor: "pointer" }}
              />
            </div>

            {isLogin ? (
              groups?.length === 0 ? (
                <div className="tw-flex-1 tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center ">
                  <img
                    src={friendImage}
                    alt="friendSVG"
                    width={"80%"}
                    height={"80%"}
                  />
                  <div className="tw-text-base tw-text-gray-900">
                    你还没有加入群组哟
                  </div>
                  <Button
                    className="tw-mt-2"
                    size="medium"
                    theme="warning"
                    variant="base"
                    onClick={() => Navigate("/login")}
                  >
                    探索群组
                  </Button>
                </div>
              ) : (
                <div className="tw-flex-1 tw-w-full tw-overflow-auto tw-flex tw-flex-col tw-gap-1 tw-pt-2 ">
                  {groups?.map((group) => (
                    <div
                      key={group.groupid}
                      className="tw-flex tw-items-center tw-px-3 tw-justify-between"
                    >
                      <div className="tw-flex tw-items-center tw-gap-2">
                        <Avatar
                          className=" tw-inline-block"
                          size="large"
                          image={group.avatar}
                          shape="circle"
                        />
                        <div className="tw-text-base tw-text-gray-900">
                          {group.name}
                        </div>
                      </div>
                      <Link
                        target="_blank"
                        to={`/group/groupId=${group.groupid}/posts`}
                      >
                        <Button size="small" theme="warning" variant="base">
                          进入
                        </Button>
                      </Link>
                    </div>
                  ))}
                </div>
              )
            ) : (
              <div className="tw-flex-1 tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center ">
                <img
                  src={groupImage}
                  alt="groupSVG"
                  width={"80%"}
                  height={"80%"}
                />
                <div className="tw-text-base tw-text-gray-900">
                  生活大小事, 都在贴贴
                </div>
                <Button
                  className="tw-mt-2"
                  size="medium"
                  theme="warning"
                  variant="base"
                  onClick={() => Navigate("/login")}
                >
                  去登录
                </Button>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </Sub>
  );
}

const Sub = styled.div`
  font-size: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  .current_block {
    display: block;
    padding-top: 10px;
  }
  .current_none {
    display: none;
  }
`;

const Tab = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default Category;
