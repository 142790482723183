import React from 'react';
import UploadStep from '../../components/Job/UploadStep';

import Form from 'tdesign-react/es/form/Form';
import Button from 'tdesign-react/es/button/Button';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import { PostNewJobs, UpdateJobs } from '../../api/ApiRequest';
import { useSelector, useDispatch } from 'react-redux';
import { selectToken } from '../../Redux/slices/authSlice';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { reset, selectLocation } from '../../Redux/slices/jobUploadSlice';

// 把传过来的数据转换成值
function convertJobCategory(category) {
  switch (category) {
    case '全职':
      return '1';
    case '兼职':
      return '2';
    case '临时工':
      return '3';
    case '合同工':
      return '4';
    case '实习生':
      return '5';
  }
}

function convertJobTypes(category) {
  switch (category) {
    case '互联网/通信':
      return '1';
    case '传媒/艺术/设计':
      return '2';
    case '财务/人力/行政':
      return '3';
    case '销售/市场/业务':
      return '4';
    case '建筑/土木工程/施工员':
      return '5';
    case '技工/工人/学徒':
      return '6';
    case '厨师/服务员/帮工':
      return '7';
    case '医护/按摩/美容/发型':
      return '8';
    case '清洁/搬运/库管/司机':
      return '9';
    case '网管/IT安装/程序员':
      return '10';
    case '保姆/月嫂/钟点工':
      return '11';
    case '店员/收银/客服':
      return '12';
    case '教师/教练/家教':
      return '13';
    case '旅行/导游':
      return '14';
    case '编辑/翻译/律师':
      return '15';
  }
}

function UploadJobPage() {
  const { jobId } = useParams();
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = React.useState(0);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);

  const handleChange = async (e) => {
    if (currentStep == 2) {
      if (e.validateResult === true) {
        if (jobId) {
          handleUpdate(jobId);
        } else {
          handleSubmit();
        }
      }
    } else {
      if (e.validateResult === true) {
        setCurrentStep((current) => current + 1);
      }
    }
  };

  React.useEffect(() => {
    const handleBeforeUnload = (event) => {
      // 提示信息
      const confirmationMessage = 'Are you sure you want to leave?';
      // 兼容性处理
      event.preventDefault();
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    // Attach the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const isUpdate = state !== null && state.getUpdate === undefined;
  // 上传职位信息状态
  const locations = useSelector(selectLocation);

  const [title, setTitle] = React.useState(isUpdate ? state.getData.title : '');
  const [salaryFrom, setSalaryFrom] = React.useState(isUpdate ? state.getData.salaryfrom : '');
  const [salaryTo, setSalaryTo] = React.useState(isUpdate ? state.getData.salaryto : '');
  const [salaryUnit, setSalaryUnit] = React.useState(isUpdate ? state.getData.salaryunit : '面议');
  const [experience, setExperience] = React.useState(isUpdate ? state.getData.experience : '不限');
  const [location, setLocation] = React.useState(isUpdate ? state.getData.locationid : '');

  const [category, setCategory] = React.useState(isUpdate ? convertJobCategory(state.getData.jobcategoryname) : '1');
  const [industry, setIndustry] = React.useState(isUpdate ? convertJobTypes(state.getData.industrytype) : '');
  const [companyName, setCompanyName] = React.useState(isUpdate ? state.getData.companytitle : '');
  const [companyDescription, setCompanyDescription] = React.useState(isUpdate ? state.getData.companydescription : '');
  const [jobDescription, setJobDescription] = React.useState(isUpdate ? state.getData.jobdescription : '');
  const [companyAdvantage, setCompanyAdvantage] = React.useState(
    isUpdate ? JSON.parse(state.getData.companyadvantage) : []
  );
  const [jobRequirement, setJobRequirement] = React.useState(isUpdate ? JSON.parse(state.getData.jobrequirment) : []);
  const [submitLoading, setSubmitLoading] = React.useState(false);

  const step1Data = {
    title,
    setTitle,
    salaryFrom,
    setSalaryFrom,
    salaryTo,
    setSalaryTo,
    salaryUnit,
    setSalaryUnit,
    experience,
    setExperience,
    locations,
    location,
    setLocation,
    category,
    setCategory,
    industry,
    setIndustry,
  };

  const step2Data = {
    jobDescription,
    setJobDescription,
    jobRequirement,
    setJobRequirement,
  };

  const step3Data = {
    companyName,
    setCompanyName,
    companyDescription,
    setCompanyDescription,
    companyAdvantage,
    setCompanyAdvantage,
  };

  React.useLayoutEffect(() => {
    if (state !== null && state.getUpdate === undefined) {
      state.getUpdate = true;
    }
  }, []);

  // 提交数据给后端
  const handleSubmit = async () => {
    try {
      setSubmitLoading(true);
      await PostNewJobs(
        title,
        salaryFrom,
        salaryTo,
        salaryUnit,
        experience,
        location,
        category,
        industry,
        companyName,
        companyDescription,
        jobDescription,
        companyAdvantage,
        jobRequirement,
        token
      );
      dispatch(reset());
      setSubmitLoading(false);
      // close the tab
      window.close();
    } catch (error) {
      setSubmitLoading(false);
      console.error(error);
    }
  };

  const handleUpdate = async (jobId) => {
    try {
      setSubmitLoading(true);
      await UpdateJobs(
        jobId,
        title,
        salaryFrom,
        salaryTo,
        salaryUnit,
        experience,
        location,
        category,
        industry,
        companyName,
        companyDescription,
        jobDescription,
        companyAdvantage,
        jobRequirement,
        token
      );
      navigate(-1);
      dispatch(reset());
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      console.error(error);
    }
  };

  return (
    <div
      className="tw-h-screen tw-w-scree tw-flex tw-flex-col tw-justify-center tw-items-center"
      style={{ backgroundColor: '#E37318', opacity: 0.9 }}
    >
      {/* <UploadStep /> */}
      <div className="tw-bg-white tw-h-5/6 tw-w-5/6 tw-rounded-xl tw-flex tw-flex-col tw-items-center tw-relative">
        <UploadStep current={currentStep} />
        <Form
          form={form}
          colon={true}
          labelAlign="top"
          labelWidth="100px"
          layout="vertical"
          preventSubmitDefault
          requiredMark
          resetType="initial"
          showErrorMessage
          statusIcon
          submitWithWarningMessage={false}
          onSubmit={handleChange}
        >
          {currentStep === 0 && <Step1 step1Data={step1Data} />}
          {currentStep === 1 && <Step2 step2Data={step2Data} />}
          {currentStep === 2 && <Step3 step3Data={step3Data} />}

          {currentStep !== 0 && (
            <Button
              shape="rectangle"
              theme="danger"
              size="medium"
              type="button"
              variant="base"
              className="tw-absolute tw-bottom-6 tw-right-48"
              onClick={() => setCurrentStep((current) => current - 1)}
            >
              上一步
            </Button>
          )}

          {currentStep === 2 ? (
            <Button
              shape="rectangle"
              size="medium"
              type="submit"
              variant="base"
              className="tw-absolute tw-bottom-6 tw-right-20"
              loading={submitLoading}
            >
              {submitLoading ? '提交中' : '提交'}
            </Button>
          ) : (
            <Button
              shape="rectangle"
              size="medium"
              type="submit"
              variant="base"
              className="tw-absolute tw-bottom-6 tw-right-20"
            >
              下一步
            </Button>
          )}
        </Form>
      </div>
    </div>
  );
}

export default UploadJobPage;
