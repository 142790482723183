import React from 'react';
import styles from './Background.module.css';

function Background({ children }) {
  return (
    <div
      id={styles.background}
      style={{ width: '100vw', height: '100vh' }}
      className="tw-flex tw-justify-center tw-items-center"
    >
      {children}
    </div>
  );
}

export default Background;
