import React from "react";
import InfiniteScroll from "react-infinite-scroll-component"; //无限滚动
import { useState, useEffect } from "react";
import { fetchJobslist } from "../../../../../../api/ApiRequest";
import styled from "styled-components";
import { Avatar, Tag } from "tdesign-react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectjobId,
  setcreatedtime,
  setjobId,
} from "../../../../../../Redux/slices/jobSlice";
import { getDateDiff } from "../../../../../../utils/getDateDiff";
import getCursor from "../../../../../../utils/getCursor";
import ListLoading from "../../../../../../components/Loading/ListLoading";

function JobList({ category, experience, industry, location }) {
  // 根据状态渲染当前样式
  let active = useSelector(selectjobId);

  // 发送jobId给redux，渲染对应的详细信息
  const dispatch = useDispatch();

  const handleclick = (jobid, createdtime) => {
    dispatch(setjobId(jobid));
    dispatch(setcreatedtime(createdtime));
  };

  // 从后端获取数据
  const [getData, setGetdata] = useState([]);

  const [cursor, setCursor] = useState(getCursor());
  const [hasmore, sethasmore] = useState(true);

  const fetchData = async (changeSearch, isFirstTime) => {
    let response = null;
    if (changeSearch) {
      setGetdata([]);
      const newCursor = getCursor();
      console.log("newCursor", newCursor);
      setCursor(newCursor);
      sethasmore(true);
      response = await fetchJobslist(
        newCursor,
        category,
        experience,
        industry,
        location
      );
    } else {
      response = await fetchJobslist(
        cursor,
        category,
        experience,
        industry,
        location
      );
    }

    if (response.data.info === "no more jobs") {
      if (isFirstTime) {
        dispatch(setjobId(""));
        dispatch(setcreatedtime(""));
      }
      sethasmore(false);
    } else {
      setGetdata((current) => [...current, ...response.data.data]);
      if (response.data.data.length < 3) sethasmore(false);
      console.log("cursor", response.data.cursor);
      setCursor(response.data.cursor);
      if (isFirstTime) {
        dispatch(setjobId(response.data.data[0].jobid));
        dispatch(
          setcreatedtime(getDateDiff(response.data.data[0].createdtime))
        );
      }
    }
  };

  useEffect(() => {
    fetchData(true, true);
  }, [category, experience, industry, location]);

  return (
    <Container id="jobParent">
      <InfiniteScroll
        dataLength={getData.length} //This is important field to render the next
        next={() => fetchData(false, false)}
        hasMore={hasmore}
        loader={<ListLoading />}
        scrollableTarget="jobParent"
        endMessage={
          <div
            className=" tw-flex tw-items-center tw-justify-center"
            style={{ fontSize: "16px", height: "60px" }}
          >
            <b>-已经看到底了-</b>
          </div>
        }
      >
        {getData?.map((item) => {
          //   获取头像
          let username = item.username;
          let defaulticon = username?.slice(0, 1);
          let date = getDateDiff(item.createdtime);
          return (
            <JobsList
              key={item.jobid}
              onClick={() => handleclick(item.jobid, date)}
              className={active === item.jobid ? "active" : ""}
            >
              <div className="icon">
                <Avatar
                  shape="round"
                  size="40px"
                  image={item.avatar !== "" && item.avatar}
                >
                  {item.avatar === "" ? defaulticon : item.avatar}
                </Avatar>
              </div>
              <div className="list_content">
                <div className="title">
                  <h4>{item.title}</h4>
                  <h2>
                    {item.salaryfrom}
                    {item.salaryunit !== "面议" && "-"}
                    {item.salaryto}
                    {item.salaryunit !== "面议" && "/"}
                    {item.salaryunit}
                  </h2>
                  <h3>{item.companytitle}</h3>
                </div>
                {/* 标签管理 */}
                <div className="tag">
                  {/* 发布时间 */}
                  <Tag theme="warning" variant="outline" size="small">
                    {getDateDiff(item.createdtime)}
                  </Tag>
                  {/* 工作类型 */}
                  <Tag theme="warning" variant="outline" size="small">
                    {item.jobcategoryname}
                  </Tag>
                  {/* 公司位置 */}
                  <Tag theme="warning" variant="outline" size="small">
                    {item.locationname}
                  </Tag>
                </div>
              </div>
            </JobsList>
          );
        })}
      </InfiniteScroll>
    </Container>
  );
}

const Container = styled.div`
  overflow-y: auto;
  height: 100%;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const JobsList = styled.div`
  display: flex;
  position: relative;
  padding-top: 10px;
  padding-left: 0.3125rem;
  cursor: pointer;
  border-right: 0.0625rem solid #e0e0e0;
  background-color: ${(props) =>
    props.className === "active" && "rgba(255,193,7,0.25)"};

  .list_content {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: #e37353;
    }
    h2 {
      font-size: 16px;
      margin-top: 10px;
      color: #000000e6;
    }
    h3 {
      font-size: 15px;
      margin-top: 8px;
    }
  }
  .tag {
    display: flex;
    margin-bottom: 5px;
    margin-top: 9px;
    gap: 8px;
    span {
      font-size: 13px;
    }
  }
  ::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1px;
    width: 99%;
    background-color: #e0e0e0;
    /* border-bottom: solid 1px black; */
  }
`;

export default JobList;
