import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   locations: [],
//   name: '123',
// };

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    locations: [],
  },
  reducers: {
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
  },
});

export const { setLocations } = filterSlice.actions;

export const selectLocations = (state) => state.filter.locations;

export default filterSlice.reducer;
