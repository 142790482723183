import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti';

function ConfirmPasswordWindow(props) {
  const naviate = useNavigate();
  const handleConfirm = (onHide) => {
    onHide();
    naviate('/login');
  };
  const { innerWidth: width, innerHeight: height } = window;

  return (
    <>
      <Confetti width={width} height={height} run={props.show} numberOfPieces={600} recycle={false} />
      <Modal {...props} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>🎉 Yo-Hoo! 请验证邮箱以完成注册📮</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>请注意查收邮件, 如果没有收到邮件, 请检查垃圾箱, 或者重新发送验证邮件.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="tw-bg-yellow-500 tw-border-yellow-500 tw-text-black hover:tw-border-yellow-500 hover:tw-text-black hover:tw-bg-yellow-400"
            onClick={props.onHide}
          >
            重新发送
          </Button>
          <Button className="tw-bg-blue-800" onClick={() => handleConfirm(props.onHide)}>
            好的
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmPasswordWindow;
