import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

function Usersubtitle({ userid }) {
  // 判断url渲染不同页面
  let params = useLocation();
  const a = params.pathname.split("/");
  const urlmatch = a[a.length - 1];
  const saveTitles = ["newsave", "jobsave", "housesave", "postsave"];
  const postTitles = ["jobspost", "housespost", "formspost"];
  const likeTitles = ["newslike", "formslike"];

  // 设定选中状态钩子
  const Navigate = useNavigate();
  const handleChange = async (index, e) => {
    // 跳转页面
    Navigate(e);
  };

  return (
    <Usercontent>
      <Subtitle>
        <li onClick={() => handleChange(1, `/profile/userInfo/${userid}`)}>
          主页
          {urlmatch === userid ? (
            <motion.div className="active" layoutId="active" />
          ) : null}
        </li>
        <li onClick={() => handleChange(2, "jobspost")}>
          投稿
          {postTitles.includes(urlmatch) ? (
            <motion.div className="active" layoutId="active" />
          ) : null}
        </li>
        <li onClick={() => handleChange(3, "newslike")}>
          点赞
          {likeTitles.includes(urlmatch) ? (
            <motion.div className="active" layoutId="active" />
          ) : null}
        </li>
        <li onClick={() => handleChange(4, "newsave")}>
          收藏
          {saveTitles.includes(urlmatch) ? (
            <motion.div className="active" layoutId="active" />
          ) : null}
        </li>
      </Subtitle>
    </Usercontent>
  );
}

const Usercontent = styled.div`
  width: 100%;
  margin-left: 20px;
  position: relative;
`;

const Subtitle = styled.ul`
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  font-size: 0.9375rem;

  li {
    height: 100%;
    position: relative;
    line-height: 4.0625rem;
    margin-right: 1.875rem;
    cursor: pointer;
    :hover {
      color: #d9a40e;
    }
  }

  .active {
    position: absolute;
    bottom: -1px;
    width: 100%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: orange;
  }
`;

export default Usersubtitle;
