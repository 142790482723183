import { uploadImage } from '../api/ApiRequest';

export const convertImage = async (data, token) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    data.percent = 0;
    reader.readAsDataURL(data.raw);
    reader.onloadend = async function () {
      var base64data = reader.result;
      const response = await uploadImage(base64data.split(',')[1], token);
      console.log(response);
      resolve({ status: 'success', response: { url: response.data.url } });
    };
  });
};

export const convertImages = async (data, token) => {
  const realData = data[0];
  return new Promise((resolve) => {
    var reader = new FileReader();
    realData.percent = 0;
    reader.readAsDataURL(realData.raw);
    reader.onloadend = async function () {
      var base64data = reader.result;
      const response = await uploadImage(base64data.split(',')[1], token);
      console.log(response.data);
      resolve({ status: 'success', response: { url: response.data.url, name: response.data.status } });
    };
  });
};

// export const convertImages = async (data, token, maxWidth, maxHeight) => {
//   console.log('here1');
//   const realData = data[0];
//   console.log('here2');

//   return new Promise((resolve) => {
//     var reader = new FileReader();
//     realData.percent = 0;
//     reader.readAsDataURL(realData.raw);
//     reader.onloadend = async function () {
//       console.log('here3');
//       // Resize the image using react-image-file-resizer
//       Resizer.imageFileResizer(
//         realData.raw,
//         maxWidth, // New width
//         maxHeight, // New height
//         'JPEG', // Output format
//         100, // Quality
//         0, // Rotation
//         async (uri) => {
//           // Upload the resized image
//           console.log('here4');
//           console.log(uri);
//           const response = await uploadImage(uri.split(',')[1], token);
//           console.log(response.data);
//           resolve({ status: 'success', response: { url: response.data.url, name: response.data.status } });
//         },
//         'base64' // Output type
//       );
//     };
//   });
// };
