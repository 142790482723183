import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectSubTag, setSubTag } from "../../../../../Redux/slices/homeSlice";
import { useState, useEffect } from "react";
import NewsScroll from "../../../../../components/Home/NewsScroll";
import InfiniteScroll from "react-infinite-scroll-component"; //无限滚动

import { fetchNews } from "../../../../../api/ApiRequest";
import NewsCategory from "./NewsCategory";
import ListLoading from "../../../../../components/Loading/ListLoading";
import Footer from "../../../../../components/Home/Footer";

function convertCategory(category) {
  switch (category) {
    case 1:
      return "top";
    case 2:
      return "australia";
    case 3:
      return "international";
    case 4:
      return "domestic";
    case 5:
      return "financial";
    case 6:
      return "entertainment";
    case 7:
      return "discount";
    default:
      return "top";
  }
}

function TopNews() {
  const dispatch = useDispatch();

  // 设定选中状态钩子
  let active = useSelector(selectSubTag);
  let category = convertCategory(active);

  // 重置redux状态
  const handleChange = (index) => {
    setGetdata([]);
    setStartIndex(0);
    sethasmore(true);
    dispatch(setSubTag(index));
  };

  // 从后端获取数据
  const [getData, setGetdata] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [hasmore, sethasmore] = useState(true);

  const fetchData = async () => {
    if (!hasmore) return;
    const response = await fetchNews(category, startIndex);
    console.log(response);
    if (response.data.info === "no more news") {
      sethasmore(false);
    } else {
      setGetdata((current) => [...current, ...response.data.data]);
      setStartIndex((current) => current + response.data.data.length);
      if (response.data.data.length < 20) sethasmore(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [active]);

  return (
    <News id="newsContent">
      {/* 先设置子选项 */}
      <NewsCategory active={active} handleChange={handleChange} />
      {/* 遍历板块内容 */}
      <InfiniteScroll
        dataLength={getData.length} //This is important field to render the next
        next={fetchData}
        hasMore={hasmore}
        scrollableTarget="newsContent"
        loader={<ListLoading />}
        endMessage={
          <p className="tw-text-center tw-text-xl tw-mb-2">
            <b>🚧 到底了哟 🚧</b>
          </p>
        }
      >
        <NewsScroll data={getData} />
      </InfiniteScroll>
      <Footer />
    </News>
  );
}

const News = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

export default TopNews;
