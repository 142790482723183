import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { apiCall } from '../../api/ApiRequest';
import { useNavigate } from 'react-router-dom';
import LoadingBtn from '../../components/Home/LoadingBtn';
import ConfirmPasswordWindow from '../../components/Auth/ConfirmPasswordWindow';
import Background from '../../components/Auth/Background';
import Share from '../../img/share.svg';
import CustomizeInput from '../../components/Auth/CustomizeInput';
import { checkEmail } from '../../utils/loginChecking';
import { checkUsername, checkPassword, checkConfirmPassword } from '../../utils/registerChecking';

function RegisterPage() {
  const navigate = useNavigate();

  // Email state
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = React.useState(true);
  const [emailError, setEmailError] = React.useState('');

  // Username state
  const [userName, setUserName] = useState('');
  const [validUserName, setValidUserName] = React.useState(true);
  const [userNameError, setUserNameError] = React.useState('');

  // Password state
  const [password, setPassword] = React.useState('');
  const [validPassword, setValidPassword] = React.useState(true);
  const [passwordError, setPasswordError] = React.useState('');

  // Confirm Password state
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validConfirmPassword, setValidConfirmPassword] = useState(true);
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  // Track the request status
  const [secondRequest, setSecondRequest] = useState(false);

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  // Input checking function
  const inputChecking = () => {
    const { emailStatus, emailInfo } = checkEmail(email);
    const { userNameStatus, userNameInfo } = checkUsername(userName);
    const { passwordStatus, passwordInfo } = checkPassword(password);
    const { confirmPasswordStatus, confirmPasswordInfo } = checkConfirmPassword(password, confirmPassword);

    if (!emailStatus) {
      setValidEmail(false);
      setEmailError(emailInfo);
    } else {
      setValidEmail(true);
      setEmailError('');
    }

    if (!userNameStatus) {
      setValidUserName(false);
      setUserNameError(userNameInfo);
    } else {
      setValidUserName(true);
      setUserNameError('');
    }

    if (!passwordStatus) {
      setValidPassword(false);
      setPasswordError(passwordInfo);
    } else {
      setValidPassword(true);
      setPasswordError('');
    }

    if (!confirmPasswordStatus) {
      setValidConfirmPassword(false);
      setConfirmPasswordError(confirmPasswordInfo);
    } else {
      setValidConfirmPassword(true);
      setConfirmPasswordError('');
    }
  };

  React.useEffect(() => {
    if (secondRequest) {
      inputChecking();
    }
  }, [email, userName, password, confirmPassword]);

  // 提交数据
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { emailStatus, emailInfo } = checkEmail(email);
    const { userNameStatus, userNameInfo } = checkUsername(userName);
    const { passwordStatus, passwordInfo } = checkPassword(password);
    const { confirmPasswordStatus, confirmPasswordInfo } = checkConfirmPassword(password, confirmPassword);

    if (!emailStatus) {
      setValidEmail(false);
      setEmailError(emailInfo);
      setSecondRequest(true);
    } else {
      setValidEmail(true);
      setEmailError('');
    }

    if (!userNameStatus) {
      setValidUserName(false);
      setUserNameError(userNameInfo);
      setSecondRequest(true);
    } else {
      setValidUserName(true);
      setUserNameError('');
    }

    if (!passwordStatus) {
      setValidPassword(false);
      setPasswordError(passwordInfo);
      setSecondRequest(true);
    } else {
      setValidPassword(true);
      setPasswordError('');
    }

    if (!confirmPasswordStatus) {
      setValidConfirmPassword(false);
      setConfirmPasswordError(confirmPasswordInfo);
      setSecondRequest(true);
    } else {
      setValidConfirmPassword(true);
      setConfirmPasswordError('');
    }

    if (emailStatus && userNameStatus && passwordStatus && confirmPasswordStatus) {
      setLoading(true);
      try {
        await apiCall.post(`/auth/register`, { email, userName, password });
        setShowConfirmPopup(true);
        setLoading(false);
      } catch (error) {
        if (error.response.data.info === 'user already exists') {
          setValidEmail(false);
          setEmailError('当前邮箱已被注册');
          setLoading(false);
          setSecondRequest(true);
        } else {
          setLoading(false);
          setSecondRequest(true);
        }
        console.error(error);
        return;
      }
    }
  };

  // 主体部分
  return (
    <Background>
      <ConfirmPasswordWindow show={showConfirmPopup} onHide={() => setShowConfirmPopup(false)} />
      <Main>
        <div className="tw-flex tw-justify-center tw-flex-1 tw-h-full">
          <img src={Share} alt="" />
        </div>

        <div className="tw-flex-1 tw-h-full tw-border-l-2 tw-border-l-white tw-p-3">
          <form action="" className="tw-flex tw-flex-col">
            <h3 className="tw-text-5xl tw-mb-2 tw-text-white tw-self-start tw-mt-4 tw-font-bold">注册</h3>
            {/* 用户邮箱 */}

            <CustomizeInput
              title="邮箱地址"
              detail={{ type: 'text', placeholder: 'example@email.com' }}
              checkValid={validEmail}
              setState={setEmail}
              errorDetail={emailError}
              marginTop="tw-mt-3"
              TextColor="text-white"
            />

            {/* 用户名 */}
            <CustomizeInput
              title="用户名"
              detail={{ type: 'text', placeholder: '请输入用户名' }}
              checkValid={validUserName}
              setState={setUserName}
              errorDetail={userNameError}
              marginTop="tw-mt-3"
              TextColor="text-white"
            />

            {/* 密码   */}
            <CustomizeInput
              title="密码"
              detail={{ type: 'password', placeholder: '请输入个人密码' }}
              checkValid={validPassword}
              setState={setPassword}
              errorDetail={passwordError}
              marginTop="tw-mt-3"
              TextColor="text-white"
            />

            {/* 密码确认 */}
            <CustomizeInput
              title="确认密码"
              detail={{ type: 'password', placeholder: '请再次输入上述密码' }}
              checkValid={validConfirmPassword}
              setState={setConfirmPassword}
              errorDetail={confirmPasswordError}
              marginTop="tw-mt-3"
              TextColor="text-white"
            />

            <div
              className="tw-w-fit tw-self-end tw-mb-2 tw-text-base tw-cursor-pointer tw-text-white tw-underline"
              onClick={() => navigate('/login')}
            >
              已经拥有账号了？请登录
            </div>
            {/* 提交按钮 */}
            <LoadingBtn title={'注册'} loading={loading} onClick={handleSubmit} />
          </form>
        </div>
      </Main>
    </Background>
  );
}

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60vw;

  min-height: fit-content;
  height: 90vh;
  background-color: rgb(15, 11, 5, 0.8);
  position: relative;
  border-radius: 3rem;
`;

export default RegisterPage;
