import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import { Pagination } from "tdesign-react";
import {
  PostForumUnsave,
  fetchUserCollectForums,
} from "../../../api/ApiRequest";
import { selectToken } from "../../../Redux/slices/authSlice";
import { DeleteIcon } from "tdesign-icons-react";

import moment from "moment";
import UserForumDetail from "../UserForumDetail";
function Postsave() {
  const token = useSelector(selectToken);
  const params = useParams();
  //   获取论坛收藏的总数
  const [collectnumber] = useOutletContext();

  // 获取收藏的工作
  const [getcollectForums, setCollectForums] = useState([]);
  const [pageIndex, setpageIndex] = useState(1);
  const fetchJob = async () => {
    const response = await fetchUserCollectForums(pageIndex, params.userid);
    setCollectForums([...response.data.data]);
  };

  // 取消收藏
  const [trigger, setTrigger] = useState("");
  const handleDelete = async (event, forumid) => {
    event.preventDefault();
    await PostForumUnsave(forumid, token).then(() => {
      setTrigger(forumid);
    });
  };

  // 判断个人页面内容弹框是否显示
  const [visible, setVisible] = useState(false);

  const [targetForumId, setTargetForumId] = useState(null);

  const handleVisible = (forumId) => {
    setTargetForumId(forumId);
    setVisible(true);
  };

  const handleClose = () => {
    if (visible) {
      setVisible(false);
    }
  };

  useEffect(() => {
    fetchJob();
  }, [pageIndex, trigger]); //eslint-disable-line
  return (
    <div>
      <SaveBox>
        {getcollectForums.map((items, index) => {
          return (
            <li key={index} onClick={() => handleVisible(items.forumid)}>
              <div className="header">
                {/* banner */}
                <div className="icon">
                  <img src={items.thumbnail} />
                </div>
              </div>
              <div className="title">
                {items.title}
                <div className="time tw-flex tw-justify-between tw-mt-2">
                  <div>
                    收藏于：
                    {moment(items.collecttime * 1000).format("DD-MM-YYYY")}
                  </div>
                  <span>
                    <DeleteIcon
                      size="18px"
                      onClick={(e) => {
                        handleDelete(e, items.forumid);
                      }}
                    />
                  </span>
                </div>
              </div>
            </li>
          );
        })}
      </SaveBox>
      {collectnumber.forumscollectionnum > 12 && (
        <Pagination
          current={pageIndex}
          foldedMaxPageBtn={5}
          pageEllipsisMode="mid"
          showFirstAndLastPageBtn={false}
          showJumper
          showPageNumber
          showPageSize={false}
          showPreviousAndNextBtn
          size="medium"
          theme="default"
          total={collectnumber.forumscollectionnum}
          totalContent={false}
          onCurrentChange={(index) => {
            // console.log(index);
            setpageIndex(index);
          }}
        />
      )}

      {visible && (
        <UserForumDetail
          visible={visible}
          handleClose={handleClose}
          forumId={targetForumId}
        />
      )}
    </div>
  );
}

const SaveBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;

  li {
    height: 250px;
    width: 15rem;
    margin-right: 10px;
    border-radius: 8px;
    /* font-size: 18px; */
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 20px;
    padding: 6px;
    background: rgb(255, 255, 255);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    :nth-child(4n) {
      margin-right: 0;
      @media (max-width: 1420px) {
        margin-right: 10px;
      }
    }
    :hover {
      /* box-shadow: 0 19px 39px 0 rgb(0 0 0 / 20%); */
      cursor: pointer;
      transform: translateY(-6px);
      box-shadow: 0 30px 50px rgba(0, 0, 0, 0.3);
      transition: all 0.3s;
    }
    .icon {
      width: 100%;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .header {
      display: flex;
      margin-bottom: 10px;
      .companyname {
        width: 100%;
        text-align: center;
      }
    }
    .title {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0 0 5px;
      font-size: 16px;
    }
    .time {
      font-size: 13px;
    }
  }
`;

export default Postsave;
