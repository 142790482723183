import React from "react";
import Steps from "tdesign-react/es/steps/Steps";
import StepItem from "tdesign-react/es/steps/StepItem";

import "../Job/jobStyle.css";
function RenthomeStep({ current }) {
  return (
    <Steps
      current={current}
      layout="horizontal"
      separator="line"
      // readonly
      sequence="positive"
      theme="default"
      className="tw-w-4/6 tw-mt-4 tw-mb-2"
    >
      <StepItem icon status="default" title="出租简述" />
      <StepItem icon status="default" title="房屋介绍" />
    </Steps>
  );
}

export default RenthomeStep;
