import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import { fetchNews } from "../../../api/ApiRequest";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSubTag,
  setCurrentTag,
  setSubTag,
} from "../../../Redux/slices/homeSlice";
import ListLoading from "../../../components/Loading/ListLoading";
import NewsScroll from "../../../components/Home/NewsScroll";
import { useNavigate } from "react-router-dom";

function convertCategory(category) {
  switch (category) {
    case 1:
      return "top";
    case 2:
      return "australia";
    case 3:
      return "international";
    case 4:
      return "domestic";
    case 5:
      return "financial";
    case 6:
      return "entertainment";
    case 7:
      return "discount";
    default:
      return "top";
  }
}

function MobilehomeContain() {
  let active = useSelector(selectSubTag);
  let category = convertCategory(active);

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  // 从后端获取数据
  const [getData, setGetdata] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [hasmore, sethasmore] = useState(true);

  const fetchData = async () => {
    if (!hasmore) return;
    const response = await fetchNews(category, startIndex);
    console.log(response);
    if (response.data.info === "no more news") {
      sethasmore(false);
    } else {
      setGetdata((current) => [...current, ...response.data.data]);
      setStartIndex((current) => current + response.data.data.length);
      if (response.data.data.length < 20) sethasmore(false);
    }
  };

  // 切换不同新闻类型tab
  const handleChange = (index) => {
    setGetdata([]);
    setStartIndex(0);
    sethasmore(true);
    dispatch(setSubTag(index));
  };

  useEffect(() => {
    fetchData();
  }, [active]);
  return (
    <Bigbox>
      {/* 新闻类型tag */}
      <Splide
        options={{ perPage: 5, arrows: false, pagination: false }}
        style={{ marginTop: "5px" }}
      >
        <SplideSlide className="active" onClick={() => handleChange(1)}>
          头条
        </SplideSlide>
        <SplideSlide onClick={() => handleChange(2)}>澳洲</SplideSlide>
        <SplideSlide onClick={() => handleChange(3)}>国际</SplideSlide>
        <SplideSlide onClick={() => handleChange(4)}>国内</SplideSlide>
        <SplideSlide onClick={() => handleChange(5)}>财经</SplideSlide>
        <SplideSlide onClick={() => handleChange(6)}>娱乐</SplideSlide>
        <SplideSlide onClick={() => handleChange(7)}>折扣</SplideSlide>
      </Splide>
      {/* 无限滚动 */}
      <InfiniteScroll
        dataLength={getData.length} //This is important field to render the next
        next={fetchData}
        hasMore={hasmore}
        scrollableTarget="newsContent"
        loader={<ListLoading />}
        endMessage={
          <p className="tw-text-center tw-text-xl tw-mb-2">
            <b>🚧 到底了哟 🚧</b>
          </p>
        }
        height="85vh"
      >
        <NewsScroll data={getData} />
      </InfiniteScroll>
    </Bigbox>
  );
}
const Bigbox = styled.div`
  li {
    font-size: 0.9rem;
    text-align: center;
  }
  .active {
    background-color: pink;
  }
`;
export default MobilehomeContain;
