import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import { Pagination } from "tdesign-react";
import { DeleteIcon } from "tdesign-icons-react";
import moment from "moment";
import {
  PostForumUnlike,
  PostNewsUnLike,
  fetchUserLikeForums,
  fetchUserLikeNews,
} from "../../api/ApiRequest";
import { selectToken, selectUserId } from "../../Redux/slices/authSlice";
import UserForumDetail from "./UserForumDetail";
function UserLike() {
  const myId = useSelector(selectUserId);
  const token = useSelector(selectToken);

  let params = useParams();
  const userId = params.userid;

  // 判断url渲染不同页面
  let urllocation = useLocation();
  const spliteurl = urllocation.pathname.split("/");
  const urlmatch = spliteurl[spliteurl.length - 1];

  //   获取点赞的总数
  const [collectnumber] = useOutletContext();

  // 获取数据
  const [getlikeNews, setLikeNews] = useState([]);
  const [getlikeForums, setGetLikeForums] = useState([]);

  const [pageIndex, setpageIndex] = useState(1);
  const fetchData = async () => {
    if (urlmatch === "newslike") {
      const response = await fetchUserLikeNews(pageIndex, params.userid);
      setLikeNews([...response.data.data]);
    } else if (urlmatch === "formslike") {
      const response = await fetchUserLikeForums(pageIndex, params.userid);
      setGetLikeForums([...response.data.data]);
    }
  };

  // 删除投稿
  const [trigger, setTrigger] = useState("");
  const handleDelete = async (event, Id) => {
    event.preventDefault();

    if (urlmatch === "newslike") {
      await PostNewsUnLike(Id, token).then(() => {
        setTrigger(Id);
      });
    } else if (urlmatch === "formslike") {
      await PostForumUnlike(Id, token).then(() => {
        setTrigger(Id);
      });
    }
  };

  // 判断个人页面内容弹框是否显示
  const [visible, setVisible] = useState(false);

  const [targetForumId, setTargetForumId] = useState(null);

  const handleVisible = (forumId) => {
    setTargetForumId(forumId);
    setVisible(true);
  };

  const handleClose = () => {
    if (visible) {
      setVisible(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageIndex, trigger, urlmatch]); //eslint-disable-line
  return (
    <div>
      <SaveBox>
        {urlmatch === "newslike" &&
          getlikeNews.map((items, index) => {
            return (
              <Link
                target="_blank"
                to={"/news/detail/" + items.newsid}
                key={items.newsid}
              >
                <li key={index}>
                  <div className="header">
                    {/* banner */}
                    <div className="icon">
                      <img src={items.thumbnail} />
                    </div>
                  </div>
                  <div className="title">
                    <span>{items.title}</span>
                  </div>
                  <div className="time tw-flex tw-justify-between tw-mt-2">
                    <div>
                      点赞于：
                      {moment(items.liketime * 1000).format("DD-MM-YYYY")}
                    </div>
                    {params.userid === myId && (
                      <span>
                        <DeleteIcon
                          size="18px"
                          onClick={(e) => {
                            handleDelete(e, items.newsid);
                          }}
                        />
                      </span>
                    )}
                  </div>
                </li>
              </Link>
            );
          })}

        {urlmatch === "formslike" &&
          getlikeForums.map((items, index) => {
            return (
              <li key={index} onClick={() => handleVisible(items.forumid)}>
                <div className="header">
                  {/* banner */}
                  <div className="thumbnail">
                    <img src={items.thumbnail} />
                  </div>
                </div>
                <div className="title">
                  {items.title}
                  <div className="time tw-flex tw-justify-between tw-mt-2">
                    <div>
                      点赞于：
                      {moment(items.liketime * 1000).format("DD-MM-YYYY")}
                    </div>
                    {params.userid === myId && (
                      <span>
                        <DeleteIcon
                          size="18px"
                          onClick={(e) => {
                            handleDelete(e, items.forumid);
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
      </SaveBox>
      {/* 分页查询 */}
      {urlmatch === "newslike" && collectnumber.newslikednum > 12 && (
        <Pagination
          current={pageIndex}
          foldedMaxPageBtn={5}
          pageEllipsisMode="mid"
          showFirstAndLastPageBtn={false}
          showJumper
          showPageNumber
          showPageSize={false}
          showPreviousAndNextBtn
          size="medium"
          theme="default"
          total={collectnumber.jobspostnum}
          totalContent={false}
          onCurrentChange={(index) => {
            setpageIndex(index);
          }}
        />
      )}
      {urlmatch === "formslike" && collectnumber.forumlikednum > 12 && (
        <Pagination
          current={pageIndex}
          foldedMaxPageBtn={5}
          pageEllipsisMode="mid"
          showFirstAndLastPageBtn={false}
          showJumper
          showPageNumber
          showPageSize={false}
          showPreviousAndNextBtn
          size="medium"
          theme="default"
          total={collectnumber.jobspostnum}
          totalContent={false}
          onCurrentChange={(index) => {
            setpageIndex(index);
          }}
        />
      )}

      {visible && (
        <UserForumDetail
          visible={visible}
          handleClose={handleClose}
          forumId={targetForumId}
          userId={userId}
        />
      )}
    </div>
  );
}

const SaveBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  li {
    height: 250px;
    width: 15rem;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
    color: #212121;
    margin-bottom: 20px;
    padding: 10px;
    background: rgb(255, 255, 255);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .thumbnail {
      height: 120px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    :hover {
      /* box-shadow: 0 19px 39px 0 rgb(0 0 0 / 20%); */
      cursor: pointer;
      transform: translateY(-6px);
      box-shadow: 0 30px 50px rgba(0, 0, 0, 0.3);
      transition: all 0.3s;
    }
    .header {
      display: flex;
      margin-bottom: 10px;
      .companyname {
        width: 100%;
        text-align: center;
        h4 {
          font-size: 18px;
          font-weight: 500;
          line-height: 50px;
        }
      }
    }
    .title {
      height: 100px;
      display: flex;
      font-size: 16px;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0 0 5px;
    }
    .time {
      font-size: 13px;
      padding-left: 2px;
    }
    :nth-child(4) {
      margin-right: 0px;
      @media (max-width: 1420px) {
        margin-right: 10px;
      }
    }
  }
`;

export default UserLike;
