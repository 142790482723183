// Import the required dependencies.
import React from 'react';

function CustomizeInput({ id, title, detail, checkValid, setState, errorDetail, marginTop, TextColor }) {
  return (
    <div className={`${marginTop} tw-mb-2`}>
      {/* Input title */}
      <label className={`tw-block tw-${TextColor} tw-text-base tw-font-bold tw-mb-2`} htmlFor={title}>
        {title}
      </label>
      {/* User input section */}
      <input
        className={`tw-shadow tw-appearance-none tw-text-lg tw-rounded tw-w-full tw-h-8 tw-py-2 tw-px-3 tw-text-black tw-leading-tight tw-focus:outline-none tw-focus:shadow-outline ${
          checkValid ? 'tw-border' : 'tw-border-4 tw-border-rose-500'
        }`}
        id={id}
        type={detail.type}
        autoComplete="on"
        placeholder={detail.placeholder}
        onChange={(event) => {
          setState(event.target.value);
        }}
      />
      {/* Error info section */}
      <p
        id="emailError"
        className={`tw-peer-invalid:visible tw-text-base tw-pl-2 tw-font-semibold tw-text-red-700 ${
          checkValid ? 'tw-hidden' : ''
        }`}
      >
        {errorDetail}
      </p>
    </div>
  );
}

export default CustomizeInput;
