import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { selectToken, selectUserId } from "../../../Redux/slices/authSlice";
import { useSelector } from "react-redux";
import { joinChatRoom, sendChatMessage } from "../../../api/ApiRequest";
import getCursor from "../../../utils/getCursor";
import { useParams } from "react-router-dom";
import { getSocket } from "../../../utils/socket";
import { Avatar } from "tdesign-react";
import { getDateDiff } from "../../../utils/getDateDiff";

function ChatRoom({ socketConnection }) {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const token = useSelector(selectToken);
  const userid = useSelector(selectUserId);
  const { groupid } = useParams();
  const cursor = getCursor();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (inputValue.trim() !== "") {
      sendChatMessage(groupid, inputValue, token);
      setInputValue("");
    }
  };

  // useEffect(() => {
  //   console.log('123');
  //   if (socketConnection !== null) {
  //     const socket = getSocket();
  //     console.log('ChatRoom render');
  //     console.log(socket);

  //     if (socket.id !== undefined) {
  //       joinChatRoom(groupid, socket.id, token)
  //         .then((res) => {
  //           console.log('joinChatRoom', res);
  //           socket.emit('history', { cursor });
  //         })
  //         .catch((err) => {
  //           console.log('joinChatRoom', err);
  //         });
  //     }
  //     socket.on('chatHistory', (messages) => {
  //       console.log('chatHistory', messages);
  //       setMessages(messages.messages);
  //     });

  //     socket.on('chatMessage', (message) => {
  //       console.log('receiveChatMessage', message);
  //       console.log('messages', messages);
  //       setMessages((current) => [...current, message]);
  //       console.log('here');
  //     });

  //     return () => {
  //       socket.off('chatHistory');
  //       socket.off('chatMessage');
  //     };
  //   }
  // }, [socketConnection]);

  return (
    <Chat>
      <Messages>
        {messages.map((message) => (
          <Message key={message.messageid}>
            {message.userid === userid ? (
              <div className="tw-flex tw-flex-col tw-items-end">
                <div className="tw-flex tw-justify-end tw-gap-2 tw-items-center">
                  <div className=" tw-text-xs tw-text-gray-500 ">
                    {getDateDiff(message.timestamp)}
                  </div>
                  {message.avatar === "" ? (
                    <Avatar size="medium">
                      {message.username.slice(0, 3)}
                    </Avatar>
                  ) : (
                    <Avatar size="medium" image={message.avatar} />
                  )}
                </div>
                <div
                  className="tw-max-w-sm tw-rounded-xl tw-p-2 tw-mt-1 tw-w-fit"
                  style={{ backgroundColor: "#E9832F" }}
                >
                  <div className="tw-text-sm tw-text-gray-800">
                    {message.content}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="tw-flex tw-gap-2 tw-items-center">
                  {message.avatar === "" ? (
                    <Avatar size="medium">
                      {message.username.slice(0, 3)}
                    </Avatar>
                  ) : (
                    <Avatar size="medium" image={message.avatar} />
                  )}
                  <div className=" tw-text-sm tw-text-gray-600 tw-font-medium ">
                    {message.username}
                  </div>
                  <div className=" tw-text-xs tw-text-gray-500 ">
                    {getDateDiff(message.timestamp)}
                  </div>
                </div>

                <div className="tw-bg-white tw-max-w-sm tw-rounded-xl tw-p-2 tw-mt-1 tw-w-fit ">
                  <div className="tw-text-sm tw-text-gray-900">
                    {message.content}
                  </div>
                </div>
              </>
            )}
          </Message>
        ))}
      </Messages>
      {/* <button onClick={() => console.log(messages)}>click</button> */}
      <Form onSubmit={handleFormSubmit}>
        <TextInput
          className=" tw-rounded-xl tw-p-2 tw-border tw-border-gray-300 "
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="输入你的消息"
        />
        <SubmitButton className="tw-rounded-xl  " type="submit">
          发送
        </SubmitButton>
      </Form>
    </Chat>
  );
}

const Chat = styled.div`
  display: flex;
  flex-direction: column;
  height: 92vh;
  width: 100%;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 10px;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const Message = styled.div`
  background-color: #f2f3f5;
  padding: 10px;
  margin-bottom: 10px;
`;

const Form = styled.form`
  display: flex;
  margin: 0px 15px 20px 15px;
  gap: 10px;
`;

const TextInput = styled.input`
  flex: 1;
  padding: 10px;
  :focus {
    outline: none;
  }
`;

const SubmitButton = styled.button`
  background-color: #e9832f;
  color: #fff;
  border: none;
  padding: 15px;
  cursor: pointer;
`;
export default ChatRoom;
