import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useOutletContext,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import { Avatar, Pagination, Tag } from "tdesign-react";
import { DeleteIcon } from "tdesign-icons-react";
import moment from "moment";
import {
  DelUserPostForums,
  DelUserPostHouse,
  DelUserPostJob,
  fetchUserPostForums,
  fetchUserPostHouse,
  fetchUserPostJob,
} from "../../api/ApiRequest";
import { selectToken, selectUserId } from "../../Redux/slices/authSlice";
import UserForumDetail from "./UserForumDetail";
import UserJobDetail from "./UserJobDetail";
import UserHouseDetail from "./UserHouseDetail";

function UserPost() {
  const myId = useSelector(selectUserId);
  const token = useSelector(selectToken);

  let params = useParams();
  const userId = params.userid;

  // 判断url渲染不同页面
  let urllocation = useLocation();
  const spliteurl = urllocation.pathname.split("/");
  const urlmatch = spliteurl[spliteurl.length - 1];

  //   获取新闻收藏的总数
  const [collectnumber, newsdata] = useOutletContext();
  let defaulticon = newsdata.username?.slice(0, 1);

  // 获取数据
  const [getpostJobs, setGetPostJobs] = useState([]);
  const [getpostHouses, setGetPostHouses] = useState([]);
  const [getpostForums, setGetPostForums] = useState([]);

  const [pageIndex, setpageIndex] = useState(1);
  const fetchData = async () => {
    if (urlmatch === "jobspost") {
      const response = await fetchUserPostJob(pageIndex, params.userid);
      setGetPostJobs([...response.data.data]);
    } else if (urlmatch === "housespost") {
      const response = await fetchUserPostHouse(pageIndex, params.userid);
      setGetPostHouses([...response.data.data]);
    } else if (urlmatch === "formspost") {
      const response = await fetchUserPostForums(pageIndex, params.userid);
      setGetPostForums([...response.data.data]);
    }
  };

  // 删除投稿
  const [trigger, setTrigger] = useState("");
  const handleDelete = async (event, Id) => {
    event.preventDefault();

    if (urlmatch === "jobspost") {
      await DelUserPostJob(Id, token).then(() => {
        setTrigger(Id);
      });
    } else if (urlmatch === "housespost") {
      await DelUserPostHouse(Id, token).then(() => {
        setTrigger(Id);
      });
    } else if (urlmatch === "formspost") {
      await DelUserPostForums(Id, token).then(() => {
        setTrigger(Id);
      });
    }
  };

  // 判断个人页面内容弹框是否显示
  const [visible, setVisible] = useState(false);

  const [targetForumId, setTargetForumId] = useState(null);

  const handleVisible = (forumId) => {
    setTargetForumId(forumId);
    setVisible(true);
  };

  const handleClose = () => {
    if (visible) {
      setVisible(false);
    }
  };

  // 判断个人页面工作招聘弹框是否显示
  const [jobvisible, setJobVisible] = useState(false);

  const handleJobVisible = (Id) => {
    setTargetForumId(Id);
    setJobVisible(true);
  };

  const handleJobClose = () => {
    if (jobvisible) {
      setJobVisible(false);
    }
  };

  // 判断个人页面租房弹框是否显示
  const [rentvisible, setRentVisible] = useState(false);

  const handleRentVisible = (Id) => {
    setTargetForumId(Id);
    setRentVisible(true);
  };

  const handleRentClose = () => {
    if (rentvisible) {
      setRentVisible(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageIndex, trigger, urlmatch]); //eslint-disable-line

  return (
    <div>
      <SaveBox>
        {urlmatch === "jobspost" &&
          getpostJobs.map((items, index) => {
            return (
              <li key={index} onClick={() => handleJobVisible(items.jobid)}>
                <div className="header">
                  {/* 头像 */}
                  <div className="icon">
                    <Avatar shape="round" size="50px" image={newsdata.avatar}>
                      {newsdata.avatar === "" ? defaulticon : newsdata.avatar}
                    </Avatar>
                  </div>
                  <div className="companyname">
                    {/* 公司名称 */}
                    <h4>{items.companytitle}</h4>
                  </div>
                </div>
                <div className="title">{items.title}</div>
                {/* 标签 */}
                <div className="tw-mt-5">
                  <Tag style={{ marginRight: "5px" }}>{items.locationname}</Tag>
                  <Tag style={{ marginRight: "5px" }}>
                    {items.jobcategoryname}
                  </Tag>
                  <Tag>经验:{items.experience}</Tag>

                  <div className="time tw-flex tw-justify-between tw-mt-2">
                    <div>
                      发布于：
                      {moment(items.createdtime * 1000).format("DD-MM-YYYY")}
                    </div>
                    {params.userid === myId && (
                      <span>
                        <DeleteIcon
                          size="18px"
                          onClick={(e) => {
                            handleDelete(e, items.jobid);
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        {urlmatch === "housespost" &&
          getpostHouses.map((items, index) => {
            return (
              <li key={index} onClick={() => handleRentVisible(items.houseid)}>
                <div className="header">
                  {/* 头像 */}
                  <div className="thumbnail">
                    <img src={items.thumbnail} />
                  </div>
                </div>
                <div className="title">{items.title}</div>
                {/* 标签 */}
                <div className="tw-mt-5">
                  <Tag style={{ marginRight: "5px" }}>{items.locationname}</Tag>
                  <Tag style={{ marginRight: "5px" }}>{items.name}</Tag>
                  <Tag>{items.housefrom}</Tag>

                  <div className="time tw-flex tw-justify-between tw-mt-2">
                    <div>
                      发布于：
                      {moment(items.createdtime * 1000).format("DD-MM-YYYY")}
                    </div>
                    {params.userid === myId && (
                      <span>
                        <DeleteIcon
                          size="18px"
                          onClick={(e) => {
                            handleDelete(e, items.houseid);
                          }}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
        {urlmatch === "formspost" &&
          getpostForums.map((items, index) => {
            return (
              <li key={index} onClick={() => handleVisible(items.forumid)}>
                <div className="header">
                  {/* banner */}
                  <div className="thumbnail">
                    <img src={items.thumbnail} />
                  </div>
                </div>
                <div className="title">{items.title}</div>
                <div className="time tw-flex tw-justify-between tw-mt-2">
                  <div>
                    发布于：
                    {moment(items.createdtime * 1000).format("DD-MM-YYYY")}
                  </div>
                  {params.userid === myId && (
                    <span>
                      <DeleteIcon
                        size="18px"
                        onClick={(e) => {
                          handleDelete(e, items.forumid);
                        }}
                      />
                    </span>
                  )}
                </div>
              </li>
            );
          })}
      </SaveBox>
      {/* 分页查询按钮 */}
      {urlmatch === "jobspost" && collectnumber.jobspostnum > 12 && (
        <Pagination
          current={pageIndex}
          foldedMaxPageBtn={5}
          pageEllipsisMode="mid"
          showFirstAndLastPageBtn={false}
          showJumper
          showPageNumber
          showPageSize={false}
          showPreviousAndNextBtn
          size="medium"
          theme="default"
          total={collectnumber.jobspostnum}
          totalContent={false}
          onCurrentChange={(index) => {
            setpageIndex(index);
          }}
        />
      )}
      {urlmatch === "housespost" && collectnumber.housespostnum > 12 && (
        <Pagination
          current={pageIndex}
          foldedMaxPageBtn={5}
          pageEllipsisMode="mid"
          showFirstAndLastPageBtn={false}
          showJumper
          showPageNumber
          showPageSize={false}
          showPreviousAndNextBtn
          size="medium"
          theme="default"
          total={collectnumber.housespostnum}
          totalContent={false}
          onCurrentChange={(index) => {
            setpageIndex(index);
          }}
        />
      )}
      {urlmatch === "formspost" && collectnumber.forumspostnum > 12 && (
        <Pagination
          current={pageIndex}
          foldedMaxPageBtn={5}
          pageEllipsisMode="mid"
          showFirstAndLastPageBtn={false}
          showJumper
          showPageNumber
          showPageSize={false}
          showPreviousAndNextBtn
          size="medium"
          theme="default"
          total={collectnumber.forumspostnum}
          totalContent={false}
          onCurrentChange={(index) => {
            setpageIndex(index);
          }}
        />
      )}
      {visible && (
        <UserForumDetail
          visible={visible}
          handleClose={handleClose}
          forumId={targetForumId}
          userId={userId}
        />
      )}
      {jobvisible && (
        <UserJobDetail
          visible={jobvisible}
          handleClose={handleJobClose}
          Id={targetForumId}
        />
      )}
      {rentvisible && (
        <UserHouseDetail
          visible={rentvisible}
          handleClose={handleRentClose}
          Id={targetForumId}
        />
      )}
    </div>
  );
}

const SaveBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  li {
    height: 250px;
    width: 15rem;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
    color: #212121;
    margin-bottom: 20px;
    padding: 10px;
    background: rgb(255, 255, 255);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .thumbnail {
      height: 120px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    :hover {
      /* box-shadow: 0 19px 39px 0 rgb(0 0 0 / 20%); */
      cursor: pointer;
      transform: translateY(-6px);
      box-shadow: 0 30px 50px rgba(0, 0, 0, 0.3);
      transition: all 0.3s;
    }
    .header {
      display: flex;
      margin-bottom: 10px;
      .companyname {
        width: 100%;
        text-align: center;
        h4 {
          font-size: 18px;
          font-weight: 500;
          line-height: 50px;
        }
      }
    }
    .title {
      height: 100px;
      display: flex;
      font-size: 16px;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0 0 5px;
    }
    .time {
      font-size: 13px;
      padding-left: 2px;
    }
    :nth-child(4n) {
      margin-right: 0px;
      @media (max-width: 1420px) {
        margin-right: 10px;
      }
    }
  }
`;
export default UserPost;
