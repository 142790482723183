import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import { Pagination } from "tdesign-react";
import { DeleteIcon } from "tdesign-icons-react";
import { PostNewsUnSave, fetchUserCollectNews } from "../../../api/ApiRequest";
import { selectToken, selectUserId } from "../../../Redux/slices/authSlice";
import moment from "moment";

function Newssave() {
  const myId = useSelector(selectUserId);
  const token = useSelector(selectToken);
  let params = useParams();

  //   获取新闻收藏的总数
  const [collectnumber] = useOutletContext();

  // 获取收藏的新闻
  const [getcollectNews, setCollectNews] = useState([]);
  const [pageIndex, setpageIndex] = useState(1);
  const fetchNews = async () => {
    const response = await fetchUserCollectNews(pageIndex, params.userid);
    setCollectNews([...response.data.data]);
  };

  // 取消收藏
  const [trigger, setTrigger] = useState("");
  const handleDelete = async (event, newsId) => {
    event.preventDefault();
    await PostNewsUnSave(newsId, token).then(() => {
      setTrigger(newsId);
    });
  };

  useEffect(() => {
    fetchNews();
  }, [pageIndex, trigger]); //eslint-disable-line
  return (
    <div>
      <SaveBox>
        {getcollectNews.map((items, index) => {
          return (
            <Link
              target="_blank"
              to={"/news/detail/" + items.newsid}
              key={items.newsid}
              title={items.title}
            >
              <li key={index}>
                <div className="header">
                  {/* banner */}
                  <div className="icon">
                    <img src={items.thumbnail} />
                  </div>
                </div>
                <div className="title">
                  <span>{items.title}</span>
                </div>
                <div className="time tw-flex tw-justify-between tw-mt-2">
                  收藏于：
                  {moment(items.collecttime * 1000).format("DD-MM-YYYY")}
                  {params.userid === myId && (
                    <span>
                      <DeleteIcon
                        size="18px"
                        onClick={(e) => {
                          handleDelete(e, items.newsid);
                        }}
                      />
                    </span>
                  )}
                </div>
              </li>
            </Link>
          );
        })}
      </SaveBox>
      {collectnumber.newscollectionnum > 12 && (
        <Pagination
          current={pageIndex}
          foldedMaxPageBtn={5}
          pageEllipsisMode="mid"
          showFirstAndLastPageBtn={false}
          showJumper
          showPageNumber
          showPageSize={false}
          showPreviousAndNextBtn
          size="medium"
          theme="default"
          total={collectnumber.newscollectionnum}
          totalContent={false}
          onCurrentChange={(index) => {
            setpageIndex(index);
          }}
        />
      )}
    </div>
  );
}

const SaveBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;

  li {
    width: 15rem;
    height: 250px;
    margin-right: 10px;
    border-radius: 8px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 20px;
    padding: 6px;
    background: rgb(255, 255, 255);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    :nth-child(4n) {
      margin-right: 0;
      @media (max-width: 1420px) {
        margin-right: 10px;
      }
    }
    :hover {
      cursor: pointer;
      transform: translateY(-6px);
      box-shadow: 0 30px 50px rgba(0, 0, 0, 0.3);
      transition: all 0.3s;
    }
    .icon {
      width: 100%;
      height: 120px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .header {
      display: flex;
      margin-bottom: 10px;
      .companyname {
        width: 100%;
        text-align: center;
      }
    }
    .title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 60px;
      padding: 10px 0 0 5px;
      font-size: 16px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
    }
    .time {
      font-size: 13px;
      padding-left: 5px;
    }
  }
`;
export default Newssave;
