import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLogin: false,
  token: '',
  avatar: '',
  userName: '',
  signature:'',
  dob:'',
  location:'',
  userId:'',
  followers:'',
  following:''
};

export const navSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setSignature: (state, action) => {
      state.signature = action.payload;
    },    
    setDob: (state, action) => {
      state.dob = action.payload;
    },    
    setLocation: (state, action) => {
      state.location = action.payload;
    }, 
    setUserId: (state, action) => {
      state.userId = action.payload;
    }, 
    setFollowers: (state, action) => {
      state.followers = action.payload;
    }, 
    setFollowing: (state, action) => {
      state.following = action.payload;
    }, 
  },
});

export const { setIsLogin, setToken, setAvatar, setUserName,setSignature,setDob,setLocation,setFollowers,setFollowing,setUserId } = navSlice.actions;

export const selectToken = (state) => state.auth.token;
export const selectDob = (state) => state.auth.dob;
export const selectLocation = (state) => state.auth.location;
export const selectSignature = (state) => state.auth.signature;
export const selectAvatar = (state) => state.auth.avatar;
export const selectUserName = (state) => state.auth.userName;
export const selectIsLogin = (state) => state.auth.isLogin;
export const selectUserId = (state) => state.auth.userId;
export const selectFollowers = (state) => state.auth.followers;
export const selectFollowing = (state) => state.auth.following;

export default navSlice.reducer;
