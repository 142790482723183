import React from 'react';
import FormItem from 'tdesign-react/es/form/FormItem';
import Input from 'tdesign-react/es/input/Input';
import Textarea from 'tdesign-react/es/textarea/Textarea';
import { InputAdornment, Space } from 'tdesign-react';
import { BiPlus } from 'react-icons/bi';
import Button from 'tdesign-react/es/button/Button';

import { List } from 'tdesign-react';
const { ListItem } = List;

function Step3({ step3Data }) {
  const [currentAdvantage, setCurrentAdvantage] = React.useState('');
  const companyAdvantage = step3Data.companyAdvantage;
  console.log(step3Data);

  return (
    <>
      <FormItem
        className="tw-w-5/6 tw-font-bold"
        style={{ width: '50vw' }}
        label="公司名称"
        name="companytitle"
        shouldUpdate={false}
        successBorder={false}
        initialData={step3Data.companyName}
        rules={[{ required: true, message: '请填写公司名称', type: 'error' }]}
      >
        <Input
          onChange={(e) => step3Data.setCompanyName(e)}
          align="left"
          autofocus={true}
          maxlength={100}
          placeholder="请输入公司名称"
          size="medium"
          type="text"
        />
      </FormItem>

      <FormItem
        initialData={step3Data.companyDescription}
        requiredMark={false}
        className="tw-w-5/6 tw-font-bold"
        style={{ width: '50vw' }}
        label="公司介绍"
        name="companyDescription"
        shouldUpdate={false}
        successBorder={false}
      >
        <Textarea
          onChange={(e) => step3Data.setCompanyDescription(e)}
          autosize={{ minRows: 4, maxRows: 4 }}
          placeholder="请简单地介绍公司的背景和业务范围, 有利于吸引求职者的关注哦"
          maxlength={1500}
        />
      </FormItem>

      <FormItem
        requiredMark={false}
        className="tw-w-5/6 tw-font-bold tw-flex tw-flex-col"
        style={{ width: '50vw' }}
        label="公司优势"
        name="jobAdvantage"
        shouldUpdate={false}
        successBorder={false}
      >
        <div className="tw-border-2 tw-rounded-lg" style={{ height: '114px', width: '100%', overflow: 'auto' }}>
          <List size="small" split={true} stripe={true}>
            {companyAdvantage?.length === 0 && (
              <ListItem>
                <span style={{ color: '#A8A8A8' }}>请在下方输入公司优势, 一行一个</span>
              </ListItem>
            )}
            {companyAdvantage?.map((item) => (
              <ListItem
                key={item.id}
                action={
                  <>
                    <li>
                      <Button
                        shape="rectangle"
                        theme="danger"
                        size="small"
                        type="button"
                        variant="base"
                        onClick={() => {
                          const target = companyAdvantage.filter((i) => i.id !== item.id);
                          step3Data.setCompanyAdvantage(target);
                        }}
                      >
                        移除
                      </Button>
                    </li>
                  </>
                }
              >
                {item.info}
              </ListItem>
            ))}
          </List>
        </div>
      </FormItem>

      <Space direction="vertical" style={{ width: '100%' }}>
        <InputAdornment
          append={
            <Button
              shape="rectangle"
              size="medium"
              type="button"
              variant="base"
              onClick={() => {
                const target = [...companyAdvantage, { id: companyAdvantage.length, info: currentAdvantage }];
                step3Data.setCompanyAdvantage(target);
                setCurrentAdvantage('');
              }}
            >
              <BiPlus size={25} />
            </Button>
          }
        >
          <Input onChange={(e) => setCurrentAdvantage(e)} value={currentAdvantage} />
        </InputAdornment>
      </Space>
    </>
  );
}

export default Step3;
