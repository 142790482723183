import React from "react";
import styled from "styled-components";
import { Button } from "tdesign-react";
import Logo from "../../../img/logo.png";

function Mobileheader() {
  return (
    <Header>
      <div className="app_banner">
        <div className="left">
          <img className="tw-inline-block" height={30} width={30} src={Logo} />
          <span>贴贴</span>
        </div>
        <div className="right">
          <Button shape="round" theme="warning">
            打开App
          </Button>
        </div>
      </div>
    </Header>
  );
}
const Header = styled.div`
  height: 3.75rem;
  line-height: 3.75rem;
  box-shadow: 0 0.04rem 0.08rem 0 rgb(0 0 0 / 8%);
  .app_banner {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    padding: 0 0.9375rem;
    .left > span {
      margin-left: 0.5rem;
    }
  }
`;
export default Mobileheader;
