import React from "react";
import styled from "styled-components";
import { Button, Loading, Skeleton } from "tdesign-react";
import { useState, useEffect } from "react";
import {
  fetchHomesdetail,
  PostHousesSave,
  PostHousesUnSave,
  fetchHouseSave,
} from "../../api/ApiRequest";
import { useSelector } from "react-redux";
import { selectToken } from "../../Redux/slices/authSlice";
import { selecthouseId } from "../../Redux/slices/renthouseSlice";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";

import { PhotoProvider, PhotoView } from "react-photo-view";
import { getDateDiff } from "../../utils/getDateDiff";
import { RiWalletLine } from "react-icons/ri";
import { BsHouseDoor } from "react-icons/bs";
import { AiOutlineCalendar, AiOutlineFieldTime } from "react-icons/ai";
import { FaRegShareSquare } from "react-icons/fa";
import { MdOutlineBedroomParent } from "react-icons/md";
import searchingSVG from "../../img/search.svg";

function RenthomeDetail() {
  // 从后端获取数据
  const houseId = useSelector(selecthouseId);
  const token = useSelector(selectToken);
  const [getData, setGetdata] = useState({});
  // 房子图片
  const [getimages, setGetimages] = useState([]);
  const [showCollectBtn, setShowCollectBtn] = useState(true);

  const [showLoading, setShowLoading] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);

  const fetchData = async () => {
    setShowLoading(true);
    setShowNotFound(false);
    if (houseId === "") {
      setShowNotFound(true);
    } else {
      const response = await fetchHomesdetail(houseId);
      setGetdata(response.data.data);
      setGetimages(response.data.data.images.split(","));
    }
    setShowLoading(false);
  };

  const collectStatus = async () => {
    if (token) {
      const response = await fetchHouseSave(houseId, token);
      response.data.data.collectstatus === true
        ? setShowCollectBtn(false)
        : setShowCollectBtn(true);
    }
  };

  React.useLayoutEffect(() => {
    collectStatus();
  }, [houseId]);

  useEffect(() => {
    fetchData();
  }, [houseId]); //eslint-disable-line

  if (showNotFound) {
    return (
      <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-h-full">
        <img src={searchingSVG} alt="searching" className="tw-w-1/3 tw-h-1/3" />
        <div className="tw-text-base tw-font-bold ">没有找到相关信息</div>
      </div>
    );
  }

  return (
    <JobDet>
      <Loading
        loading={showLoading}
        size="large"
        showOverlay
        style={{ color: "#F8924B" }}
      >
        <Skeleton
          animation={"gradient"}
          loading={showLoading}
          theme={"article"}
          style={{ marginTop: 20 }}
        >
          <div className="job_header ">
            {/* 房屋大标题 */}
            <div className="jobtitle tw-text-gray-900">{getData.title}</div>
            {/* 工作副标题 */}
            <div className="jobsubtitle">
              <span>{getData.locationname} </span>
              <span>{getDateDiff(getData.createdtime)}</span>
            </div>
            {/* 出租方式 */}
            <div className=" tw-flex tw-items-center tw-text-sm">
              <MdOutlineBedroomParent
                size={18}
                style={{ marginRight: "8px" }}
              />
              出租方式：{getData.method}
            </div>
            {/* 每周租金 */}
            <div className=" tw-flex tw-items-center tw-text-sm">
              <RiWalletLine size={18} style={{ marginRight: "8px" }} />
              每周租金：
              {getData.price === 0 ? "面议" : `$${getData.price} / 每周`}
            </div>
            {/* 房子类型 */}
            <div className=" tw-flex tw-items-center tw-text-sm">
              <BsHouseDoor size={18} style={{ marginRight: "8px" }} />
              房子类型：{getData.housecategory}
            </div>
            {/* 入住时间 */}

            <div className=" tw-flex tw-items-center tw-text-sm">
              <AiOutlineFieldTime size={18} style={{ marginRight: "8px" }} />
              入住时间：{getData.checkintime}
            </div>
            {/* 最短租期 */}
            <div className=" tw-flex tw-items-center tw-text-sm">
              <AiOutlineCalendar size={18} style={{ marginRight: "8px" }} />
              最短租期：{getData.minimumperiod}
            </div>
            {/* 联系房东按钮 */}
            <Button theme="warning" shape="round">
              联系房东
              <FaRegShareSquare />
            </Button>
            {/* 收藏按钮 */}
            {showCollectBtn ? (
              <Button
                variant="outline"
                theme="warning"
                className="save_job"
                shape="round"
                onClick={() => {
                  if (token) {
                    PostHousesSave(houseId, token);
                    setShowCollectBtn(false);
                  }
                }}
              >
                收藏房屋
              </Button>
            ) : (
              <Button
                variant="outline"
                theme="warning"
                className="save_job"
                shape="round"
                onClick={() => {
                  if (token) {
                    PostHousesUnSave(houseId, token);
                    setShowCollectBtn(true);
                  }
                }}
              >
                取消收藏
              </Button>
            )}
          </div>
          {/* 房子详细内容 */}
          <div className="job_content">
            {/* 房子描述 */}
            <div className="house_des tw-whitespace-pre-wrap">
              <span className="tw-text-xl tw-text-gray-900">房子描述</span>
              <p>{getData.detail}</p>
            </div>
          </div>
          <div className=" imgswiper tw-flex tw-flex-col tw-justify-center tw-items-center">
            {/* 轮播图 */}
            <PhotoProvider>
              <Splide
                options={{
                  perPage: 1,
                  pagination: true,
                  type: "loop",
                  autoplay: true,
                  interval: "8000",
                  // perMove: "1",
                }}
              >
                {getimages?.map((item, index) => {
                  return (
                    <SplideSlide key={index}>
                      <PhotoView src={item} key={item}>
                        <img src={item} />
                      </PhotoView>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </PhotoProvider>
          </div>
        </Skeleton>
      </Loading>
    </JobDet>
  );
}

const JobDet = styled.div`
  padding: 0 0.9375rem 0 0.9375rem;
  /* 标题样式 */
  .job_header {
    div {
      margin: 0.625rem;
    }
    .jobtitle {
      font-size: 24px;
    }
    .jobsubtitle {
      font-size: 15px;
      span {
        padding: 0 4px;
        margin-right: 12px;
      }
    }
    .save_job {
      :hover {
        background-color: rgba(227, 115, 24, 0.15);
        box-shadow: inset 0 0 0 1px #fa9550;
      }
    }
    button {
      height: 2.8125rem;
      width: 5.625rem;
      margin: 0.3125rem 0.625rem 0.3125rem 0.4375rem;
      .t-ripple {
        display: none;
      }
      svg {
        margin: 0.25rem 0 0 0.25rem;
      }
    }
  }
  /* 内容样式 */
  .job_content {
    margin: 0.625rem;
  }
  .house_des {
    p {
      margin-top: 5px;
      font-size: 15px;
    }
  }
  /* 轮播图样式 */
  .imgswiper {
    margin: 4rem 0;

    /* 轮播图箭头样式 */
    .splide__arrow {
      opacity: 0;
      transition: all 0.3s;
      background-clip: padding-box;
    }
    :hover {
      .splide__arrow {
        opacity: 1;
        transition: all 0.4s;
        background-color: #fff;
      }
    }
    /* 轮播图指示器样式 */
    .splide__pagination {
      display: flex;
    }
    .splide__pagination__page {
      background-color: #d0caca;
    }
    .splide__pagination__page.is-active {
      background: #d9a40e;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: zoom-in;
    }
  }
`;

const Photocard = styled.div`
  min-height: 25rem;
  /* width: 200px; */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default RenthomeDetail;
