import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Avatar, Button } from "tdesign-react";
import { ChevronRightIcon } from "tdesign-icons-react";
import Headers from "../../components/Home/Headers";
import backgroundimg from "../../img/userpage.png";
import {
  selectToken,
  selectUserId,
  selectUserName,
} from "../../Redux/slices/authSlice";
import { useSelector } from "react-redux";
import Usercount from "./Usercount";
import Usersubtitle from "./Usersubtitle";
import UserInfo from "./UserInfo";
import { Outlet, useLocation, useParams } from "react-router-dom";
import UserSavelist from "../../components/Userpage/UserSavelist";
import {
  PostFollowUser,
  PostUnFollowUser,
  fetchFollowStatus,
  fetchSelfInfo,
  fetchUserBriefInfo,
  fetchUserInfo,
  fetchUserListCount,
} from "../../api/ApiRequest";
import moment from "moment";
import Nodataimg from "../../img/No data-cuate.png";
import UserForumDetail from "../../components/Userpage/UserForumDetail";
function Userpage() {
  // 获取头像
  let username = useSelector(selectUserName);
  let defaulticon = username?.slice(0, 1);
  let params = useParams();
  const myId = useSelector(selectUserId);
  const userId = params.userid;
  let token = useSelector(selectToken);

  const subTitles = [
    "newsave",
    "jobsave",
    "housesave",
    "postsave",
    "jobspost",
    "housespost",
    "formspost",
    "newslike",
    "formslike",
  ];

  // 获取用户个人信息
  const [newsdata, setNewsdata] = useState([]);
  const fetchData = async () => {
    if (myId === userId) {
      const response = await fetchSelfInfo(token);
      setNewsdata(response.data.data);
    } else {
      const response = await fetchUserInfo(params.userid);
      setNewsdata(response.data.data);
    }
  };
  // 判断url渲染不同页面
  let urllocation = useLocation();
  const spliteurl = urllocation.pathname.split("/");
  const urlmatch = spliteurl[spliteurl.length - 1];

  //   检查用户是否关注过
  const [followstatus, setFollowstatus] = useState(false);
  const checkFollowStatus = async () => {
    const response = await fetchFollowStatus(params.userid, token);
    if (response.data.data.exists) {
      setFollowstatus(true);
    }
    if (!response.data.data.exists) {
      setFollowstatus(false);
    }
  };

  //   关注用户向后端发请求
  const handleFollow = async () => {
    // 如果登陆后
    if (token) {
      // 如果关注过用户就post取消关注
      if (followstatus) {
        await PostUnFollowUser(params.userid, token).then(
          setFollowstatus(false)
        );
      } else {
        await PostFollowUser(params.userid, token).then(setFollowstatus(true));
      }
    }
  };

  // 获得收藏数字
  const [collectnumber, setCollectNumber] = useState({});

  const getCollectnumber = async () => {
    const response = await fetchUserListCount(params.userid);
    setCollectNumber(response.data.data);
  };

  // 获取用户首页动态资料
  const [getpostForums, setGetPostForums] = useState([]);
  const [getLikeForums, setGetLikeForums] = useState([]);
  const [getSaveForums, setGetSaveForums] = useState([]);

  const fetchUserActivity = async () => {
    const response = await fetchUserBriefInfo(params.userid);
    setGetPostForums([...response.data.data.forums]);
    setGetLikeForums([...response.data.data.likes]);
    setGetSaveForums([...response.data.data.collections]);
  };

  // 判断个人页面内容弹框是否显示
  const [visible, setVisible] = useState(false);

  const [targetForumId, setTargetForumId] = useState(null);

  const handleVisible = (forumId) => {
    setTargetForumId(forumId);
    setVisible(true);
  };

  const handleClose = () => {
    if (visible) {
      setVisible(false);
    }
  };

  useEffect(() => {
    fetchData();
    checkFollowStatus();
    getCollectnumber();
    fetchUserActivity();
  }, []);

  return (
    <UserpageBox className="userpage">
      <div className="user_header">
        <Headers />
      </div>
      <Userbackground>
        <div className="background_Info">
          <Avatar
            image={newsdata.avatar}
            size="80px"
            style={{ marginTop: "5px" }}
          >
            {newsdata.avatar === "" && defaulticon}
          </Avatar>
          {/* 用户名 */}
          <div className="background_username">{newsdata.username}</div>
          {/* 自我简介 */}
          {myId === params.userid ? (
            <div className="background_signature">
              {newsdata.signature === ""
                ? "用一句话介绍一下自己吧"
                : newsdata.signature}
            </div>
          ) : (
            <div className="background_signature">
              {newsdata.signature === ""
                ? "这个用户还没有留下任何签名"
                : newsdata.signature}
            </div>
          )}

          {myId !== params.userid && (
            // 关注按钮
            <div className="background_button">
              <Button theme="warning" onClick={handleFollow}>
                {followstatus ? "取消关注" : "关注"}
              </Button>
              <Button>私信</Button>
            </div>
          )}
        </div>
      </Userbackground>

      <Usersub>
        <Usercount
          followers={newsdata.followers}
          following={newsdata.following}
          forumsnumber={collectnumber.forumspostnum}
        />
        <Usersubtitle userid={params.userid} />
      </Usersub>
      <Usercontent>
        {/* 编辑个人信息 */}
        {urlmatch === params.userid && (
          <UserInfo dob={newsdata.dob} location={newsdata.location} />
        )}
        {urlmatch === "followers" && (
          <UserInfo dob={newsdata.dob} location={newsdata.location} />
        )}
        {urlmatch === "following" && (
          <UserInfo dob={newsdata.dob} location={newsdata.location} />
        )}

        {/* 列表 */}
        {subTitles.includes(urlmatch) && (
          <UserSavelist collectnumber={collectnumber} />
        )}

        {/* 主要内容展示，根据subtab的值返回对应的模块 */}
        <MainBox>
          {/* 主页显示的内容 */}
          {urlmatch === params.userid && (
            <div>
              {/* 我的帖子概括 */}
              <BriefInfo>
                <div className="header tw-flex tw-justify-between">
                  {urlmatch === myId ? <h3>我的帖子</h3> : <h3>Ta的帖子</h3>}
                  {getpostForums.length !== 0 && (
                    <Button
                      href={`/profile/userInfo/${params.userid}/formspost`}
                      variant="outline"
                      theme="warning"
                      size="small"
                    >
                      更多
                      <ChevronRightIcon style={{ marginTop: "3px" }} />
                    </Button>
                  )}
                </div>
                <ShowBox>
                  {getpostForums.map((items, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleVisible(items.forumid)}
                      >
                        {/* banner */}
                        <div className="thumbnail">
                          <img src={items.thumbnail} />
                        </div>
                        <div className="title">{items.title}</div>
                        <div className="time tw-flex tw-justify-between tw-mt-2">
                          <div>
                            发布于：
                            {moment(items.createdtime * 1000).format(
                              "DD-MM-YYYY"
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ShowBox>
              </BriefInfo>
              {/* 点赞概括 */}
              <BriefInfo>
                <div className="header tw-flex tw-justify-between">
                  {urlmatch === myId ? <h3>我的点赞</h3> : <h3>Ta的点赞</h3>}
                  {getLikeForums.length !== 0 && (
                    <Button
                      href={`/profile/userInfo/${params.userid}/formslike`}
                      variant="outline"
                      theme="warning"
                      size="small"
                    >
                      更多
                      <ChevronRightIcon style={{ marginTop: "3px" }} />
                    </Button>
                  )}
                </div>
                <ShowBox>
                  {getLikeForums.map((items, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleVisible(items.forumid)}
                      >
                        {/* banner */}
                        <div className="thumbnail">
                          <img src={items.thumbnail} />
                        </div>
                        <div className="title">{items.title}</div>
                        <div className="time tw-flex tw-justify-between tw-mt-2">
                          <div>
                            发布于：
                            {moment(items.liketime * 1000).format("DD-MM-YYYY")}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ShowBox>
              </BriefInfo>
              <div>
                <div className="header tw-flex tw-justify-between">
                  {urlmatch === myId ? <h3>我的收藏</h3> : <h3>Ta的收藏</h3>}
                  {getSaveForums.length !== 0 && (
                    <Button
                      href={`/profile/userInfo/${params.userid}/postsave`}
                      variant="outline"
                      theme="warning"
                      size="small"
                    >
                      更多
                      <ChevronRightIcon style={{ marginTop: "3px" }} />
                    </Button>
                  )}
                </div>
                <ShowBox>
                  {getSaveForums.length === 0 && (
                    <div className=" tw-flex tw-items-center tw-h-64">
                      <img style={{ height: "100%" }} src={Nodataimg} />
                      <span style={{ marginLeft: "40px" }}>
                        该用户还没有收藏的帖子
                      </span>
                    </div>
                  )}
                  {getSaveForums.map((items, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => handleVisible(items.forumid)}
                      >
                        {/* banner */}
                        <div className="thumbnail">
                          <img src={items.thumbnail} />
                        </div>
                        <div className="title">{items.title}</div>
                        <div className="time tw-flex tw-justify-between tw-mt-2">
                          <div>
                            发布于：
                            {moment(items.collecttime * 1000).format(
                              "DD-MM-YYYY"
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ShowBox>
              </div>
            </div>
          )}

          {urlmatch !== params.userid && (
            <Outlet context={[collectnumber, newsdata]} />
          )}

          {visible && (
            <UserForumDetail
              visible={visible}
              handleClose={handleClose}
              forumId={targetForumId}
              userId={userId}
            />
          )}
        </MainBox>
      </Usercontent>
    </UserpageBox>
  );
}

const UserpageBox = styled.div`
  background-color: transparent;
  /* tdesign对话框样式 
  /* 轮播图样式 */
  .imgswiper {
    /* 轮播图箭头样式 */
    .splide__arrow {
      opacity: 0;
      transition: all 0.3s;
      background-clip: padding-box;
    }
    :hover {
      .splide__arrow {
        opacity: 1;
        transition: all 0.4s;
        background-color: #fff;
      }
    }
    /* 轮播图指示器样式 */
    .splide__pagination {
      display: flex;
    }
    .splide__pagination__page {
      background-color: #d0caca;
    }
    .splide__pagination__page.is-active {
      background: #d9a40e;
    }
    img {
      border-radius: 24px 0 0 24px;
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: zoom-in;
    }
  }
  .user_header {
    background-color: #fff;
    height: 4rem;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  }
  li {
    color: #212121;
    font-size: 0.875rem;
    text-shadow: none;
  }
`;

const Userbackground = styled.div`
  position: relative;
  width: 80.1875rem;
  @media (max-width: 1420px) {
    width: 1022px;
  }
  height: 12rem;
  margin: 0 auto;
  background: url(${backgroundimg});
  .background_Info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    text-align: center;
  }
  .background_username {
    text-align: center;
    font-size: 18px;
    margin-top: 0.3125rem;
  }
  .background_signature {
    font-size: 16px;
  }
  .background_button {
    margin-top: 5px;
    button {
      margin-right: 5px;
    }
  }
`;

const Usersub = styled.div`
  display: flex;
  margin: 0 auto;
  width: 80.1875rem;
  @media (max-width: 1420px) {
    width: 1022px;
  }
  background-color: #fff;
  box-shadow: 0 0 0 1px #eee;
  margin-bottom: 10px;
  border-radius: 0 0 4px 4px;
`;

const Usercontent = styled.div`
  display: flex;
  margin: 0 auto;
  width: 80.1875rem;
  @media (max-width: 1420px) {
    width: 1022px;
  }
  margin-bottom: 10px;
  border-radius: 0 0 4px 4px;
`;

const MainBox = styled.div`
  background-color: #fff;
  box-shadow: 0 0 0 1px #eee;
  width: 100%;
  min-height: 630px;
  margin-left: 10px;
  overflow: hidden;
  /* height: 31.25rem; */
  padding: 15px;
  h3 {
    font-size: 20px;
  }
`;

const BriefInfo = styled.div`
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  .header {
    padding-bottom: 15px;
  }
`;

const ShowBox = styled.div`
  display: flex;
  overflow: hidden;
  li {
    padding: 10px 10px 10px 0;
    width: 200px;
    cursor: pointer;
    @media (max-width: 1420px) {
      margin-right: 10px;
    }
  }
  .thumbnail {
    width: 170px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title {
    margin-top: 10px;
    line-height: 20px;
    white-space: normal;
    overflow: hidden;
  }
  .time {
    margin-top: 6px;
  }
  a:hover {
    text-decoration: none;
  }
`;

export default Userpage;
