import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import authReducer from './slices/authSlice';
import homeReducer from './slices/homeSlice';
import jobReducer from './slices/jobSlice';
import filterReducer from './slices/filterSlice';
import jobUploadReducer from './slices/jobUploadSlice';
import houseReducer from './slices/renthouseSlice';
import storage from 'redux-persist/lib/storage';
import rentUploadReducer from './slices/rentUploadSlice';
import communityReducer from './slices/communitySlice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  home: homeReducer,
  job: jobReducer,
  house: houseReducer,
  filter: filterReducer,
  jobUpload: jobUploadReducer,
  rentUpload: rentUploadReducer,
  community: communityReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
