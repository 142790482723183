import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentTag: 1,
  subTag: 1,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setCurrentTag: (state, action) => {
      state.currentTag = action.payload;
    },
    setSubTag: (state, action) => {
      state.subTag = action.payload;
    },

  },
});

export const { setCurrentTag, setSubTag } = homeSlice.actions;

export const selectCurrentTag = (state) => state.home.currentTag;
export const selectSubTag = (state) => state.home.subTag;

export default homeSlice.reducer;
