import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  rentmethod: '4',
  origin: '个人',
  location: '',
  housetype: '',
  houseUnit: '面议',
  checkinTime: '',
  minimumperiod: '面议',
  Priceweek: '',
  houseDescription: '',
  imgs: [],
  imgsurl:[],
  currentStep: 0,
};

export const rentUploadSlice = createSlice({
  name: 'rentUpload',
  initialState,
  reducers: {
    reset: () => initialState,
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setRentmethod: (state, action) => {
      state.rentmethod = action.payload;
    },
    setOrigin: (state, action) => {
      state.origin = action.payload;
    },
    setHousetype: (state, action) => {
      state.housetype = action.payload;
    },
    setHouseunit: (state, action) => {
      state.houseUnit = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setcheckinTime: (state, action) => {
      state.checkinTime = action.payload;
    },
    setMinimumperiod: (state, action) => {
      state.minimumperiod = action.payload;
    },
    setPriceweek: (state, action) => {
      state.Priceweek = action.payload;
    },
    setHouseDescription: (state, action) => {
      state.houseDescription = action.payload;
    },
    setImgs: (state, action) => {
      state.imgs = action.payload;
    },
    deleteImg: (state, action) => {
      state.imgs = state.imgs.filter((item, index) => index !== action.payload);
    },
    setImgsUrl:(state, action) => {
      state.imgsurl = action.payload;
    },
    deleteImgUrl: (state, action) => {
      state.imgsurl = state.imgsurl.filter((item, index) => index !== action.payload);
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
  },
});

export const {
  reset,
  setTitle,
  setRentmethod,
  setOrigin,
  setHousetype,
  setHouseunit,
  setcheckinTime,
  setMinimumperiod,
  setPriceweek,
  setHouseDescription,
  setImgs,
  deleteImg,
  setImgsUrl,
  deleteImgUrl,
  setLocation,
  setCurrentStep,
} = rentUploadSlice.actions;

export const selectTitle = (state) => state.rentUpload.title;
export const selectRentmethod = (state) => state.rentUpload.rentmethod;
export const selectOrigin = (state) => state.rentUpload.origin;
export const selectHousetype = (state) => state.rentUpload.housetype;
export const selectHouseunit = (state) => state.rentUpload.houseUnit;
export const selectCheckinTime = (state) => state.rentUpload.checkinTime;
export const selectMinimumperiod = (state) => state.rentUpload.minimumperiod;
export const selectPriceweek = (state) => state.rentUpload.Priceweek;
export const selecthouseDescription = (state) => state.rentUpload.houseDescription;
export const selectImgs = (state) => state.rentUpload.imgs;
export const selectImgsUrl = (state) => state.rentUpload.imgsurl;
export const selectLocation = (state) => state.rentUpload.location;
export const selectCurrentStep = (state) => state.rentUpload.currentStep;

export default rentUploadSlice.reducer;
