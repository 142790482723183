import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Dialog, Input } from "tdesign-react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectToken } from "../../Redux/slices/authSlice";
function CommunityGroupAdd({ visible, onClose }) {
  let token = useSelector(selectToken);
  // 向后端申请内容详细数据，跳出弹窗，路由跳转
  let params = useLocation();
  const a = params.pathname.split("/");
  const forumid = a[a.length - 1];
  const [getimages, setGetimages] = useState([]);

  const fetchData = async () => {};
  // 收藏点赞评论状态
  const [like, setLike] = useState(false);
  const [save, setSave] = useState(false);

  // 点赞帖子
  const handleLike = async () => {};

  // 查看用户是否点赞过帖子
  const checkLikeStatus = async () => {};
  return (
    <Bigbox>
      <Dialog
        visible={visible}
        onClose={onClose}
        confirmBtn={null}
        cancelBtn={null}
        closeBtn={null}
        footer={false}
        zIndex="1500"
        attach=".App"
        className="group"
      ></Dialog>
    </Bigbox>
  );
}

const Bigbox = styled.div`
  /* tdesign对话框样式 */
`;

// 论坛详细信息的头部
const Headerdetail = styled.div`
  display: flex;
  padding: 20px 30px;
  justify-content: space-between;
  align-items: center;
  .thumbnail {
    height: 43px;
    display: flex;
    align-items: center;
    /* line-height: 43px; */
    img {
      border-radius: 50%;
      object-fit: cover;
    }
  }
`;

// 论坛详细信息的内容
const Contentdetail = styled.div`
  padding: 10px 30px 20px 30px;
  height: 40.625rem;
  h1 {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: #333;
  }
`;

// 论坛详细信息的评论
const Commentdetail = styled.div`
  padding: 20px 30px;
  height: 9.375rem;
  border-top: 1px solid rgba(239, 239, 239);
  .left {
    display: flex;
  }
  .icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    .count {
      margin: 0 10px 0 5px;
    }
  }
  .comment_input {
    margin-top: 1.25rem;
  }
`;
export default CommunityGroupAdd;
