import React from "react";
import Steps from "tdesign-react/es/steps/Steps";
import StepItem from "tdesign-react/es/steps/StepItem";

import "./jobStyle.css";

function UploadStep({ current }) {
  return (
    <Steps
      current={current}
      layout="horizontal"
      separator="line"
      // readonly
      sequence="positive"
      theme="default"
      className="tw-w-4/6 tw-mt-4 tw-mb-2"
    >
      <StepItem icon status="default" title="工作简述" />
      <StepItem icon status="default" title="工作介绍" />
      <StepItem icon status="default" title="雇主信息" />
    </Steps>
  );
}

export default UploadStep;
