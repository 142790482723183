import React, { useEffect, useState } from "react";
import styled from "styled-components";
import backgroundimg from "../../../img/userpage.png";
import { Avatar, Button } from "tdesign-react";
import { useNavigate, useParams } from "react-router-dom";
import {
  PostForumLike,
  PostForumUnlike,
  PostJoinGroup,
  PostUnJoinGroup,
  fetchGroupForum,
  fetchGroupForumLogin,
  fetchGroupsInfo,
  fetchJoinStatus,
} from "../../../api/ApiRequest";
import { BiSearchAlt } from "react-icons/bi";
import getCursor from "../../../utils/getCursor";
import InfiniteScroll from "react-infinite-scroll-component";
import ListLoading from "../../Loading/ListLoading";
import {
  HeartIcon,
  HeartFilledIcon,
  StarIcon,
  StarFilledIcon,
  ChatIcon,
} from "tdesign-icons-react";
import { FaRegShareSquare } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectToken } from "../../../Redux/slices/authSlice";

function GroupForum() {
  const location = useParams();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const [getdata, setGetdata] = useState([]);
  const [forumlist, setForumList] = useState([]);
  const [hasmore, sethasmore] = useState(true);
  const [cursor, setCursor] = useState(getCursor());
  const [joinstatus, setJoinStatus] = useState(false);

  // 获取群组帖子
  const fetchList = async () => {
    if (token) {
      const response = await fetchGroupForumLogin(
        cursor,
        location.groupid,
        token
      );
      if (response.data.info === "no more forums") {
        sethasmore(false);
      } else {
        setForumList([...forumlist, ...response.data.data]);
        setCursor(response.data.cursor);
      }
    } else {
      const response = await fetchGroupForum(cursor, location.groupid);
      if (response.data.info === "no more forums") {
        sethasmore(false);
      } else {
        setForumList([...forumlist, ...response.data.data]);
        setCursor(response.data.cursor);
      }
    }
  };

  // 加入群组和退出群组
  const handleJoined = async () => {
    if (token) {
      // 如果已关注，就退出群组
      if (joinstatus) {
        PostUnJoinGroup(location.groupid, token).then(() => {
          setJoinStatus(false);
        });
      } else {
        PostJoinGroup(location.groupid, token).then(() => {
          setJoinStatus(true);
        });
      }
    } else {
      navigate("/login");
    }
  };

  // 帖子点赞
  const handleLiked = async (id, liked) => {
    if (token) {
      if (liked === null) {
        PostForumLike(id, token).then(() => {
          const newstate = [...forumlist];
          found: for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].forumid === id) {
              newstate[index].liked = "1";
              newstate[index].likes += 1;
              break found;
            }
          }
          setForumList(newstate);
        });
      } else if (liked !== null) {
        PostForumUnlike(id, token).then(() => {
          const newstate = [...forumlist];
          found: for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].forumid === id) {
              newstate[index].liked = null;
              newstate[index].likes -= 1;
              break found;
            }
          }
          setForumList(newstate);
        });
      }
    } else navigate("/login");
  };

  useEffect(() => {
    // 获取群组信息
    fetchGroupsInfo(location.groupid).then((res) => {
      setGetdata(res.data.group);
    });

    // 检查用户是否加入了群组
    fetchJoinStatus(location.groupid, token).then((res) => {
      setJoinStatus(res.data.data);
    });

    fetchList();
  }, []);

  return (
    <ForumBox>
      <div className="background">
        <img src={backgroundimg} alt="" />
      </div>
      {/* 群组信息 */}
      <div className="group_title">
        <div className="info">
          {/* 群组头像 */}
          <Avatar
            shape="square"
            size="120px"
            image={getdata.avatar}
            style={{ position: "absolute", top: "-70%", left: "5%" }}
          ></Avatar>
          <div className="title">{getdata.name}</div>
          <div className="detail">{getdata.description}</div>
        </div>
        {joinstatus ? (
          <Button
            theme="warning"
            style={{ marginRight: "10px" }}
            onClick={() => handleJoined()}
          >
            取消关注
          </Button>
        ) : (
          <Button
            theme="warning"
            style={{ marginRight: "10px" }}
            onClick={() => handleJoined()}
          >
            关注
          </Button>
        )}
      </div>
      <ForumList>
        <Tab>
          <span className="tw-text-2xl tw-text-gray-900">论坛精选</span>
          <div className="search_container">
            <input type="text" placeholder="搜索帖子吧" />
            <BiSearchAlt className="icon" />
          </div>
        </Tab>
        {/* 滚动加载 */}
        <InfiniteScroll
          dataLength={forumlist.length} //This is important field to render the next
          next={() => fetchList()}
          hasMore={hasmore}
          loader={<ListLoading />}
          style={{ marginTop: "10px" }}
          endMessage={
            <div
              className=" tw-flex tw-items-center tw-justify-center"
              style={{ fontSize: "16px", height: "60px" }}
            >
              <b>-已经看到底了-</b>
            </div>
          }
        >
          {forumlist?.map((item) => {
            let username = item.username;
            let defaulticon = username?.slice(0, 1);
            return (
              <List key={item.forumid}>
                <div className=" tw-flex">
                  <Avatar
                    shape="round"
                    size="40px"
                    image={item.avatar !== "" && item.avatar}
                  >
                    {item.avatar === "" ? defaulticon : item.avatar}
                  </Avatar>
                  <div className="content">
                    <h1>{item.username}</h1>
                    <h3>{item.title}</h3>
                    <div className="image">
                      <img src={item.thumbnail} alt="" />
                    </div>
                  </div>
                </div>
                <Actions>
                  <div>
                    <FaRegShareSquare style={{ marginRight: "5px" }} />
                    分享
                  </div>
                  <div>
                    <ChatIcon size="19px" style={{ marginRight: "5px" }} />
                    评论
                  </div>
                  <div onClick={() => handleLiked(item.forumid, item.liked)}>
                    {item.liked === null ? (
                      <HeartIcon
                        size="19px"
                        style={{
                          marginRight: "5px",
                        }}
                      />
                    ) : (
                      <HeartFilledIcon
                        size="19px"
                        style={{
                          marginRight: "5px",
                          color: token && item.liked !== null ? "#ff2442" : "",
                        }}
                      />
                    )}
                    <span>{item.likes}</span>
                  </div>
                </Actions>
              </List>
            );
          })}
        </InfiniteScroll>
      </ForumList>
    </ForumBox>
  );
}

const ForumBox = styled.div`
  height: 100%;
  .info {
    flex: 2;
    padding-left: 200px;
  }
  .group_title {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    height: 75px;
    .title {
      font-size: 24px;
      color: #333;
    }
    .detail {
      font-size: 14px;
      color: #3c3c3c;
    }
  }
`;

// 论坛盒子
const ForumList = styled.div`
  padding: 10px 10px 0 45px;
`;
// 搜索栏
const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search_container {
    position: relative;
    width: 200px;
    input {
      width: 100%;
      padding: 10px 0px 10px 15px;
      border-radius: 300px;
      border: 1px solid #ccc;
      outline: none;
      background-color: #f8f8f8;
      font-size: 13px;
    }
    .icon {
      position: absolute;
      top: 50%;
      right: 10px;
      font-size: 20px;
      transform: translate(0, -50%);
    }
  }
`;
const List = styled.div`
  margin-top: 10px;
  padding: 20px 20px 0 20px;
  background-color: #fff;
  .content {
    margin-left: 20px;
    h1 {
      font-size: 20px;
      line-height: 20px;
      color: #e37353;
      cursor: pointer;
    }
    h3 {
      font-size: 16px;
      color: #111827;
      margin-top: 10px;
      cursor: pointer;
    }
    .image {
      margin-top: 10px;
      width: 200px;
      cursor: zoom-in;
    }
  }
`;
const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 14px;
    cursor: pointer;
    :hover {
      color: #d9a40e;
    }
  }
`;
export default GroupForum;
