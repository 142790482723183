import axios from 'axios';
// import { io } from 'socket.io-client';

// process.env.REACT_APP_Backend_url
export const apiCall = axios.create({
  baseURL: 'https://tietieserver.onrender.com',
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*',
  },
});
// 登录注册
export const logIn = async (FormData) => {
  try {
    const response = await apiCall.post('/auth/login', FormData);
    return response;
  } catch (error) {
    return error;
  }
};

export const signUp = async (FormData) => {
  try {
    const response = await apiCall.post(`/auth/register`, FormData);
    return response;
  } catch (error) {
    console.error(error);
    return error.message;
  }
};

export const verify = async (token) => {
  try {
    const response = await apiCall.get('/auth/verify', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    throw error;
  }
};
// 获取新闻列表
export const fetchNews = async (category, startIndex) => {
  try {
    const response = await apiCall.get(`/news/${category}`, {
      params: {
        startIndex,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取新闻详细内容
export const fetchNewsdetail = async (newsId) => {
  try {
    const response = await apiCall.get(`/news/detail/${newsId}`, {});
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取热榜新闻
export const fetchRankNews = async () => {
  try {
    const response = await apiCall.get(`/news/hotNews/rank`, {});
    return response;
  } catch (error) {
    throw error;
  }
};

// 新闻点赞
export const PostNewsLike = async (newsId, token) => {
  try {
    const response = await apiCall.post(
      'news/like',
      {
        newsId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 新闻取消点赞
export const PostNewsUnLike = async (newsId, token) => {
  try {
    const response = await apiCall.post(
      'news/unlike',
      {
        newsId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 新闻收藏
export const PostNewsSave = async (newsId, token) => {
  try {
    const response = await apiCall.post(
      'news/collect',
      {
        newsId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 取消新闻收藏
export const PostNewsUnSave = async (newsId, token) => {
  try {
    const response = await apiCall.post(
      'news/uncollect',
      {
        newsId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 检查用户是否点赞了特定新闻
export const fetchNewsLike = async (newsId, token) => {
  try {
    const response = await apiCall.get(`/news/liked/check/`, {
      params: {
        newsId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 检查用户是否收藏了特定新闻
export const fetchNewsSave = async (newsId, token) => {
  try {
    const response = await apiCall.get(`/news/collect/${newsId}`, {
      params: {
        newsId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 发布新闻评论
export const PostNewsreply = async (newsId, content, token) => {
  try {
    const response = await apiCall.post(
      '/reply/new',
      {
        newsId,
        content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取新闻一级评论
export const fetchNewsAllReply = async (newsId, cursor) => {
  try {
    const response = await apiCall.get(`/reply/all/${newsId}`, {
      params: {
        cursor,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 回复评论
export const PostNewsSecondreply = async (commentId, content, receiver, replytofirst, token) => {
  try {
    const response = await apiCall.post(
      '/reply/reply',
      {
        commentId,
        content,
        receiver,
        replytofirst,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 点赞一级评论
export const PutLikeComment = async (commentId, token, creator) => {
  try {
    const response = await apiCall.put(
      'reply/comment/like',
      {
        commentId,
        creator,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 取消点赞一级评论
export const PutUnlikeComment = async (commentId, token) => {
  try {
    const response = await apiCall.put(
      'reply/comment/unlike',
      {
        commentId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 点赞二级评论
export const PutLikeSecondComment = async (replyId, token, creator) => {
  try {
    const response = await apiCall.put(
      'reply/reply/like',
      {
        replyId,
        creator,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 取消二级评论点赞
export const PutUnlikeSecondComment = async (replyId, token) => {
  try {
    const response = await apiCall.put(
      'reply/reply/unlike',
      {
        replyId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 登录后，获取现有新闻所有一级评论
export const checkNewsCommentStatus = async (newsId, token, cursor) => {
  try {
    const response = await apiCall.get(`/reply/login/all/${newsId}`, {
      params: {
        cursor,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 登录前，获取现有新闻的二级评论
export const fetchNewsExpandReply = async (commentId, cursor) => {
  try {
    const response = await apiCall.get(`/reply/reply/${commentId}`, {
      params: {
        cursor,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 登录后，获取现有新闻的二级评论
export const checkNewsReplystatus = async (commentId, token, cursor) => {
  try {
    console.log('here');
    console.log(cursor);
    const response = await apiCall.get(`/reply/login/reply/${commentId}`, {
      params: {
        cursor,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取悉尼地区地址
export const fetchSydlocation = async () => {
  try {
    const response = await apiCall.get(`/jobs/locations`);
    return response;
  } catch (error) {
    throw error;
  }
};
// 获取工作招聘列表
export const fetchJobslist = async (cursor, category, experience, industry, location) => {
  try {
    category = category === '' ? '6' : category;
    experience = experience === '' ? '3' : experience;
    industry = industry.length === 0 ? '16' : industry;
    location = location === '' ? '700' : location;
    const response = await apiCall.get(`/jobs/overview`, {
      params: {
        cursor,
        category,
        experience,
        industry,
        location,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取工作招聘详细信息
export const fetchJobsdetail = async (jobId) => {
  try {
    const response = await apiCall.get(`/jobs/detail`, {
      params: {
        jobId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 上传新的招聘信息

export const PostNewJobs = async (
  title,
  salaryFrom,
  salaryTo,
  salaryUnit,
  experience,
  location,
  category,
  industry,
  companyTitle,
  companyDescription,
  jobDescription,
  companyAdvantage,
  jobRequirment,
  token
) => {
  try {
    const response = await apiCall.post(
      '/jobs/upload',
      {
        title: title,
        salaryFrom: salaryFrom,
        salaryTo: salaryTo,
        salaryUnit: salaryUnit,
        experience: experience,
        location: location,
        category: category,
        industry: industry,
        companyTitle: companyTitle,
        companyDescription: companyDescription,
        jobDescription: jobDescription,
        companyAdvantage: JSON.stringify(companyAdvantage),
        jobRequirment: JSON.stringify(jobRequirment),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 修改招聘信息
export const UpdateJobs = async (
  jobId,
  title,
  salaryFrom,
  salaryTo,
  salaryUnit,
  experience,
  location,
  category,
  industry,
  companyTitle,
  companyDescription,
  jobDescription,
  companyAdvantage,
  jobRequirment,
  token
) => {
  try {
    const response = await apiCall.post(
      `/jobs/update/${jobId}`,
      {
        title: title,
        salaryFrom: salaryFrom,
        salaryTo: salaryTo,
        salaryUnit: salaryUnit,
        experience: experience,
        location: location,
        category: category,
        industry: industry,
        companyTitle: companyTitle,
        companyDescription: companyDescription,
        jobDescription: jobDescription,
        companyAdvantage: JSON.stringify(companyAdvantage),
        jobRequirment: JSON.stringify(jobRequirment),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};


// 删除招聘信息
export const DelUserPostJob = async (jobId,token) => {
  try {
    const response = await apiCall.delete(`/jobs/delete/${jobId}`, 
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }}

export const checkCollectStatus = async (jobId, token) => {
  try {
    const response = await apiCall.get(`/jobs/collect/${jobId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const collectJob = async (jobId, token) => {
  try {
    const response = await apiCall.post(
      '/jobs/collect',
      {
        jobId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 取消工作收藏
export const uncollectJob = async (jobId, token) => {
  try {
    const response = await apiCall.post(
      '/jobs/uncollect',
      {
        jobId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 上传新的租房信息
export const PostNewHomes = async (
  title,
  price,
  priceRange,
  priceUnit,
  images,
  houseFrom,
  location,
  category,
  method,
  detail,
  checkInTime,
  minimumPeriod,
  token
) => {
  try {
    const response = await apiCall.post(
      '/house/new',
      {
        title,
        price,
        priceRange,
        priceUnit,
        images,
        houseFrom,
        location,
        category,
        method,
        detail,
        checkInTime,
        minimumPeriod,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取用户投稿的租房信息
export const fetchUserPostHouse = async (pageIndex, userId) => {
  try {
    const response = await apiCall.get(`/house/gethouse`, {
      params: {
        pageIndex,
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }}

// 删除租房信息
export const DelUserPostHouse = async (houseId,token) => {
  try {
    const response = await apiCall.delete(`/house/delete/${houseId}`, 
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }}

// 获取租房列表
export const fetchHomeslist = async (cursor, method, category, price, location) => {
  try {
    const response = await apiCall.get(`/house/overview`, {
      params: {
        cursor,
        method,
        category,
        price,
        location,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
// 获取租房详细信息
export const fetchHomesdetail = async (houseId) => {
  try {
    const response = await apiCall.get(`/house/detail`, {
      params: {
        houseId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 取消收藏租房
export const PostHousesUnSave = async (houseId, token) => {
  try {
    const response = await apiCall.post(
      'house/cancelCollect',
      {
        houseId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 收藏租房
export const PostHousesSave = async (houseId, token) => {
  try {
    const response = await apiCall.post(
      'house/collect',
      {
        houseId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 检查租房收藏状态
export const fetchHouseSave = async (houseId, token) => {
  try {
    const response = await apiCall.get(`/house/collectStatus`, {
      params: {
        houseId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 修改用户头像接口
export const uploadImage = async (data, token) => {
  try {
    const response = await apiCall.post(
      '/profile/uploadImage',
      {
        image: data,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
// 修改用户资料
export const UpdateUserInfo = async (username, avatar, signature, dob, location, token) => {
  try {
    const response = await apiCall.put(
      'profile/updateUserInfo',
      {
        username: username,
        avatar: avatar,
        signature: signature,
        dob: dob,
        location: location,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};


// 获取用户简要
export const fetchUserBriefInfo = async ( userId) => {
  try {
    const response = await apiCall.get(`/profile/getUserBriefInfo`, {
      params: {
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }}

// 获取用户发布的招聘信息
export const fetchUserPostJob = async (pageIndex, userId) => {
  try {
    const response = await apiCall.get(`/jobs/getJobs`, {
      params: {
        pageIndex,
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }}

// 获取用户发布点赞收藏总数
export const fetchUserListCount = async (userId) => {
  try {
    const response = await apiCall.get(`/profile/getUserListCount`, {
      params:{
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取用户收藏的工作
export const fetchUserCollectJob = async (pageIndex, userId) => {
  try {
    const response = await apiCall.get(`/jobs/collect`, {
      params: {
        pageIndex,
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取用户收藏的新闻
export const fetchUserCollectNews = async (pageIndex, userId,token) => {
  try {
    const response = await apiCall.get(`/news/collect/all`, {
      params: {
        pageIndex,
        userId
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取用户点赞的新闻
export const fetchUserLikeNews = async (pageIndex, userId,token) => {
  try {
    const response = await apiCall.get(`/news/liked/all`, {
      params: {
        pageIndex,
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取用户收藏的租房信息
export const fetchUserCollectHouses = async (pageIndex, userId) => {
  try {
    const response = await apiCall.get(`/house/collect/all`, {
      params: {
        pageIndex,
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取用户发布过的帖子
export const fetchUserPostForums = async (pageIndex, userId) => {
  try {
    const response = await apiCall.get(`/forum/all`, {
      params: {
        pageIndex,
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }}


// 获取用户点赞过的帖子
export const fetchUserLikeForums = async (pageIndex, userId) => {
  try {
    const response = await apiCall.get(`/forum/liked`, {
      params: {
        pageIndex,
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }}

// 获取用户收藏帖子
export const fetchUserCollectForums = async (pageIndex, userId) => {
  try {
    const response = await apiCall.get(`/forum/collections`, {
      params: {
        pageIndex,
        userId
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取其他用户信息
export const fetchSelfInfo = async (token) => {
  try {
    const response = await apiCall.get(`/profile/userInfo/self`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取其他用户信息
export const fetchUserInfo = async (userId) => {
  try {
    const response = await apiCall.get(`/profile/userInfo`, {
      params: {
        userId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 检查用户是否关注过用户
export const fetchFollowStatus = async (targetUserId, token) => {
  try {
    const response = await apiCall.get(`/profile/checkFollowing`, {
      params: {
        targetUserId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 关注用户
export const PostFollowUser = async (targetUserId, token) => {
  try {
    const response = await apiCall.post(
      'profile/followUser',
      {
        targetUserId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

//  取消关注用户
export const PostUnFollowUser = async (targetUserId, token) => {
  try {
    const response = await apiCall.post(
      'profile/unfollowUser',
      {
        targetUserId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取用户粉丝信息
export const fetchFollowers = async (userId, startIndex) => {
  try {
    const response = await apiCall.get(`/profile/getFollowers`, {
      params: {
        userId,
        startIndex,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 登录后获取用户粉丝信息
export const fetchLoginFollowers = async (userId, startIndex, token) => {
  try {
    const response = await apiCall.get(`/profile/getFollowers/login`, {
      params: {
        userId,
        startIndex,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取用户关注信息
export const fetchFollowing = async (userId, startIndex) => {
  try {
    const response = await apiCall.get(`/profile/getFollowing`, {
      params: {
        userId,
        startIndex,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 登录后获取用户关注信息
export const fetchLoginFollowing = async (userId, startIndex, token) => {
  try {
    const response = await apiCall.get(`/profile/getFollowing/login`, {
      params: {
        userId,
        startIndex,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

//-----------------------------------论坛

// 获取论坛列表
export const fetchForumList = async (cursor) => {
  try {
    const response = await apiCall.get(`/forum/overview`, {
      params: {
        cursor: cursor,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 登录后获取论坛列表
export const fetchForumListLogin = async (cursor,token) => {
  try {
    const response = await apiCall.get(`/forum/overview/login`, {
      params: {
        cursor: cursor,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};


// 获取用户关注论坛列表
export const fetchFollowForumList = async (cursor, token) => {
  try {
    const response = await apiCall.get(`/forum/followed`, {
      params: {
        cursor,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取论坛详细信息
export const fetchForumDetail = async (forumId) => {
  try {
    console.log("counter")
    const response = await apiCall.get(`/forum/detail`, {
      params: {
        forumId,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 用户删除帖子
export const DelUserPostForums = async (forumId,token) => {
  try {
    const response = await apiCall.delete(`/forum/delete/${forumId}`, 
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }}


// 用户更新帖子
export const PostUserEditForums = async (forumId,title,content,images,isPublic,category,token) => {
  try {
    const response = await apiCall.post(`/forum/update/${forumId}`, 
    {
      title,
      content,
      images,
      isPublic,
      category,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }}

// 检查用户是否点赞了帖子
export const fetchForumLike = async (forumId, token) => {
  try {
    const response = await apiCall.get(`forum/isliked`, {
      params: {
        forumId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 取消点赞帖子
export const PostForumUnlike = async (forumId, token) => {
  try {
    const response = await apiCall.post(
      '/forum/unlike',
      {
        forumId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 点赞帖子
export const PostForumLike = async (forumId, token) => {
  try {
    const response = await apiCall.post(
      '/forum/like',
      {
        forumId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 收藏帖子
export const PostForumSave = async (forumId, token) => {
  try {
    const response = await apiCall.post(
      '/forum/collect',
      {
        forumId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 取消收藏帖子
export const PostForumUnsave = async (forumId, token) => {
  try {
    const response = await apiCall.post(
      '/forum/uncollect',
      {
        forumId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 检查用户是否收藏了论坛帖子
export const fetchForumSaveStatus = async (forumId, token) => {
  try {
    const response = await apiCall.get(`/forum/collectStatus`, {
      params: {
        forumId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 论坛一级评论发布
export const PostForumComment = async (forumId, content, token) => {
  try {
    const response = await apiCall.post(
      '/forum/comments/create',
      {
        forumId,
        content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取论坛帖子一级评论
export const fetchForumFirstReply = async (forumId, cursor) => {
  try {
    const response = await apiCall.get(`/forum/comments`, {
      params: {
        forumId,
        cursor,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 登陆后获取论坛帖子一级评论
export const fetchForumCommentLogin = async (forumId, cursor, token) => {
  try {
    const response = await apiCall.get(`/forum/comments/login`, {
      params: {
        forumId,
        cursor,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 点赞帖子一级评论
export const PostForumLikeComment = async (forumCommentId, token) => {
  try {
    const response = await apiCall.post(
      '/forum/comments/like',
      {
        forumCommentId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 取消点赞论坛帖子一级评论
export const PostForumUnlikeComment = async (forumCommentId, token) => {
  try {
    const response = await apiCall.post(
      '/forum/comments/unlike',
      {
        forumCommentId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 论坛二级评论发布
export const PostForumReply = async (forumCommentId, content, replyToFirst, receiver, token) => {
  try {
    const response = await apiCall.post(
      '/forum/reply',
      {
        forumCommentId,
        content,
        replyToFirst,
        receiver,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 点赞论坛帖子二级评论
export const PostForumLikeReply = async (forumReplyId, token) => {
  try {
    const response = await apiCall.post(
      '/forum/reply/like',
      {
        forumReplyId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 取消点赞论坛帖子二级评论
export const PostForumUnlikeReply = async (forumReplyId, token) => {
  try {
    const response = await apiCall.post(
      '/forum/reply/unlike',
      {
        forumReplyId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 登陆前获取论坛帖子二级评论
export const fetchForumReply = async (forumCommentId, cursor) => {
  try {
    const response = await apiCall.get(`/forum/comments/replies`, {
      params: {
        forumCommentId,
        cursor,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 登陆后获取论坛帖子二级评论
export const fetchForumReplyLogin = async (forumCommentId, cursor, token) => {
  try {
    console.log(cursor);
    const response = await apiCall.get(`/forum/comments/replies/login`, {
      params: {
        forumCommentId,
        cursor,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 帖子发布
export const PostForum = async (title, content, images, isPublic, category, token) => {
  try {
    const response = await apiCall.post(
      '/forum/create',
      {
        title,
        content,
        images,
        isPublic,
        category,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 论坛帖子搜索联想接口
export const fetchForumAutocomplete = async (term) => {
  try {
    const response = await apiCall.get(`/forum/autoComplete`, {
      params: {
        term,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 论坛帖子搜索后的结果
export const fetchForumSearch = async (term, cursor) => {
  try {
    const response = await apiCall.get(`/forum/search`, {
      params: {
        term,
        cursor: cursor,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取推荐群组
export const fetchRecommendGroups = async () => {
  try {
    const response = await apiCall.get(`/group/list`, {
      headers: {},
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取用户加入的群组
export const fetchUserGroups = async (token) => {
  try {
    const response = await apiCall.get(`/group/joined`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取群组信息
export const fetchGroupsInfo = async (groupId) => {
  try {
    const response = await apiCall.get(`/group/id/${groupId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取群组帖子
export const fetchGroupForum = async (cursor, groupId) => {
  try {
    const response = await apiCall.get(`group/id/${groupId}/posts`, {
      params: {
        cursor: cursor,
      },
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 登录后获取群组帖子
export const fetchGroupForumLogin = async (cursor, groupId,token) => {
  try {
    const response = await apiCall.get(`group/id/${groupId}/posts/login`, {
      params: {
        cursor: cursor,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      }
    }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 获取群组成员
export const fetchGroupMember = async (groupId) => {
  try {
    const response = await apiCall.get(`group/id/${groupId}/members`, {});
    return response;
  } catch (error) {
    throw error;
  }
};

// 判断用户是否加入了群组
export const fetchJoinStatus = async (groupId,token) => {
  try {
    const response = await apiCall.get(`group/id/${groupId}/check`, {
      
        headers: {
          Authorization: `Bearer ${token}`,
        },
      
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// 加入群组
export const PostJoinGroup = async (groupId,token) => {
  try {
    const response = await apiCall.post(`group/id/${groupId}/join`,{}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
    });
    return response;
  } catch (error) {
    throw error;
  }
};
// 退出群组
export const PostUnJoinGroup = async (groupId,token) => {
  try {
    const response = await apiCall.post(`group/id/${groupId}/leave`,{}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
    });
    return response;
  } catch (error) {
    throw error;
  }
};


// 加入群组聊天室
export const joinChatRoom = async (groupId, socketId, token) => {
  try {
    const response = await apiCall.post(
      `/chat/join/${groupId}`,
      {
        socketId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

// 聊天室发送消息
export const sendChatMessage = async (groupId, content, token) => {
  try {
    const response = await apiCall.post(
      `/chat/send/${groupId}`,
      {
        content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
