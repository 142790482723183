import React from "react";
import styled from "styled-components";
import Mobilefooter from "../components/home/Mobilefooter";
import Mobileheader from "../components/home/Mobileheader";
import MobilehomeContain from "../components/home/MobilehomeContain";

function Mobilehome() {
  return (
    <>
      <Mobileheader />
      <MobilehomeContain />
      <Mobilefooter />
    </>
  );
}

export default Mobilehome;
