import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  experience: '不限',
  category: '1',
  industry: '',
  salaryFrom: '',
  salaryTo: '',
  salaryUnit: '面议',
  jobDescription: [],
  jobRequirement: [],
  companyName: '',
  companyDescription: '',
  companyAdvantage: [],
  location: '',
  currentStep: 0,
};

export const jobUploadSlice = createSlice({
  name: 'jobUpload',
  initialState,
  reducers: {
    reset: () => initialState,
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setExperience: (state, action) => {
      state.experience = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setIndustry: (state, action) => {
      state.industry = action.payload;
    },
    setSalaryFrom: (state, action) => {
      state.salaryFrom = action.payload;
    },
    setSalaryTo: (state, action) => {
      state.salaryTo = action.payload;
    },
    setSalaryUnit: (state, action) => {
      state.salaryUnit = action.payload;
    },
    setJobDescription: (state, action) => {
      state.jobDescription = action.payload;
    },
    setJobRequirement: (state, action) => {
      state.jobRequirement = action.payload;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setCompanyDescription: (state, action) => {
      state.companyDescription = action.payload;
    },
    setCompanyAdvantage: (state, action) => {
      state.companyAdvantage = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
  },
});

export const {
  reset,
  setTitle,
  setExperience,
  setCategory,
  setIndustry,
  setSalaryFrom,
  setSalaryTo,
  setSalaryUnit,
  setJobDescription,
  setJobRequirement,
  setCompanyName,
  setCompanyDescription,
  setCompanyAdvantage,
  setLocation,
  setCurrentStep,
} = jobUploadSlice.actions;

export const selectTitle = (state) => state.jobUpload.title;
export const selectExperience = (state) => state.jobUpload.experience;
export const selectCategory = (state) => state.jobUpload.category;
export const selectIndustry = (state) => state.jobUpload.industry;
export const selectSalaryFrom = (state) => state.jobUpload.salaryFrom;
export const selectSalaryTo = (state) => state.jobUpload.salaryTo;
export const selectSalaryUnit = (state) => state.jobUpload.salaryUnit;
export const selectJobDescription = (state) => state.jobUpload.jobDescription;
export const selectJobRequirement = (state) => state.jobUpload.jobRequirement;
export const selectCompanyName = (state) => state.jobUpload.companyName;
export const selectCompanyDescription = (state) => state.jobUpload.companyDescription;
export const selectCompanyAdvantage = (state) => state.jobUpload.companyAdvantage;
export const selectLocation = (state) => state.jobUpload.location;
export const selectCurrentStep = (state) => state.jobUpload.currentStep;

export default jobUploadSlice.reducer;
