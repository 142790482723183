import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchTarget: '',
};

export const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    setSearchTarget: (state, action) => {
      state.searchTarget = action.payload;
    },
  },
});

export const { setSearchTarget } = communitySlice.actions;

export const selectSearchTarget = (state) => state.community.searchTarget;

export default communitySlice.reducer;
