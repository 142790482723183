import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component'; //无限滚动
import { fetchHomeslist } from '../../api/ApiRequest';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selecthouseId, sethouseId } from '../../Redux/slices/renthouseSlice';
import { getDateDiff } from '../../utils/getDateDiff';
import getCursor from '../../utils/getCursor';
import ListLoading from '../Loading/ListLoading';
import { Tag } from 'tdesign-react';

function Renthomelist({ method, category, price, location }) {
  // 根据状态渲染当前样式
  let active = useSelector(selecthouseId);

  // 发送jobId给redux，渲染对应的详细信息
  const dispatch = useDispatch();

  const handleclick = (houseid) => {
    dispatch(sethouseId(houseid));
  };

  // 从后端获取数据
  const [getData, setGetdata] = useState([]);
  const [hasmore, sethasmore] = useState(true);
  const [cursor, setCursor] = useState(getCursor());

  const fetchData = async (changeSearch, isFirstTime) => {
    let response = null;

    if (changeSearch) {
      setGetdata([]);
      const newCursor = getCursor();
      setCursor(newCursor);
      sethasmore(true);
      response = await fetchHomeslist(newCursor, method, category, price, location);
    } else {
      response = await fetchHomeslist(cursor, method, category, price, location);
    }

    if (response.data.info === 'no more houses') {
      if (isFirstTime) {
        dispatch(sethouseId(''));
      }
      sethasmore(false);
    } else {
      setGetdata((current) => [...current, ...response.data.data]);
      if (response.data.data.length < 10) sethasmore(false);
      setCursor(response.data.cursor);
      if (isFirstTime) {
        dispatch(sethouseId(response.data.data[0].houseid));
      }
    }
  };

  useEffect(() => {
    fetchData(true, true);
  }, [method, category, price, location]);

  return (
    <Container id="houseParent">
      {/* 滚动加载 */}
      <InfiniteScroll
        dataLength={getData.length} //This is important field to render the next
        next={() => fetchData(false, false)}
        hasMore={hasmore}
        loader={<ListLoading />}
        scrollableTarget="houseParent"
        endMessage={
          <div className=" tw-flex tw-items-center tw-justify-center" style={{ fontSize: '16px', height: '60px' }}>
            <b>-已经看到底了-</b>
          </div>
        }
      >
        {getData?.map((item) => {
          return (
            <JobsList
              key={item.houseid}
              onClick={() => handleclick(item.houseid)}
              className={active === item.houseid ? 'active' : ''}
            >
              <div className="icon">
                <img src={item.thumbnail} alt="" />
              </div>
              <div className="list_content">
                <div className="title">
                  <h4>{item.title}</h4>
                  <h2>
                    {item.priceunit !== '面议' && item.price}
                    {item.priceunit !== '面议' && '/'}
                    {item.priceunit}
                  </h2>
                  <h3>{item.housecategory}</h3>
                </div>

                {/* 标签管理 */}
                <div className="tag">
                  {/* 发布时间 */}
                  <Tag theme="warning" variant="outline" size="small">
                    {getDateDiff(item.createdtime)}
                  </Tag>
                  {/* 房源位置 */}
                  <Tag theme="warning" variant="outline" size="small">
                    {item.locationname}
                  </Tag>
                  {/* 出租方式 */}
                  <Tag theme="warning" variant="outline" size="small">
                    {item.housemethod}{' '}
                  </Tag>
                </div>
              </div>
            </JobsList>
          );
        })}
      </InfiniteScroll>
    </Container>
  );
}

const Container = styled.div`
  overflow-y: auto;
  height: 100%;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const JobsList = styled.div`
  display: flex;
  position: relative;
  padding-top: 10px;
  padding-left: 0.3125rem;
  cursor: pointer;
  border-right: 0.0625rem solid #e0e0e0;
  background-color: ${(props) => props.className === 'active' && 'rgba(255,193,7,0.25)'};
  .t-avatar {
    width: 40px;
  }
  .icon {
    width: 150px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
      max-height: 100%;
      max-width: 100%;
    }
  }
  .list_content {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .title {
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: #e37353;
    }
    h2 {
      font-size: 16px;
      margin-top: 10px;
      color: #000000e6;
    }
    h3 {
      font-size: 15px;
      margin-top: 8px;
    }
  }
  .tag {
    display: flex;
    margin-bottom: 5px;
    margin-top: 9px;

    gap: 8px;
    span {
      font-size: 13px;
    }
  }
  ::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1px;
    width: 99%;
    background-color: #e0e0e0;
  }
`;

export default Renthomelist;
