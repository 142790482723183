import React from "react";
import { useState, useEffect } from "react";
import {
  PostForumLike,
  PostForumUnlike,
  fetchForumList,
  fetchForumListLogin,
  fetchForumSearch,
} from "../../api/ApiRequest";
import styled from "styled-components";
import { HeartFilledIcon, HeartIcon } from "tdesign-icons-react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { selectSearchTarget } from "../../Redux/slices/communitySlice";
import { useSelector } from "react-redux";
import CommunityForumDetail from "./CommunityForumDetail";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import Avatar from "tdesign-react/es/avatar/Avatar";
import { Image } from "tdesign-react";
import getCursor from "../../utils/getCursor";
import ListLoading from "../Loading/ListLoading";
import { motion } from "framer-motion";
import { selectToken } from "../../Redux/slices/authSlice";

function CommunityContent() {
  let params = useLocation();
  const token = useSelector(selectToken);

  const { terms } = useParams();
  const navigate = useNavigate();

  const [getdata, setgetdata] = useState([]);
  const [cursor, setCursor] = useState(getCursor());
  const [hasmore, sethasmore] = useState(true);

  const searchTarget = useSelector(selectSearchTarget);

  // 向后端取推荐内容数据
  const fetchData = async (currentData, cursor, more) => {
    // 登录后
    if (token) {
      if (more === false) return;
      let response = null;
      if (params.pathname === "/community/forum" && !visible) {
        response = await fetchForumListLogin(cursor, token);
      } else if (params.state === "isSearch") {
        response = await fetchForumSearch(terms, cursor);
      }
      if (response) {
        if (response.data.info === "no more forums") {
          sethasmore(false);
        } else {
          setgetdata([...currentData, ...response.data.data]);
          if (response.data.data.length < 20) sethasmore(false);
          setCursor(response.data.cursor);
        }
      }
    }
    // 登录前
    else {
      if (more === false) return;
      let response = null;
      if (params.pathname === "/community/forum" && !visible) {
        response = await fetchForumList(cursor);
      } else if (params.state === "isSearch") {
        response = await fetchForumSearch(terms, cursor);
      }
      if (response) {
        if (response.data.info === "no more forums") {
          sethasmore(false);
        } else {
          setgetdata([...currentData, ...response.data.data]);
          if (response.data.data.length < 20) sethasmore(false);
          setCursor(response.data.cursor);
        }
      }
    }
  };

  useEffect(() => {
    fetchData([], getCursor(), true);
  }, [params.state, searchTarget]);

  // 向后端申请内容详细数据，跳出弹窗，路由跳转
  const [visible, setVisible] = useState(false);

  const handleClick = async (forumId, userId) => {
    navigate(forumId, { state: { forumId, userId } }, { status: "isForum" });
    setVisible(true);
  };

  const handleClose = () => {
    if (params.pathname !== "/community/forum" && !visible) {
      setVisible(false);
      navigate(-1);
    } else {
      setVisible(false);
      navigate(-1);
    }
  };

  // 点赞功能
  const handleLike = async (id, liked) => {
    if (token) {
      if (liked === "-1") {
        PostForumLike(id, token).then(() => {
          const newstate = [...getdata];
          found: for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].forumid === id) {
              newstate[index].liked = "1";
              newstate[index].likes += 1;
              break found;
            }
          }
          setgetdata(newstate);
        });
      } else if (liked !== "-1") {
        PostForumUnlike(id, token).then(() => {
          const newstate = [...getdata];
          found: for (let index = 0; index < newstate.length; index += 1) {
            if (newstate[index].forumid === id) {
              newstate[index].liked = "-1";
              newstate[index].likes -= 1;
              break found;
            }
          }
          setgetdata(newstate);
        });
      }
    } else navigate("/login");
  };

  // 根据不同屏幕，渲染不同数量的瀑布流列表
  const breakpointColumnsObj = {
    default: 5,
    1420: 4,
    1192: 3,
    690: 2,
  };

  return (
    <Bigbox id="communityContent">
      <InfiniteScroll
        dataLength={getdata.length}
        next={() => fetchData(getdata, cursor, hasmore)}
        hasMore={hasmore}
        scrollableTarget="communityContent"
        loader={<ListLoading className="tw-self-center" />}
        endMessage={
          <p className="tw-text-center tw-text-xl tw-mb-2">
            <b>🚧 到底了哟 🚧</b>
          </p>
        }
        style={{ flex: 1 }}
      >
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="react-waterfall-grid-comps"
        >
          {getdata.map((item) => {
            return (
              <motion.div
                key={item.forumid}
                initial={{ y: 15, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 1.5, type: "spring" }}
              >
                <li>
                  <Listbox>
                    <Image
                      src={item.thumbnail}
                      overlayTrigger="hover"
                      overlayContent={
                        <div
                          style={{
                            background: "rgba(0,0,0,.4)",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: 8,
                          }}
                        ></div>
                      }
                      onClick={() => handleClick(item.forumid, item.userid)}
                    />
                    {/* 用户信息 */}
                    <Footer>
                      <span
                        className="title tw-cursor-pointer"
                        onClick={() => handleClick(item.forumid, item.userid)}
                      >
                        {item.title}
                      </span>

                      <Userbox>
                        {/* 用户头像和用户名 */}
                        <div className="avatar tw-cursor-pointer">
                          <Link
                            target="_blank"
                            to={"/profile/userInfo/" + item.userid}
                          >
                            <Avatar
                              image={item.avatar}
                              size="22px"
                              style={{ marginRight: "8px" }}
                            >
                              {item.avatar === "" && item.username.slice(0, 1)}
                            </Avatar>
                            <span>{item.username}</span>
                          </Link>
                        </div>

                        <div
                          className="like tw-cursor-pointer"
                          onClick={() => {
                            handleLike(item.forumid, item.liked);
                          }}
                        >
                          {item.liked === "-1" ? (
                            <HeartIcon
                              size="1.3rem"
                              style={{
                                marginRight: "5px",
                              }}
                            />
                          ) : (
                            <HeartFilledIcon
                              size="1.3rem"
                              style={{
                                marginRight: "5px",
                                color:
                                  token && item.liked !== "-1" ? "#ff2442" : "",
                              }}
                            />
                          )}
                          <span>{item.likes}</span>
                        </div>
                      </Userbox>
                    </Footer>
                  </Listbox>
                </li>
              </motion.div>
            );
          })}
        </Masonry>
      </InfiniteScroll>
      {/* 弹出对话框 */}

      {params.pathname !== "/community/forum" &&
        params.state !== "isSearch" && (
          <CommunityForumDetail
            visible={params.pathname !== "/community/forum" ? true : visible}
            onClose={handleClose}
          />
        )}
    </Bigbox>
  );
}

const Bigbox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-horizontal: 20px;
  padding-top: 5px;

  .react-waterfall-grid-comps {
    width: 100%;
    display: flex;
  }

  .react-waterfall-grid-comps li > div {
    border-radius: 8px;
    font-size: 15px;
    overflow: hidden;
    color: #151515;
    margin-bottom: 20px;
    padding: 6px;
    background: rgb(255, 255, 255);
  }

  .react-waterfall-grid-comps li > div > div > img {
    width: 100%;
    border-radius: 8px;
  }
`;

const Listbox = styled.div`
  .avatar {
    display: flex;
    height: 1.25rem;
    align-items: center;
    span {
      font-size: 14px;
    }
  }
`;

const Footer = styled.div`
  padding: 12px;
`;

const Userbox = styled.div`
  display: flex;
  margin-top: 0.5rem;
  justify-content: space-between;
  align-items: center;
  a {
    color: #666;
    :hover {
      color: #333;
    }
  }
  .like {
    display: flex;
    align-items: center;
  }
`;

export default CommunityContent;
