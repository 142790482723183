import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Avatar, Pagination, Tag } from "tdesign-react";
import { fetchUserCollectJob, uncollectJob } from "../../../api/ApiRequest";
import { selectToken, selectUserId } from "../../../Redux/slices/authSlice";

import { DeleteIcon } from "tdesign-icons-react";
import moment from "moment";
import { useOutletContext, useParams } from "react-router-dom";
import UserJobDetail from "../UserJobDetail";

function Jobsave() {
  const token = useSelector(selectToken);
  const myId = useSelector(selectUserId);

  let params = useParams();

  //   获取工作收藏的总数
  const [collectnumber] = useOutletContext();

  // 获取收藏的工作
  const [getcollectJob, setCollectJob] = useState([]);
  const [pageIndex, setpageIndex] = useState(1);
  const fetchJob = async () => {
    const response = await fetchUserCollectJob(pageIndex, params.userid);
    setCollectJob([...response.data.data]);
  };

  // 取消收藏
  const [trigger, setTrigger] = useState("");
  const handleDelete = async (event, jobid) => {
    event.preventDefault();
    await uncollectJob(jobid, token).then(() => {
      setTrigger(jobid);
    });
  };

  // 判断个人页面工作招聘弹框是否显示
  const [jobvisible, setJobVisible] = useState(false);
  const [targetForumId, setTargetForumId] = useState(null);

  const handleJobVisible = (Id) => {
    setTargetForumId(Id);
    setJobVisible(true);
  };

  const handleJobClose = () => {
    if (jobvisible) {
      setJobVisible(false);
    }
  };

  useEffect(() => {
    fetchJob();
  }, [pageIndex, trigger]); //eslint-disable-line
  return (
    <div>
      <SaveBox>
        {getcollectJob.map((items, index) => {
          return (
            <li key={index} onClick={() => handleJobVisible(items.jobid)}>
              <div className="header">
                {/* 头像 */}
                <div className="icon">
                  <Avatar shape="round" size="50px" image={items.avatar}>
                    {/* {item.avatar === '' ? defaulticon : item.avatar} */}
                  </Avatar>
                </div>
                <div className="companyname">
                  {/* 公司名称 */}
                  <h4>{items.companytitle}</h4>
                </div>
              </div>
              <div className="title">{items.title}</div>
              {/* 标签 */}
              <div className="tw-mt-5">
                <Tag style={{ marginRight: "5px" }}>{items.locationname}</Tag>
                <Tag style={{ marginRight: "5px" }}>
                  {items.jobcategoryname}
                </Tag>
                <Tag>经验:{items.experience}</Tag>

                {params.userid === myId && (
                  <div className="time tw-flex tw-justify-between tw-mt-2">
                    <div>
                      收藏于：
                      {moment(items.collecttime * 1000).format("DD-MM-YYYY")}
                    </div>
                    <span>
                      <DeleteIcon
                        size="18px"
                        onClick={(e) => {
                          handleDelete(e, items.newsid);
                        }}
                      />
                    </span>
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </SaveBox>

      {/* 分页查询按钮 */}
      {collectnumber.jobscollectionnum > 12 && (
        <Pagination
          current={pageIndex}
          foldedMaxPageBtn={5}
          pageEllipsisMode="mid"
          showFirstAndLastPageBtn={false}
          showJumper
          showPageNumber
          showPageSize={false}
          showPreviousAndNextBtn
          size="medium"
          theme="default"
          total={collectnumber.jobscollectionnum}
          totalContent={false}
          onCurrentChange={(index) => {
            // console.log(index);
            setpageIndex(index);
          }}
        />
      )}
      {/* 弹框显示 */}
      {jobvisible && (
        <UserJobDetail
          visible={jobvisible}
          handleClose={handleJobClose}
          Id={targetForumId}
        />
      )}
    </div>
  );
}

const SaveBox = styled.ul`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  li {
    height: 250px;
    width: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 10px;
    border-radius: 8px;
    /* font-size: 18px; */
    overflow: hidden;
    color: #212121;
    margin-bottom: 20px;
    padding: 10px;
    background: rgb(255, 255, 255);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s;

    :hover {
      /* box-shadow: 0 19px 39px 0 rgb(0 0 0 / 20%); */
      cursor: pointer;
      transform: translateY(-6px);
      box-shadow: 0 30px 50px rgba(0, 0, 0, 0.3);
      transition: all 0.3s;
    }
    .header {
      display: flex;
      margin-bottom: 10px;
      .companyname {
        width: 100%;
        text-align: center;
        h4 {
          font-size: 18px;
          font-weight: 500;
          line-height: 50px;
        }
      }
    }
    .title {
      height: 60px;
      display: flex;
      font-size: 16px;
      align-items: center;
    }
    .time {
      font-size: 13px;
      padding-left: 2px;
    }
    :nth-child(4n) {
      margin-right: 0;
      @media (max-width: 1420px) {
        margin-right: 10px;
      }
    }
  }
`;
export default Jobsave;
