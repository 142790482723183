import React, { useEffect } from "react";
import { Avatar, Button, Comment, Textarea } from "tdesign-react";
import { HeartFilledIcon, ChatIcon } from "tdesign-icons-react";
import styled from "styled-components";
import {
  checkNewsCommentStatus,
  fetchNewsAllReply,
  PostNewsreply,
  PostNewsSecondreply,
  PutLikeComment,
  PutUnlikeComment,
} from "../../api/ApiRequest";
import { useSelector } from "react-redux";
import { useState } from "react";
import { selectToken, selectUserId } from "../../Redux/slices/authSlice";
import Secondlycomment from "./Secondlycomment";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { getDateDiff } from "../../utils/getDateDiff";
import ListLoading from "../Loading/ListLoading";
import getCursor from "../../utils/getCursor";
import { BiUser } from "react-icons/bi";

function ReplyComment({ newsId }) {
  const token = useSelector(selectToken);
  const userid = useSelector(selectUserId);

  const [cursor, setCursor] = useState(getCursor());
  const newCommentRef = React.useRef(null);

  // 从后端获取现有的一级评论
  const [Allreply, setAllreply] = useState([]);
  const [hasmore, sethasmore] = useState(true);

  const fetchData = async () => {
    if (newsId !== "") {
      const response = await fetchNewsAllReply(newsId, cursor);
      if (response.data.info === "no more comments") {
        sethasmore(false);
        setCursor(response.data.cursor);
      } else {
        if (response.data.data.length < 10) sethasmore(false);
        setAllreply((current) => [...current, ...response.data.data]);
        setCursor(response.data.cursor);
      }
    }
  };

  // 登录后从后端获取现有的一级评论
  const commentStatus = async () => {
    if (newsId) {
      const response = await checkNewsCommentStatus(newsId, token, cursor);
      console.log(response);
      if (response.data.info === "no more comments") {
        sethasmore(false);
        setCursor(response.data.cursor);
      } else {
        if (response.data.data.length < 10) sethasmore(false);
        setAllreply((current) => [...current, ...response.data.data]);
        setCursor(response.data.cursor);
      }
    }
  };

  // 发布一级评论
  const [content, setcontent] = useState("");
  const handleSubmit = async () => {
    if (content !== "") {
      const newComment = await PostNewsreply(newsId, content, token);
      setAllreply((current) => [newComment.data.data, ...current]);
      setcontent("");
    } else {
      alert("内容不能为空");
    }
  };

  // 发布二级评论
  const [secondcontent, setSecondcontent] = useState("");

  const handleSecondlSubmit = async (commentId, receiver, replytofirst) => {
    if (secondcontent !== "") {
      await PostNewsSecondreply(
        commentId,
        secondcontent,
        receiver,
        replytofirst,
        token
      ).then(() => {
        setSecondcontent("");
        setExpandReply(undefined);
      });
    } else {
      alert("内容不能为空");
    }
  };

  // 显示更多回复
  const [expendbutton, setExpandbutton] = useState([]);
  const handleMoreReply = (id) => {
    if (expendbutton.includes(id)) {
      setExpandbutton((current) => current.filter((item) => item !== id));
    } else {
      setExpandbutton((current) => [...current, id]);
    }
  };

  // 回复框功能
  const [expendReply, setExpandReply] = useState(undefined);
  const handlereply = (id) => {
    if (token) {
      if (expendReply === id) {
        setExpandReply(undefined);
      } else {
        setExpandReply(id);
      }
    } else {
      navigate("/login");
    }
  };

  // 点赞功能
  const [likebutton, setLikebutton] = useState([]);
  const [unlikebutton, setUnLikebutton] = useState([]);

  let navigate = useNavigate();
  // 点赞function
  const handleLike = (id, liked, creator) => {
    if (token) {
      if (likebutton.includes(id) || liked !== "-1") {
        if (!unlikebutton.includes(id)) {
          PutUnlikeComment(id, token).then(() => {
            setUnLikebutton((current) => [...current, id]);
            setLikebutton((current) => current.filter((item) => item !== id));
          });
        } else {
          PutLikeComment(id, token, creator)
            .then(() => {
              setLikebutton((current) => [...current, id]);
              setUnLikebutton((current) =>
                current.filter((item) => item !== id)
              );
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        PutLikeComment(id, token, creator)
          .then(() => {
            setLikebutton((current) => [...current, id]);
            setUnLikebutton((current) => current.filter((item) => item !== id));
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else navigate("/login");
  };

  return (
    <div>
      <Publish>
        <div>
          <Avatar
            icon={<BiUser />}
            size="48px"
            shape="circle"
            style={{ marginRight: "var(--td-comp-margin-xxl)" }}
          ></Avatar>
        </div>

        <div
          className="textinput tw-flex-1 tw-relative "
          style={content === "" ? { height: "100px" } : { height: "150px" }}
          onClick={() => newCommentRef.current.focus()}
        >
          <textarea
            placeholder="我来说两句"
            onChange={(e) => setcontent(e.target.value)}
            value={content}
            ref={newCommentRef}
          />
          <Button
            className="tw-absolute tw-right-3 tw-bottom-3"
            theme={content === "" ? "default" : "warning"}
            disabled={content === "" ? true : false}
            onClick={handleSubmit}
          >
            发布评论
          </Button>
        </div>
      </Publish>

      {/* 登陆后遍历帖子评论 */}
      {token && (
        <div>
          <InfiniteScroll
            dataLength={Allreply.length} //This is important field to render the next
            next={commentStatus}
            hasMore={hasmore}
            loader={<ListLoading />}
          >
            {Allreply?.map((item) => {
              return (
                <CommentList key={item.commentid}>
                  <Comment
                    avatar={
                      <Link
                        target="_blank"
                        to={"/profile/userInfo/" + item.userid}
                        key={item.userid}
                      >
                        <Avatar size="48px" image={item.avatar}>
                          {item.avatar === "" && item.username.slice(0, 1)}
                        </Avatar>
                      </Link>
                    }
                    author={item.username}
                    datetime={getDateDiff(item.createdtime)}
                    content={<div>{item.content}</div>}
                    actions={[
                      // 点赞功能
                      <div
                        key="ThumbUp"
                        onClick={() =>
                          handleLike(item.commentid, item.liked, item.userid)
                        }
                      >
                        <HeartFilledIcon
                          size="1.3rem"
                          style={{
                            marginRight: "5px",
                            color:
                              (token &&
                                item.liked !== "-1" &&
                                !unlikebutton.includes(item.commentid) &&
                                `#ff2442`) ||
                              (likebutton.includes(item.commentid) &&
                                `#ff2442`) ||
                              (unlikebutton.includes(item.commentid) && ""),
                          }}
                        />
                        <span>
                          {likebutton.includes(item.commentid) &&
                            (item.liked !== "-1" ? item.likes : item.likes + 1)}
                          {unlikebutton.includes(item.commentid) &&
                            (item.likes >= 1 ? item.likes - 1 : item.likes)}
                          {!unlikebutton.includes(item.commentid) &&
                            !likebutton.includes(item.commentid) &&
                            item.likes}
                        </span>
                      </div>,

                      <div
                        key="Chat"
                        onClick={() => handlereply(item.commentid)}
                      >
                        <ChatIcon size="16px" />
                        <span>回复</span>
                      </div>,
                    ]}
                    reply={
                      item.replies.length > 0 && (
                        <Secondlycomment
                          initialData={item.replies}
                          totalNum={item.replynumber}
                          commentId={item.commentid}
                          token={token}
                          needToExpend={expendReply}
                          creator={item.userid}
                          timeStamp={item.replyTimeStamp}
                        />
                      )
                    }
                    key={item.commentid}
                  />

                  {expendReply === item.commentid &&
                    item.replies.length <= 0 && (
                      <Publish>
                        <div
                          className="textinput tw-flex-1 tw-relative tw-ml-20 tw-mt-4"
                          style={
                            secondcontent === ""
                              ? { height: "100px" }
                              : { height: "150px" }
                          }
                        >
                          <textarea
                            placeholder="我来说两句"
                            onChange={(e) => setSecondcontent(e.target.value)}
                          />
                          <Button
                            className="tw-absolute tw-right-3 tw-bottom-3"
                            theme={secondcontent === "" ? "default" : "warning"}
                            disabled={secondcontent === "" ? true : false}
                            onClick={() =>
                              handleSecondlSubmit(
                                item.commentid,
                                item.userid,
                                true
                              )
                            }
                          >
                            发布评论
                          </Button>
                        </div>
                      </Publish>
                    )}
                </CommentList>
              );
            })}
          </InfiniteScroll>
        </div>
      )}

      {/* 未登陆时遍历帖子评论 */}
      {!token && (
        <div>
          <InfiniteScroll
            dataLength={Allreply.length} //This is important field to render the next
            next={fetchData}
            hasMore={hasmore}
            loader={<ListLoading />}
          >
            {Allreply?.map((item) => {
              return (
                <CommentList key={item.commentid}>
                  <Comment
                    avatar={
                      <Link
                        target="_blank"
                        to={
                          userid === item.userid
                            ? "/userpage"
                            : "/profile/userInfo/" + item.userid
                        }
                        key={item.userid}
                      >
                        <Avatar size="48px" image={item.avatar}>
                          {item.avatar === "" && item.username.slice(0, 1)}
                        </Avatar>
                      </Link>
                    }
                    author={item.username}
                    datetime={getDateDiff(item.createdtime)}
                    content={<div>{item.content}</div>}
                    actions={[
                      <div
                        className={item.replynumber > 0 ? "" : "abc"}
                        onClick={() => handleMoreReply(item.commentid)}
                      >
                        {item.replynumber > 0 &&
                          `查看全部${item.replynumber}条回复`}
                      </div>,
                      // 点赞功能
                      <div
                        key="ThumbUp"
                        onClick={() =>
                          handleLike(item.commentid, item.liked, item.userid)
                        }
                      >
                        <HeartFilledIcon
                          size="1.3rem"
                          style={{
                            marginRight: "5px",
                          }}
                        />
                        <span>{item.likes}</span>
                      </div>,
                      <div
                        key="Chat"
                        onClick={() => handlereply(item.commentid)}
                      >
                        <ChatIcon size="16px" />
                        <span>回复</span>
                      </div>,
                    ]}
                    reply={
                      expendbutton.includes(item.commentid) &&
                      item.replynumber > 0 && (
                        <Secondlycomment
                          commentId={item.commentid}
                          token={token}
                        />
                      )
                    }
                    key={item.commentid}
                  />
                </CommentList>
              );
            })}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
}

const Publish = styled.div`
  display: flex;
  height: 100%;
  margin-bottom: 20px;
  .textinput {
    border: 1px solid #ddd;
    padding: 16px 14px 0 16px;
    border-radius: 6px;
  }
  textarea {
    resize: none;
    width: 100%;
    height: 60%;
    position: relative;
    font-size: 16px;
    outline: none;
  }
  .t-is-disabled {
    cursor: default;
  }
`;

const CommentList = styled.div`
  margin-top: 40px;
  position: relative;
`;

const ReplyBox = styled.div`
  display: flex;
  height: 4.875rem;
  margin-top: var(--td-comp-margin-l);
  margin-left: calc(var(--td-comp-size-xxxl) + var(--td-comp-margin-xxl));
  border-radius: var(--td-radius-medium);
  button {
    height: 100%;
  }
`;

export default ReplyComment;
