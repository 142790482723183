import React from "react";
import TopNews from "../category/TopNews";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentTag } from "../../../../../Redux/slices/homeSlice";

function Content() {
  const currentTag = useSelector(selectCurrentTag);

  return currentTag !== 1 ? <Outlet /> : <TopNews />;
}

export default Content;
