import React from 'react';
import styled from 'styled-components';
import { Button } from 'tdesign-react';

function NewsCategory({ active, handleChange }) {
  return (
    <Sub>
      <ul className="sub_button">
        <li>
          <Button
            shape="round"
            size="medium"
            type="button"
            variant="base"
            theme={active === 1 ? 'warning' : 'default'}
            onClick={() => handleChange(1)}
          >
            头条
          </Button>
        </li>
        <li>
          <Button
            shape="round"
            size="medium"
            variant="base"
            theme={active === 2 ? 'warning' : 'default'}
            onClick={() => handleChange(2)}
          >
            澳洲
          </Button>
        </li>
        <li>
          <Button
            shape="round"
            size="medium"
            variant="base"
            theme={active === 3 ? 'warning' : 'default'}
            onClick={() => handleChange(3)}
          >
            国际
          </Button>
        </li>
        <li>
          <Button
            shape="round"
            size="medium"
            variant="base"
            theme={active === 4 ? 'warning' : 'default'}
            onClick={() => handleChange(4)}
          >
            国内
          </Button>
        </li>
        <li>
          <Button
            shape="round"
            size="medium"
            variant="base"
            theme={active === 5 ? 'warning' : 'default'}
            onClick={() => handleChange(5)}
          >
            财经
          </Button>
        </li>
        <li>
          <Button
            shape="round"
            size="medium"
            variant="base"
            theme={active === 6 ? 'warning' : 'default'}
            onClick={() => handleChange(6)}
          >
            娱乐
          </Button>
        </li>
        <li>
          <Button
            shape="round"
            size="medium"
            variant="base"
            theme={active === 7 ? 'warning' : 'default'}
            onClick={() => handleChange(7)}
          >
            折扣
          </Button>
        </li>
      </ul>
    </Sub>
  );
}

const Sub = styled.div`
  min-height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  .sub_button li {
    cursor: pointerr;
    flex: 0 0 auto;
  }
  .sub_button {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
  }
`;

export default NewsCategory;
