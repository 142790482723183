import React from "react";
import styled from "styled-components";
import CustomizeInput from "../../components/Auth/CustomizeInput";
import { apiCall } from "../../api/ApiRequest";
import { useNavigate } from "react-router-dom";
import LoadingBtn from "../../components/Home/LoadingBtn";
import {
  setAvatar,
  setDob,
  setFollowers,
  setFollowing,
  setIsLogin,
  setLocation,
  setSignature,
  setToken,
  setUserId,
  setUserName,
} from "../../Redux/slices/authSlice";
import { useDispatch } from "react-redux";
import Background from "../../components/Auth/Background";
import Share from "../../img/share2.svg";
import WeChatLogo from "../../img/wechat.png";
import { checkEmail, checkLoginPassword } from "../../utils/loginChecking";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Email state
  const [email, setEmail] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(true);
  const [emailError, setEmailError] = React.useState("");

  // Password state
  const [password, setPassword] = React.useState("");
  const [validPassword, setValidPassword] = React.useState(true);
  const [passwordError, setPasswordError] = React.useState("");

  // Track the request status
  const [secondRequest, setSecondRequest] = React.useState(false);

  // Loading state
  const [loading, setLoading] = React.useState(false);

  // Input checking function
  const inputChecking = () => {
    const { emailStatus, emailInfo } = checkEmail(email);
    if (!emailStatus) {
      setValidEmail(false);
      setEmailError(emailInfo);
    } else {
      setValidEmail(true);
      setEmailError("");
    }
  };

  React.useEffect(() => {
    if (secondRequest) {
      inputChecking();
    }
  }, [email]);

  // 提交数据
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { emailStatus, emailInfo } = checkEmail(email);
    const { passwordStatus, passwordInfo } = checkLoginPassword(password);

    if (!emailStatus) {
      setValidEmail(false);
      setEmailError(emailInfo);
      setSecondRequest(true);
    } else {
      setValidEmail(true);
      setEmailError("");
    }

    if (!passwordStatus) {
      setValidPassword(false);
      setPasswordError(passwordInfo);
      setSecondRequest(true);
    } else {
      setValidPassword(true);
      setPasswordError("");
    }
    if (emailStatus && passwordStatus) {
      setLoading(true);

      try {
        const response = await apiCall.post("/auth/login", { email, password });
        dispatch(setIsLogin(true));
        dispatch(setToken(response.data.data.token));
        dispatch(setAvatar(response.data.data.avatar));
        dispatch(setUserName(response.data.data.userName));
        dispatch(setSignature(response.data.data.signature));
        dispatch(setDob(response.data.data.dob));
        dispatch(setLocation(response.data.data.location));
        dispatch(setUserId(response.data.data.userId));
        dispatch(setFollowers(response.data.data.followers));
        dispatch(setFollowing(response.data.data.following));
        setLoading(false);
        navigate(-1);
      } catch (error) {
        setSecondRequest(true);
        if (error.response.data.info === "please confirm your email") {
          setLoading(false);
          setValidEmail(false);
          setEmailError("邮箱错误或请先验证邮箱");
          return;
        } else {
          setLoading(false);
          setValidPassword(false);
          setPasswordError("输入的邮箱或密码不正确");
          setValidEmail(false);
          setEmailError("输入的邮箱或密码不正确");
          return;
        }
      }
    }
  };

  // 主体部分
  return (
    <Background>
      <Main>
        <div className="tw-flex tw-justify-center tw-flex-1 tw-h-full">
          {/* <AuthLogo /> */}
          <img src={Share} alt="" />
        </div>

        <div className="tw-flex-1 tw-h-full tw-border-l-2 tw-border-l-white tw-p-3">
          <form action="" className="tw-flex tw-flex-col">
            <h3 className="tw-text-5xl tw-mb-4 tw-text-white tw-self-start tw-mt-4 tw-font-bold">
              登录
            </h3>
            {/* 用户邮箱 */}
            <CustomizeInput
              title="邮箱地址"
              detail={{ type: "text", placeholder: "example@email.com" }}
              checkValid={validEmail}
              setState={setEmail}
              errorDetail={emailError}
              marginTop="tw-mt-3"
              TextColor="text-white"
            />

            {/* 密码   */}
            <CustomizeInput
              title="密码"
              detail={{ type: "password", placeholder: "请输入密码" }}
              checkValid={validPassword}
              setState={setPassword}
              errorDetail={passwordError}
              marginTop="tw-mt-3"
              TextColor="text-white"
            />

            <div
              className="tw-w-fit tw-self-end tw-text-base tw-cursor-pointer tw-text-white tw-underline"
              onClick={() => navigate("/register")}
            >
              还没有账号? 立即注册
            </div>

            <LoadingBtn
              title={"登录"}
              loading={loading}
              onClick={handleSubmit}
            />
            <div className="tw-border-t-2 tw-mt-6"></div>
            <div className="tw-text-white tw-text-center tw-text-base tw-mt-2 tw-mb-1">
              使用第三方账号登录
            </div>
            <button
              className="tw-w-full tw-rounded-md  tw-text-white tw-font-bold tw-text-base tw-p-1 tw-flex tw-justify-center tw-items-center tw-gap-p tw-mt-2"
              style={{ backgroundColor: "#3AB106" }}
            >
              <img
                style={{
                  width: 25,
                  height: 25,
                  objectFit: "contain",
                  color: "white",
                }}
                width={25}
                height={25}
                src={WeChatLogo}
              />
              <div>微信登录</div>
            </button>
          </form>
        </div>
      </Main>
    </Background>
  );
}

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60vw;
  padding: 15px;
  min-height: fit-content;
  height: 80vh;
  background-color: rgb(15, 11, 5, 0.8);
  position: relative;
  border-radius: 3rem;
`;

export default LoginPage;
