import React from "react";
import Category from "./mainContainer/category/Category";
import Content from "./mainContainer/righside/Content";

function MainContainer() {
  return (
    <div className="tw-w-screen tw-flex-1 tw-flex tw-overflow-auto">
      <Category />
      <Content />
    </div>
  );
}

export default MainContainer;
