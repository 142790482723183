import React from "react";
import styled from "styled-components";
import { Select, Space } from "tdesign-react";
import { Button } from "tdesign-react";
import { useState } from "react";
import JobDeatil from "./seekjob/JobDeatil";
import JobList from "./seekjob/JobList";
import { fetchSydlocation } from "../../../../../api/ApiRequest";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLogin } from "../../../../../Redux/slices/authSlice";
import {
  selectLocations,
  setLocations,
} from "../../../../../Redux/slices/filterSlice";

const { Option } = Select;

function SeekJob() {
  // 职业类型状态和函数
  const [category, setCategory] = useState("");
  const onChangeCategory = (value) => {
    setCategory(value);
  };

  // 工作经验状态和函数
  const [experience, setExperience] = useState("");
  const onChangeExperience = (value) => {
    setExperience(value);
  };

  // 行业状态和函数
  const [industry, setIndustry] = useState([]);
  const onChangeIndustry = (value) => {
    setIndustry(value);
  };

  // 地区状态和函数
  const [location, setLocation] = useState("");
  const onChangeLocation = (value) => {
    setLocation(value);
  };

  const dispatch = useDispatch();
  const locations = useSelector(selectLocations);
  const isLogin = useSelector(selectIsLogin);

  // 从后端获取数据
  const fetchData = async () => {
    if (locations?.length !== 0) {
      return;
    }
    const response = await fetchSydlocation();
    dispatch(setLocations(response.data.data));
  };

  return (
    <Seek>
      {/* 职业类型搜索框 */}
      <Jobsearch>
        <Space style={{ width: "100%" }}>
          {/* 职业类型 */}
          <Select
            value={category}
            placeholder="工作类型"
            onChange={onChangeCategory}
            Option
            popupProps={{
              overlayStyle: {
                position: "absolute",
                top: "0px",
                bottom: "0px",
              },
            }}
            options={[
              { label: "不限", value: "6" },
              { label: "全职", value: "1" },
              { label: "兼职", value: "2" },
              { label: "临时工", value: "3" },
              { label: "合同工", value: "4" },
              { label: "实习", value: "5" },
            ]}
          />

          {/* 工作经验选择框 */}
          <Select
            value={experience}
            placeholder="经验要求"
            onChange={onChangeExperience}
            popupProps={{
              overlayStyle: {
                position: "absolute",
                top: "0px",
                bottom: "0px",
              },
            }}
            options={[
              { label: "不限", value: "3" },
              { label: "需要", value: "1", key: 5 },
              { label: "不需要", value: "2", key: 4 },
            ]}
          />

          {/* 工作行业多选框 */}
          <Select
            value={industry}
            placeholder="招聘行业"
            onChange={onChangeIndustry}
            autoWidt={true}
            multiple
            minCollapsedNum={1}
            popupProps={{
              overlayStyle: {
                position: "absolute",
                top: "0px",
                bottom: "0px",
              },
              overlayClassName: { "t-select__dropdown": "" },
            }}
            // style={{ width: "245px" }}
          >
            <Option label="所有职业" checkAll value="16" />
            <Option key="1" label="互联网/通信" value="1" />
            <Option key="2" label="传媒/艺术/设计" value="2" />
            <Option key="3" label="财务/人力/行政" value="3" />
            <Option key="4" label="销售/市场/业务" value="4" />
            <Option key="5" label="建筑/土木工程/施工员" value="5" />
            <Option key="6" label="技工/工人/学徒" value="6" />
            <Option key="7" label="厨师/服务员/帮工" value="7" />
            <Option key="8" label="医护/按摩/美容/发型" value="8" />
            <Option key="9" label="清洁/搬运/库管/司机" value="9" />
            <Option key="10" label="网管/IT安装/程序员" value="10" />
            <Option key="11" label="保姆/月嫂/钟点工" value="11" />
            <Option key="12" label="店员/收银/客服" value="12" />
            <Option key="13" label="教师/教练/家教" value="13" />
            <Option key="14" label="旅行/导游" value="14" />
            <Option key="15" label="编辑/翻译/律师" value="15" />
          </Select>

          {/* 工作地区多选框 */}
          <div onClick={fetchData}>
            <Select
              minCollapsedNum={1}
              placeholder="-请选择地区-"
              filterable
              popupProps={{
                overlayStyle: {
                  position: "absolute",
                  top: "0px",
                  bottom: "0px",
                },
              }}
              onChange={onChangeLocation}
            >
              <Option label="所有地区" value={"700"} />
              {locations?.map((item) => (
                <Option
                  label={item.locationname}
                  key={item.locationid}
                  value={item.locationname}
                />
              ))}
            </Select>
          </div>

          <Button
            shape="rectange"
            size="medium"
            type="button"
            variant="base"
            theme={"warning"}
            onClick={() => window.open("/jobUpload", "_blank")}
          >
            {isLogin ? "免费发布招聘信息" : "登录发布招聘信息"}
          </Button>
        </Space>
      </Jobsearch>

      <div className="job_container">
        {/* 职业标题选择 */}
        <Left id="Jobparent">
          <JobList
            category={category}
            experience={experience}
            industry={industry}
            location={location}
          />
        </Left>
        {/* 粘性定位，固定右边盒子不动 */}
        <Right>
          <JobDeatil />
        </Right>
      </div>
    </Seek>
  );
}

const Seek = styled.div`
  margin: 0 auto;
  padding-top: 10px;
  overflow: hidden;

  /* 适配不同屏幕 */

  @media (max-width: 1200px) {
    width: 850px;
  }
  @media (min-width: 1400px) {
    width: 1100px;
  }
  .job_container {
    display: flex;
    height: calc(100vh - 150px);
    border: 1px solid #e0e0e0;
    border-radius: 0.625rem;
    border-left: none;
  }
`;

const Jobsearch = styled.div`
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.04);
  .search {
    background-color: orange;
  }
  .t-space-item:nth-child(5) {
    align-self: flex-end;
    width: auto;
  }
`;

const Left = styled.div`
  flex: 1.5;
  overflow-y: scroll;
  .left_items {
    height: 135px;
    background-color: skyblue;
    border: 1px solid #fff;
    cursor: pointer;
  }
  .page {
    width: 500px;
    margin-left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
    .t-pagination__pager {
      display: flex;
      justify-content: center;
      width: 100px;
    }
  }
`;

const Right = styled.div`
  overflow-y: auto;
  flex: 2;
  max-width: 607px;
  width: 592px;
  position: relative;
  top: 0;
  overflow-y: scroll;
`;
export default SeekJob;
