import React from "react";
import FormItem from "tdesign-react/es/form/FormItem";
import Input from "tdesign-react/es/input/Input";
import { Select, Radio, Space, DatePicker } from "tdesign-react";
import { useDispatch, useSelector } from "react-redux";
import { selectLocations, setLocations } from "../../Redux/slices/filterSlice";
import { fetchSydlocation } from "../../api/ApiRequest";
import Option from "tdesign-react/es/select/base/Option";

import {
  selectCheckinTime,
  selectHousetype,
  selectHouseunit,
  selectLocation,
  selectMinimumperiod,
  selectOrigin,
  selectPriceweek,
  selectRentmethod,
  selectTitle,
  setcheckinTime,
  setHousetype,
  setHouseunit,
  setLocation,
  setMinimumperiod,
  setOrigin,
  setPriceweek,
  setRentmethod,
  setTitle,
} from "../../Redux/slices/rentUploadSlice";

function Step1() {
  const locations = useSelector(selectLocations);
  const priceweek = useSelector(selectPriceweek);
  const dispatch = useDispatch();

  const fetchLocations = async () => {
    if (locations?.length !== 0) {
      return;
    }
    const response = await fetchSydlocation();
    dispatch(setLocations(response.data.data));
  };

  React.useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <>
      <FormItem
        initialData={useSelector(selectTitle)}
        className="tw-w-5/6 tw-font-bold"
        style={{ width: "50vw" }}
        label="房子标题"
        name="HouseTitle"
        shouldUpdate={false}
        successBorder={false}
        rules={[{ required: true, message: "请填写标题", type: "error" }]}
      >
        <Input
          align="left"
          allowInputOverMax={false}
          autoWidth={false}
          autofocus={true}
          maxlength={100}
          placeholder="请输入租房标题"
          showLimitNumber={true}
          onChange={(value) => dispatch(setTitle(value))}
          size="medium"
          type="text"
        />
      </FormItem>
      <div className="tw-flex tw-justify-center">
        {/* 出租方式 */}
        <FormItem
          className="tw-font-bold tw-flex-1"
          label="出租方式"
          name="rentmethod"
          requiredMark={false}
        >
          <div>
            <Space direction="vertical">
              <Radio.Group
                variant="primary-filled"
                defaultValue={useSelector(selectRentmethod)}
              >
                <Radio.Button
                  value="4"
                  onChange={() => dispatch(setRentmethod(4))}
                >
                  不限
                </Radio.Button>
                <Radio.Button
                  value="1"
                  onChange={() => dispatch(setRentmethod(1))}
                >
                  整租
                </Radio.Button>
                <Radio.Button
                  value="2"
                  onChange={() => dispatch(setRentmethod(2))}
                >
                  单间
                </Radio.Button>
                <Radio.Button
                  value="3"
                  onChange={() => dispatch(setRentmethod(3))}
                >
                  客厅
                </Radio.Button>
              </Radio.Group>
            </Space>
          </div>
        </FormItem>
        {/* 房屋来源 */}
        <FormItem
          className="tw-font-bold tw-flex-1"
          label="房屋来源"
          name="origin"
          requiredMark={false}
          initialData="个人"
        >
          <div>
            <Space direction="vertical">
              <Radio.Group
                variant="primary-filled"
                defaultValue={useSelector(selectOrigin)}
              >
                <Radio.Button
                  value="个人"
                  onChange={() => dispatch(setOrigin("个人"))}
                >
                  个人
                </Radio.Button>
                <Radio.Button
                  value="中介"
                  onChange={() => dispatch(setOrigin("中介"))}
                >
                  中介
                </Radio.Button>
              </Radio.Group>
            </Space>
          </div>
        </FormItem>
      </div>

      <div className="tw-flex tw-justify-center tw-gap-2">
        {/* 房子地区 */}
        <FormItem
          className="tw-font-bold tw-flex-1"
          label="房子地区"
          name="location"
          requiredMark={true}
          rules={[{ required: true, message: "请填写地区", type: "error" }]}
          initialData={useSelector(selectLocation)}
          value={useSelector(selectLocation)}
        >
          <Select
            style={{ width: "90%" }}
            filterable
            onChange={(value) => dispatch(setLocation(value))}
          >
            {locations?.map((item) => (
              <Option
                label={item.locationname}
                key={item.locationid}
                value={item.locationid}
              />
            ))}
          </Select>
        </FormItem>
        {/* 房子类型 */}
        <FormItem
          className="tw-font-bold tw-flex-1"
          label="房子类型"
          name="housetype"
          requiredMark={true}
          rules={[{ required: true, message: "请填写房子类型", type: "error" }]}
          initialData={useSelector(selectHousetype)}
        >
          <Select
            style={{ width: "90%" }}
            onChange={(e) => dispatch(setHousetype(e))}
          >
            <Option key="1" label="House / 别墅" value="1" />
            <Option key="2" label="Apartment / 公寓" value="2" />
            <Option key="3" label="Townhouse" value="3" />
            <Option key="4" label="Unit" value="4" />
            <Option key="5" label="Studio" value="5" />
            <Option key="6" label="商铺" value="6" />
            <Option key="7" label="车库" value="7" />
          </Select>
        </FormItem>
      </div>
      <div className="tw-flex tw-justify-center tw-gap-2">
        {/* 入住时间 */}
        <FormItem
          className="tw-font-bold tw-flex-1"
          label="入住时间"
          name="checkintime"
          initialData={useSelector(selectCheckinTime)}
          requiredMark={true}
          rules={[{ required: true, message: "请填写入住时间", type: "error" }]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            onChange={(value) => dispatch(setcheckinTime(value))}
          />
        </FormItem>

        {/* 最短租期 */}
        <FormItem
          className="tw-font-bold tw-flex-1"
          label="最短租期"
          name="minimumperiod"
          requiredMark={false}
          initialData={useSelector(selectMinimumperiod)}
        >
          <Input onChange={(value) => dispatch(setMinimumperiod(value))} />
        </FormItem>
      </div>

      {/* 房子租金 */}
      <FormItem
        className="tw-font-bold tw-flex-1"
        label="房子租金"
        name="HouseUnit"
        requiredMark={false}
        initialData="面议"
      >
        <div>
          <Space direction="vertical">
            <Radio.Group
              variant="primary-filled"
              defaultValue={useSelector(selectHouseunit)}
            >
              <Radio.Button
                value="面议"
                onClick={() => {
                  dispatch(setHouseunit("面议"));
                }}
              >
                面议
              </Radio.Button>
              <Radio.Button
                value="每周"
                onClick={() => {
                  dispatch(setHouseunit("每周"));
                }}
              >
                每周
              </Radio.Button>
            </Radio.Group>
          </Space>
        </div>
      </FormItem>

      {useSelector(selectHouseunit) != "面议" && (
        <FormItem
          className="tw-font-bold tw-flex-1"
          // initialData={}
          label="每周租金"
          name="rentprice"
          requiredMark={true}
          initialData={priceweek}
          rules={[{ required: true, message: "请填写每周租金", type: "error" }]}
        >
          <Input
            placeholder="请输入每周出租租金"
            onChange={(value) => dispatch(setPriceweek(value))}
          />
        </FormItem>
      )}
    </>
  );
}

export default Step1;
