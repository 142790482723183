import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { fetchRecommendGroups } from "../../api/ApiRequest";
import { Avatar } from "tdesign-react";
function CommunityGroup() {
  // 获取群组列表
  const [recommendgroups, setRecommendGroups] = useState([]);

  useEffect(() => {
    fetchRecommendGroups().then((res) => {
      setRecommendGroups(res.data.groups);
    });
  }, []);
  return (
    <BigBox>
      <div className="recommend">
        <h2>推荐群组</h2>
        <RecommendList>
          {recommendgroups?.map((item) => {
            return (
              <Link
                key={item.forumid}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                target="_blank"
                to={`/group/groupId=${item.groupid}/posts`}
              >
                <Avatar size="60px" shape="round" image={item.avatar} />
                <span className="tw-text-base">{item.name}</span>
              </Link>
            );
          })}
        </RecommendList>
      </div>
    </BigBox>
  );
}

const BigBox = styled.div`
  margin-top: 5px;
  /* margin-left: 50%; */
  /* transform: translateX(-50%); */
  .search {
    display: flex;
    justify-content: flex-end;
    div {
      width: 100%;
      border-radius: 12px;
    }
  }
  .recommend {
    height: 285px;
    padding: 0 16px 0 16px;
    h2 {
      font-size: 20px;
      height: 56px;
      line-height: 56px;
      color: #111827;
      border-bottom: 1px solid #e4e6eb;
      margin-bottom: 16px;
    }
  }
`;

const RecommendList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 45px;
  color: #000000;
`;
export default CommunityGroup;
