import { useState } from "react";
import styled from "styled-components";
import { AutoComplete, Button, Input } from "tdesign-react";
import img from "../../../../../../img/Vector8.svg";
import addimg from "../../../../../../img/Addgroup2.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SearchIcon } from "tdesign-icons-react";
import { useDispatch } from "react-redux";
import { setSearchTarget } from "../../../../../../Redux/slices/communitySlice";
import { fetchForumAutocomplete } from "../../../../../../api/ApiRequest";
import CommunityAdd from "../../../../../../components/community/CommunityAdd";
import CommunityGroupAdd from "../../../../../../components/community/CommunityGroupAdd";

function Community() {
  // 判断不同url，渲染不同组件
  const params = useLocation();
  const dispatch = useDispatch();
  const a = params.pathname.split("/");
  const urlmatch = a[a.length - 1];
  const isSearch = params.state === "isSearch";
  const navigate = useNavigate();
  const handleClick = (e) => {
    navigate(e);
  };

  // 发布帖子按钮
  const [forumvisible, setForumVisible] = useState(false);
  const handleAdd = () => {
    setForumVisible(true);
  };

  const handleClose = () => {
    setForumVisible(false);
  };

  // 创建群组按钮
  const [groupvisible, setGroupVisible] = useState(false);
  const handleCreate = () => {
    setGroupVisible(true);
  };

  const handleGroupClose = () => {
    setGroupVisible(false);
  };

  // 帖子搜索联想功能
  let timer = null;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);

  // / 输入框内容发生变化时进行搜索，500ms 搜索一次
  const onChange = (val) => {
    setValue(val);
    clearTimeout(timer);
    timer = setTimeout(async () => {
      const response = await fetchForumAutocomplete(val);
      setOptions(response.data.data);
      clearTimeout(timer);
    }, 500);
  };

  // 点击搜索框，渲染新画面
  const handleSearch = async (keyword) => {
    dispatch(setSearchTarget(keyword));
    navigate(`/community/forum/keyword=${keyword}`, { state: "isSearch" });
  };

  return (
    <BigBox className="community_forum">
      <Header>
        <div className="tab">
          <div
            onClick={() => handleClick("follow")}
            className={urlmatch === "follow" ? "active" : ""}
          >
            <span>关注</span>
            {urlmatch === "follow" && <img src={img}></img>}
          </div>
          <div
            onClick={() => handleClick("forum")}
            className={urlmatch === "forum" ? "active" : ""}
          >
            <span>推荐</span>
            {urlmatch === "forum" && <img src={img}></img>}
          </div>
          <div
            onClick={() => handleClick("group")}
            className={urlmatch === "group" ? "active" : ""}
          >
            <span>群组</span>
            {urlmatch === "group" && <img src={img}></img>}
          </div>
        </div>

        {/* 搜索框 */}
        <div className="search">
          {urlmatch === "forum" && (
            <div className="createnew" onClick={() => handleAdd()}>
              <img width={24} height={24} src={addimg} />
              <span>发布帖子</span>
            </div>
          )}

          {urlmatch === "group" && (
            <div className="createnew" onClick={() => handleCreate()}>
              <img src={addimg} />
              <span>创建群组</span>
            </div>
          )}

          {(urlmatch === "forum" || isSearch) && (
            <AutoComplete
              value={value}
              options={options}
              onChange={onChange}
              placeholder="请输入相关帖子内容"
              highlightKeyword
              clearable
              onSelect={(val) => {
                handleSearch(val);
              }}
              inputProps={{
                suffix: (
                  <SearchIcon
                    onClick={() => handleSearch(value)}
                    style={{ cursor: "pointer" }}
                  />
                ),
              }}
            />
          )}

          {urlmatch === "group" && (
            <Input
              placeholder="请输入群组名称"
              style={{ width: "200px", marginRight: "30px" }}
              className="searchinput"
            />
          )}
        </div>
      </Header>

      {/* 弹出对话框 */}
      {urlmatch === "forum" && (
        <CommunityAdd visible={forumvisible} onClose={handleClose} />
      )}
      {urlmatch === "group" && (
        <CommunityGroupAdd visible={groupvisible} onClose={handleGroupClose} />
      )}

      <Outlet />
    </BigBox>
  );
}

const BigBox = styled.div`
  padding-top: 5px;
  height: 100%;
  overflow: auto;
  flex: 1;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  .tab {
    display: flex;
    font-size: 20px;
    color: #959595;
    div {
      cursor: pointer;
    }
    span {
      margin: 0 10px;
    }
  }
  .active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: #151515;
  }

  .search {
    display: flex;
    align-items: center;
    .t-auto-complete {
      width: 200px;
    }
    .searchinput div {
      border-radius: 12px;
    }
    .createnew {
      display: flex;
      align-items: center;
      text-align: end;
      margin-right: 30px;
      font-size: 16px;
      color: #151515;
      cursor: pointer;
      img {
        margin-right: 5px;
      }
    }
  }
`;

export default Community;
