import React from 'react';
import { BiSearchAlt } from 'react-icons/bi';
import styles from './HeaderSearch.module.css';

function HeaderSearch() {
  const [isFocus, setIsFocus] = React.useState(false);

  const handleInputFocus = () => {
    setIsFocus(true);
  };

  const handleInputBlur = () => {
    setIsFocus(false);
  };

  return (
    <div className={styles.container_input}>
      <div className={`${styles.search_container} ${isFocus ? styles.focus : ''}`}>
        <input
          type="text"
          placeholder="生活大小事, 都在贴贴"
          name="text"
          className={styles.input}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <BiSearchAlt className={styles.icon} />
      </div>
    </div>
  );
}

export default HeaderSearch;
