export const checkEmail = (email) => {
  if (email === '') {
    return { emailStatus: false, emailInfo: '邮箱地址不能为空' };
  }

  const Reg_Email =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (Reg_Email.test(email)) {
    return { emailStatus: true, emailInfo: '邮箱地址格式正确' };
  } else {
    return { emailStatus: false, emailInfo: '请输入正确邮箱地址格式' };
  }
};

export const checkLoginPassword = (password) => {
  if (password === '') {
    return { passwordStatus: false, passwordInfo: '密码不能为空' };
  }
  return { passwordStatus: true, passwordInfo: '密码格式正确' };
};
