import React, { useState } from 'react';
import FormItem from 'tdesign-react/es/form/FormItem';
import Textarea from 'tdesign-react/es/textarea/Textarea';
import { Space, Upload } from 'tdesign-react';
import { useDispatch, useSelector } from 'react-redux';

import { convertImages } from '../../utils/uploadImage';
import { selectToken } from '../../Redux/slices/authSlice';
import {
  selecthouseDescription,
  setHouseDescription,
  setImgs,
  selectImgs,
  deleteImg,
  setImgsUrl,
  selectImgsUrl,
  deleteImgUrl,
} from '../../Redux/slices/rentUploadSlice';

function Step2() {
  const defaultImages = useSelector(selectImgs);
  const defaultImagesUrl = useSelector(selectImgsUrl);

  const [newAvatar, setNewAvatar] = useState(JSON.parse(JSON.stringify(defaultImages)));

  const dispatch = useDispatch();
  const token = useSelector(selectToken);

  let deleteTigger = React.useRef(false);
  let deleteIndex = React.useRef(-1);

  const handlesubmit = (e) => {
    dispatch(setImgs(defaultImages.concat(e.response)));
    dispatch(setImgsUrl(defaultImagesUrl.concat(e.response.map((item) => item.url))));
  };

  React.useEffect(() => {
    if (!deleteTigger.current) return;
    dispatch(deleteImg(deleteIndex.current));
    dispatch(deleteImgUrl(deleteIndex.current));
    deleteTigger.current = false;
    deleteIndex.current = -1;
  }, [newAvatar]);

  return (
    <>
      <FormItem
        className="tw-w-5/6 tw-font-bold"
        style={{ width: '50vw' }}
        label="房屋描述"
        name="homeDetail"
        shouldUpdate={false}
        successBorder={false}
        initialData={useSelector(selecthouseDescription)}
        rules={[{ required: true, message: '请填写房屋描述', type: 'error' }]}
      >
        <Textarea
          onChange={(e) => dispatch(setHouseDescription(e))}
          autosize={{ minRows: 7, maxRows: 7 }}
          placeholder="请详细描述该房子的信息"
          maxlength={3000}
        />
      </FormItem>

      <FormItem
        className="tw-w-5/6 tw-font-bold tw-flex tw-flex-col"
        style={{ width: '50vw' }}
        label="请上传房子的图片"
        name="homeImage"
        rules={[{ required: true, message: '请上传房子的图片' }]}
      >
        <Upload
          onChange={setNewAvatar}
          files={newAvatar}
          requestMethod={(data) => convertImages(data, token, 1440, 1920)}
          onRemove={(e) => {
            deleteTigger.current = true;
            deleteIndex.current = e.index;
          }}
          onSuccess={handlesubmit}
          autoUpload={true}
          useMockProgress={true}
          name="abc"
          theme="image-flow"
          accept="image/*"
          multiple
        />
      </FormItem>
    </>
  );
}

export default Step2;
