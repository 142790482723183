import React from 'react';
import styled from 'styled-components';
import { Select, Space } from 'tdesign-react';
import { Button } from 'tdesign-react';
import { useState } from 'react';
import RenthomeList from '../../../../../components/Renthome/RenthomeList';
import { fetchSydlocation } from '../../../../../api/ApiRequest';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectIsLogin } from '../../../../../Redux/slices/authSlice';
import { selectLocations, setLocations } from '../../../../../Redux/slices/filterSlice';
import RenthomeDetail from '../../../../../components/Renthome/RenthomeDetail';
const { Option } = Select;

function Renthome() {
  // 出租方式状态和函数
  const [method, setMethod] = useState('');

  //   房子类型和函数
  const [category, setCategory] = useState('');

  //   租金范围状态和函数
  const [price, setPrice] = useState('');

  const [location, setLocation] = useState('');

  const dispatch = useDispatch();
  const locations = useSelector(selectLocations);
  const isLogin = useSelector(selectIsLogin);

  // 从后端获取数据
  const fetchData = async () => {
    if (locations?.length !== 0) {
      return;
    }
    const response = await fetchSydlocation();
    dispatch(setLocations(response.data.data));
  };

  return (
    <Seek>
      {/* 房屋方式搜索框 */}
      <Jobsearch>
        <Space style={{ width: '100%' }}>
          {/* 房屋出租方式类型 */}
          <Select
            value={method}
            placeholder="出租方式"
            onChange={setMethod}
            Option
            popupProps={{
              overlayStyle: {
                position: 'absolute',
                top: '0px',
                bottom: '0px',
              },
            }}
            options={[
              { label: '不限', value: '4' },
              { label: '整租', value: '1' },
              { label: '单间', value: '2' },
              { label: '客厅', value: '3' },
            ]}
          ></Select>

          {/* 房屋户型选择框 */}
          <Select
            value={category}
            placeholder="房子类型"
            onChange={setCategory}
            popupProps={{
              overlayStyle: {
                position: 'absolute',
                top: '0px',
                bottom: '0px',
              },
            }}
            options={[
              { label: '不限', value: '8' },
              { label: 'House / 别墅', value: '1' },
              { label: 'Apartment / 公寓', value: '2' },
              { label: 'Townhouse', value: '3' },
              { label: 'Unit', value: '4' },
              { label: 'Studio', value: '5' },
              { label: '商铺', value: '6' },
              { label: '车库', value: '7' },
            ]}
          ></Select>

          {/* 价钱范围选择 */}
          <Select
            placeholder="每周租金"
            onChange={setPrice}
            popupProps={{
              overlayStyle: {
                position: 'absolute',
                top: '0px',
                bottom: '0px',
                textAlign: 'center',
              },
            }}
            options={[
              { label: '不限', value: '9' },
              { label: '面议', value: '8' },
              { label: '$100以下', value: '1' },
              { label: '$100-$150', value: '2' },
              { label: '$150-$200', value: '3' },
              { label: '$200-$300', value: '4' },
              { label: '$300-$500', value: '5' },
              { label: '$500-$1000', value: '6' },
              { label: '$1000以上', value: '7' },
            ]}
          ></Select>

          {/* 工作地区多选框 */}
          <Select
            value={location}
            onFocus={fetchData}
            minCollapsedNum={1}
            placeholder="-请选择地区-"
            filterable
            popupProps={{
              overlayStyle: { position: 'absolute', top: '0px', bottom: '0px' },
            }}
            onChange={setLocation}
            style={{ width: '200px' }}
          >
            <Option label="所有地区" value={'所有地区'} />
            {locations?.map((item) => (
              <Option label={item.locationname} key={item.locationid} value={item.locationname} />
            ))}
          </Select>
          <Button
            shape="rectange"
            size="medium"
            type="button"
            variant="base"
            theme={'warning'}
            onClick={() => window.open('/homeUpload', '_blank')}
          >
            {isLogin ? '免费发布租房信息' : '登录发布租房信息'}
          </Button>
        </Space>
      </Jobsearch>
      <div className="job_container">
        {/* 职业标题选择 */}
        <Left id="Jobparent">
          <RenthomeList method={method} category={category} price={price} location={location} />
        </Left>
        {/* 粘性定位，固定右边盒子不动 */}
        <Right>
          <RenthomeDetail />
        </Right>
      </div>
    </Seek>
  );
}

const Seek = styled.div`
  margin: 0 auto;
  padding-top: 10px;
  overflow: hidden;

  /* 适配不同屏幕 */

  @media (max-width: 1200px) {
    width: 850px;
  }
  @media (min-width: 1400px) {
    width: 1100px;
  }

  .job_container {
    display: flex;
    height: calc(100vh - 150px);
    border: 1px solid #e0e0e0;
    border-radius: 0.625rem;
    border-left: none;
  }
`;

const Jobsearch = styled.div`
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0px 4px 1px rgba(0, 0, 0, 0.04);

  .search {
    background-color: orange;
  }

  .t-space-item:nth-child(5) {
    align-self: flex-end;
    width: auto;
  }
`;

const Left = styled.div`
  flex: 1.5;
  overflow-y: scroll;
  .left_items {
    height: 135px;
    background-color: skyblue;
    border: 1px solid #fff;
    cursor: pointer;
  }
  .page {
    width: 500px;
    margin-left: 50%;
    transform: translateX(-50%);
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
    .t-pagination__pager {
      display: flex;
      justify-content: center;
      width: 100px;
    }
  }
`;

const Right = styled.div`
  overflow-y: auto;
  flex: 2;
  max-width: 607px;
  position: relative;
  top: 0;
  overflow-y: scroll;
`;

export default Renthome;
